import Analytics from '@hh.ru/analytics-js';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { IconColor } from 'bloko/blocks/icon';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';

import prolongateVacancy from 'lux/components/VacancyModals/Prolongation/prolongateVacancy';

interface ProlongateButtonProps {
    afterSuccessAction: ({
        handleCloseModal,
        queryParams,
        vacancyIds,
    }: {
        handleCloseModal: () => void;
        queryParams: Record<string, number[]>;
        vacancyIds: number[];
    }) => void;
    handleCloseModal: () => void;
    loading: boolean;
    startLoading: () => void;
    finishLoading: () => void;
}

const TrlKeys = {
    prolongate: 'vacancy.prolongate.submit.prolongate',
};

const ProlongateButton: TranslatedComponent<ProlongateButtonProps> = ({
    afterSuccessAction,
    handleCloseModal,
    loading,
    startLoading,
    finishLoading,
    trls,
}) => {
    const availableToFreeProlongateIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds
    );
    const availableToSpendIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToSpend.vacancyIds
    );

    const { addNotification } = useNotification();

    const isXs = useBreakpoint() === Breakpoint.XS;

    const prolongate = async () => {
        if (loading) {
            return;
        }

        Analytics.sendHHEventButtonClick(
            'modal_prolongation_button',
            {},
            // Из-за большого кол-ва вакансий используем body, а не query параметры
            {
                vacanciesIds: [...availableToSpendIds, ...availableToFreeProlongateIds],
            }
        );

        startLoading();
        try {
            const queryParams = await prolongateVacancy({
                addNotification,
                freeRenewalVacancyIds: availableToFreeProlongateIds,
                vacancyIds: availableToSpendIds,
            });

            if (afterSuccessAction) {
                afterSuccessAction({
                    handleCloseModal,
                    queryParams,
                    vacancyIds: [...availableToSpendIds, ...availableToFreeProlongateIds],
                });

                return;
            }
            // Нельзя написать в finally, т.к. будет закрывать любую модалку, в т.ч. открытую в afterSuccessAction
            handleCloseModal();
        } catch (ignore) {
            console.error(ignore);
            handleCloseModal();
        } finally {
            finishLoading();
        }
    };

    return (
        <Button
            data-qa="prolongate-button"
            disabled={loading}
            kind={ButtonKind.Primary}
            loading={loading && <Loading scale={LoadingScale.Small} initial={IconColor.White} />}
            onClick={prolongate}
            stretched={isXs}
        >
            {trls[TrlKeys.prolongate]}
        </Button>
    );
};

export default translation(ProlongateButton);
