import HoverTip, { TipLayer, TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import { InfoScaleSmall, IconColor, IconVerticalAlignment } from 'bloko/blocks/icon';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';

const TrlKeys = {
    info: 'employer.myVacancies.prolongate.info',
    infoHover: {
        0: 'employer.myVacancies.prolongate.info.hover.0',
        1: 'employer.myVacancies.prolongate.info.hover.1',
        2: 'employer.myVacancies.prolongate.info.hover.2',
    },
};

const ProlongateInfoSubtitle: TranslatedComponent = ({ trls }) => {
    return (
        <Text importance={TextImportance.Tertiary}>
            {trls[TrlKeys.info]}
            {NON_BREAKING_SPACE}
            <HoverTip
                host={!process.env.LUX_SERVER ? document.body : null}
                layer={TipLayer.AboveOverlayContent}
                placement={TipPlacement.Bottom}
                render={() => (
                    <>
                        <Text>{trls[TrlKeys.infoHover[0]]}</Text>
                        <VSpacing base={2} />
                        <Text>{trls[TrlKeys.infoHover[1]]}</Text>
                        <VSpacing base={2} />
                        <Text>{trls[TrlKeys.infoHover[2]]}</Text>
                    </>
                )}
            >
                <IconVerticalAlignment>
                    <InfoScaleSmall initial={IconColor.Gray40} />
                </IconVerticalAlignment>
            </HoverTip>
        </Text>
    );
};

export default translation(ProlongateInfoSubtitle);
