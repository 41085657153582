import { ButtonIconPosition } from 'bloko/blocks/button';

export type TriggerType = 'actionList' | 'batch' | 'button' | 'buttonMenu' | 'link' | 'menu';

export interface BasicTrigger {
    children: React.ReactNode;
    triggerType: TriggerType;
    triggerName: string;
    onTrigger?: () => void;
    triggerHref?: string;
    triggerTarget?: '_blank';
    icon?: React.ReactNode;
    iconPosition?: ButtonIconPosition;
    isPermitted?: boolean;
    isLoading?: boolean;
    actionType?: ControlsTriggerActionType;
    additionalAnalyticsParams?: Record<string, string>;
    batchLength?: number;
    magritteRedesign?: boolean;
    counter?: string;
}

export enum ControlsTriggerActionType {
    NEUTRAL = 'neutral',
    LIGHT = 'light',
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
}
