import Analytics from '@hh.ru/analytics-js';

const CLOSABLE_EVENTS = ['success', 'cancel'];
const NON_CLOSABLE_ACTIONS = ['linkQuestionnaire', 'addQuestionnaire'];

export const shouldCloseIframe = (eventId, payload) =>
    CLOSABLE_EVENTS.includes(eventId) && !NON_CLOSABLE_ACTIONS.includes(payload.action);

export const getIframeSrc = (vacancyId, preparedSrc = null) => {
    if (preparedSrc) {
        return `${window.globalVars.skillazURL}${preparedSrc}`;
    }

    return `${window.globalVars.skillazURL}/hh?page=questionnaire&vacancyId=${vacancyId}`;
};

export const isSkillazOrigin = (origin) => origin === window.globalVars.skillazURL;

export const sendSkillazEventAnalytics = (eventId, payload, labelPageId) => {
    Analytics.sendHHEvent('skillazEvent', {
        goal: 'PORTFOLIO-10079',
        labelPageId,
        labelEventId: eventId,
        ...(payload?.action ? { labelEventType: payload.action } : {}),
    });
};
