import { FC, ChangeEvent } from 'react';

import {
    EmojiNeutral3dSize32,
    EmojiSad3dSize32,
    EmojiHalfsad3dSize32,
    EmojiHalfsmile3dSize32,
    EmojiSmile3dSize32,
} from '@hh.ru/magritte-ui/icon';

import { Rating } from 'lux/components/VacancyModals/VacancyAdviceModal/constants';

import styles from './rating-chooser.less';

const RATING_TO_ICON = {
    [Rating.Sad]: EmojiSad3dSize32,
    [Rating.Halfsad]: EmojiHalfsad3dSize32,
    [Rating.Neutral]: EmojiNeutral3dSize32,
    [Rating.Halfsmile]: EmojiHalfsmile3dSize32,
    [Rating.Smile]: EmojiSmile3dSize32,
};

interface RatingChooserProps {
    selected: Rating | null;
    onSelect: (value: Rating) => void;
}

const RatingChooser: FC<RatingChooserProps> = ({ selected, onSelect }) => {
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        onSelect(event.target.value as Rating);
    };

    return (
        <div className={styles.ratingChooser}>
            {Object.entries(RATING_TO_ICON).map(([rating, IconComponent]) => (
                <label key={rating} className={styles.ratingChooserItem}>
                    <input
                        type="radio"
                        name="vacancy-advice-feedback-modal-rating"
                        value={rating}
                        className={styles.ratingChooserRadio}
                        onChange={onChange}
                        checked={selected === rating}
                    />
                    <IconComponent />
                </label>
            ))}
        </div>
    );
};

export default RatingChooser;
