import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import ConversionNumber from 'bloko/blocks/conversion';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    title: 'employer.myVacancies.freeOfCharge',
    updateVacancy: {
        one: 'employer.myVacancies.regularVacancies.updatingOne',
        some: 'employer.myVacancies.regularVacancies.updatingSome',
        many: 'employer.myVacancies.regularVacancies.updatingMany',
    },
};

const AvailableToFreeProlongate: TranslatedComponent = ({ trls }) => {
    const vacancyIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds
    );

    if (!vacancyIds.length) {
        return null;
    }

    return (
        <>
            <H3>{trls[TrlKeys.title]}</H3>
            <VSpacing base={4} />
            <ConversionNumber
                value={vacancyIds.length}
                one={trls[TrlKeys.updateVacancy.one]}
                some={trls[TrlKeys.updateVacancy.some]}
                many={trls[TrlKeys.updateVacancy.many]}
            />
            <VSpacing base={6} />
        </>
    );
};

export default translation(AvailableToFreeProlongate);
