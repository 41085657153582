import { useMemo } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import ConversionNumber from 'bloko/blocks/conversion';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'lux/components/translation';
import { ProlongateUnavailableReason } from 'lux/models/prolongateInfo';

import styles from './unavailable.less';

const TrlKeys = {
    reason: {
        [ProlongateUnavailableReason.AccessDenied]: 'employer.myVacancies.prolongate.error.ACCESS_DENIED',
        [ProlongateUnavailableReason.Archived]: 'employer.myVacancies.prolongate.error.ARCHIVED',
        [ProlongateUnavailableReason.AreaVacancyPostDisabled]:
            'employer.myVacancies.prolongate.error.AREA_VACANCY_POST_DISABLED',
        [ProlongateUnavailableReason.FreeNotMinimumProlongationPeriod]:
            'employer.myVacancies.prolongate.error.FREE_NOT_MINIMUM_PROLONGATION_PERIOD',
        [ProlongateUnavailableReason.FreeProlongationNotAvailable]:
            'employer.myVacancies.prolongate.error.FREE_PROLONGATION_NOT_AVAILABLE',
        [ProlongateUnavailableReason.FreeRenewalsAndPaidProlongationsMixed]:
            'employer.myVacancies.prolongate.error.FREE_RENEWALS_AND_PAID_PROLONGATIONS_MIXED',
        [ProlongateUnavailableReason.PayForPerformanceProlongationDenied]:
            'employer.myVacancies.prolongate.error.PAY_FOR_PERFORMANCE_PROLONGATION_DENIED',
        [ProlongateUnavailableReason.StandardNotMinimumProlongationPeriod]:
            'employer.myVacancies.prolongate.error.STANDARD_NOT_MINIMUM_PROLONGATION_PERIOD',
        [ProlongateUnavailableReason.StandardPlusClosed]: 'employer.myVacancies.prolongate.error.STANDARD_PLUS_CLOSED',
        [ProlongateUnavailableReason.StandardPlusNotMinimumDaysToProlongation]:
            'employer.myVacancies.prolongate.error.STANDARD_PLUS_NOT_MINIMUM_DAYS_TO_PROLONGATION',
        [ProlongateUnavailableReason.NotPremoderated]: 'employer.myVacancies.prolongate.error.NOT_PREMODERATED',
        [ProlongateUnavailableReason.UnknownProlongate]: 'employer.myVacancies.prolongate.error.UNKNOWN',
        [ProlongateUnavailableReason.Unknown]: 'employer.myVacancies.prolongate.error.UNKNOWN',
    },
    title: 'employer.myVacancies.prolongate.unavailable.title',
    vacancy: {
        one: 'vacancy.accusative.one',
        some: 'vacancy.accusative.some',
        many: 'vacancy.accusative.many',
    },
};

const Unavailable: TranslatedComponent = ({ trls }) => {
    const reasons = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.unavailable.reasons);
    const vacancyIds = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.unavailable.vacancyIds);

    const uniqueReasons = useMemo(
        () => [...new Set<ProlongateUnavailableReason>(Object.values(reasons || {}))],
        [reasons]
    );

    if (!uniqueReasons.length) {
        return null;
    }

    return (
        <>
            <H3>
                {formatToReactComponent(trls[TrlKeys.title], {
                    '{0}': (
                        <span className={styles.title}>
                            <ConversionNumber
                                value={vacancyIds.length}
                                one={trls[TrlKeys.vacancy.one]}
                                some={trls[TrlKeys.vacancy.some]}
                                many={trls[TrlKeys.vacancy.many]}
                            />
                        </span>
                    ),
                })}
            </H3>
            <VSpacing base={4} />
            <div data-qa="prolongate-unavailable">
                {uniqueReasons.map((reason) => {
                    // TODO HH-220531: remove analytics
                    if (
                        !Object.values(ProlongateUnavailableReason).includes(reason) ||
                        reason === ProlongateUnavailableReason.Unknown
                    ) {
                        Analytics.sendHHEvent('prolongation_unavailable_reason_unknown', {
                            reason,
                            vacancyIds,
                        });
                    }

                    return (
                        <div
                            className={styles.reasonText}
                            data-qa={`prolongate-unavailable-reason_${reason}`}
                            key={reason}
                        >
                            {trls[TrlKeys.reason[reason] || TrlKeys.reason[ProlongateUnavailableReason.Unknown]]}
                        </div>
                    );
                })}
            </div>
            <VSpacing base={6} />
        </>
    );
};

export default translation(Unavailable);
