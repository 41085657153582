import { Button, useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    discard: 'employer.unpremoderated.vacancyRefuse',
};

interface DiscardButtonProps {
    handleClick: () => void;
}

const DiscardButton: TranslatedComponent<DiscardButtonProps> = ({ trls, handleClick }) => {
    const { isMobile } = useBreakpoint();

    return (
        <Button mode="secondary" style="negative" onClick={handleClick} size={isMobile ? 'large' : 'small'}>
            {trls[TrlKeys.discard]}
        </Button>
    );
};

export default translation(DiscardButton);
