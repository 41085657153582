import { archiveVacancySuccess } from 'lux/components/Notifications/EmployerVacancies';
import { useNotification } from 'lux/components/Notifications/Provider';
import fetcher from 'lux/modules/fetcher';

const VACANCIES_ARCHIVE_URL = '/employer/vacancy/archive';

declare global {
    interface FetcherPostApi {
        [VACANCIES_ARCHIVE_URL]: {
            queryParams: void;
            body: {
                reason: string;
                id: number[];
            };
            response: {
                query?: string;
                daysUntilReadOnly: number;
            };
        };
    }
}
type FetchUnpremoderatedVacancies = (params: {
    additional: {
        name: string;
    };
    params: {
        reason: string;
        id: number[];
    };
}) => Promise<string>;

const useArchiveVacancyFetcher: () => {
    fetchArchiveVacancy: FetchUnpremoderatedVacancies;
} = () => {
    const { addNotification } = useNotification();

    const fetchArchiveVacancy: FetchUnpremoderatedVacancies = async ({ additional, params }) => {
        const { name } = additional;

        const response = await fetcher.postFormData(VACANCIES_ARCHIVE_URL, params);

        addNotification(archiveVacancySuccess, {
            props: {
                name,
                count: params.id.length,
                daysUntilReadOnly: response.data?.daysUntilReadOnly,
            },
        });

        return response.data.query || '';
    };

    return { fetchArchiveVacancy };
};

export default useArchiveVacancyFetcher;
