import ConversionNumber from 'bloko/blocks/conversion';
import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'lux/components/translation';

const TrlKeys = {
    publicationsOne: 'vacancy.autoUpdate.combine.summary.publications.one',
    publicationsSome: 'vacancy.autoUpdate.combine.summary.publications.some',
    publicationsMany: 'vacancy.autoUpdate.combine.summary.publications.many',
    summary: 'vacancy.autoUpdate.combine.summary.text',
};

interface CountTextProps {
    count: number;
}
const CountText: TranslatedComponent<CountTextProps> = ({ trls, count }) => (
    <Text importance={TextImportance.Tertiary}>
        {formatToReactComponent(trls[TrlKeys.summary], {
            '{0}': (
                <ConversionNumber
                    value={count}
                    one={trls[TrlKeys.publicationsOne]}
                    some={trls[TrlKeys.publicationsSome]}
                    many={trls[TrlKeys.publicationsMany]}
                />
            ),
        })}
    </Text>
);

export default translation(CountText);
