import { memo } from 'react';
import PropTypes from 'prop-types';

import Information from 'bloko/blocks/information';
import format from 'bloko/common/format';

import translation from 'lux/components/translation';

const Warnings = ({ prolongationFailureReason, typeTrl, trls }) =>
    prolongationFailureReason !== null &&
    prolongationFailureReason !== 'OTHER' && (
        <Information>
            {format(trls[Warnings.trls[prolongationFailureReason]], {
                '{0}': typeTrl,
            })}
        </Information>
    );

Warnings.trls = {
    NOT_ENOUGH_PERMISSIONS: 'vacancy.autoUpdate.warning.NOT_ENOUGH_PERMISSIONS',
    NOT_ENOUGH_PUBLICATIONS: 'vacancy.autoUpdate.warning.NOT_ENOUGH_PUBLICATIONS',
    NOT_ENOUGH_QUOTAS: 'vacancy.autoUpdate.warning.NOT_ENOUGH_QUOTAS',
};

Warnings.propTypes = {
    prolongationFailureReason: PropTypes.string,
    typeTrl: PropTypes.string,
    trls: PropTypes.object,
};

export default memo(translation(Warnings));
