import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import CustomSelect, { CustomSelectLayer, CustomSelectOption } from 'bloko/blocks/customSelect';
import { ClockScaleSmallKindDefault, IconColor } from 'bloko/blocks/icon';

const timeIntervals = [...Array(24).keys()];

const formatHour = (hour) => `${String(hour).padStart(2, '0')}:00`;
const formatTime = (hour) => `${formatHour(hour)}—${formatHour(hour + 1)}`;

const renderPlaceholder = (value) => (
    <>
        <span className="vacancy-auto-update-selectors__icon">
            <ClockScaleSmallKindDefault initial={IconColor.Gray60} />
        </span>
        {formatTime(value)}
    </>
);

const TimeSelect = ({ time, setTime, isDisabled, title }) => {
    useEffect(() => {
        if (isDisabled(timeIntervals.find((hour) => time === hour))) {
            setTime(timeIntervals.find((hour) => !isDisabled(hour)));
        }
    }, [isDisabled, setTime, time]);

    return (
        <CustomSelect
            value={time}
            onChange={setTime}
            layer={CustomSelectLayer.AboveOverlayContent}
            placeholderTemplate={renderPlaceholder}
            title={title}
        >
            {timeIntervals.map((hour) => (
                <CustomSelectOption key={hour} value={hour} disabled={isDisabled(hour)}>
                    {formatTime(hour)}
                </CustomSelectOption>
            ))}
        </CustomSelect>
    );
};

TimeSelect.propTypes = {
    time: PropTypes.number,
    setTime: PropTypes.func.isRequired,
    isDisabled: PropTypes.func.isRequired,
    title: PropTypes.string,
};

export default memo(TimeSelect);
