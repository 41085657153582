// tempexp_30986_file
import useExperiment from 'lux/hooks/useExperiment';
import { useIsHeadHunterPlatform } from 'lux/hooks/usePlatform';
import { useIsHhru } from 'lux/hooks/useSites';
import { CountryId } from 'lux/models/countryId';
import { useSelector } from 'lux/modules/useSelector';

export const RISE_PAGE_DIRECT_EXPERIMENT = 'exp_30986_rise_page_direct';
export const RISE_PAGE_MODAL_EXPERIMENT = 'exp_30986_rise_page_modal';

const Features = {
    disableRisePageEmployerIds: 'disable_rise_page_employer_ids',
};

interface UseShowRisePage {
    isAnyRisePageExperimentEnabled: boolean;
    isRisePageDirectExperimentEnabled: boolean;
    isRisePageModalExperimentEnabled: boolean;
    sendCheck: boolean;
}

const useShowRisePage = (): UseShowRisePage => {
    const isRussianEmployer = useSelector(
        ({ employerCountryIdBySeller }) => employerCountryIdBySeller?.toString() === CountryId.Russia
    );
    const isHHPlatform = useIsHeadHunterPlatform();
    const isHHRu = useIsHhru();

    const employerId = useSelector(({ employerId }) => employerId);
    const employerIds = useSelector(({ features }) => (features[Features.disableRisePageEmployerIds] as string) ?? '');
    const isEmployerEnabled = !employerIds.split(',').includes(employerId);

    const shouldCheckExperiment = isHHPlatform && isHHRu && isRussianEmployer && isEmployerEnabled;

    const isRisePageDirectExperimentEnabled = useExperiment(RISE_PAGE_DIRECT_EXPERIMENT, shouldCheckExperiment, false);
    const isRisePageModalExperimentEnabled = useExperiment(RISE_PAGE_MODAL_EXPERIMENT, shouldCheckExperiment, false);

    return {
        isAnyRisePageExperimentEnabled: isRisePageDirectExperimentEnabled || isRisePageModalExperimentEnabled,
        isRisePageDirectExperimentEnabled,
        isRisePageModalExperimentEnabled,
        sendCheck: shouldCheckExperiment,
    };
};

export default useShowRisePage;
