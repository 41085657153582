import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    single: 'vacancy.prolongate.error.notification.single',
    multiple: 'vacancy.prolongate.error.notification.multiple',
};

interface VacancyProlongateErrorNotificationProps {
    isMultiple: boolean;
}

const VacancyProlongateErrorNotification: TranslatedComponent<VacancyProlongateErrorNotificationProps> = ({
    isMultiple,
    trls,
}) => {
    return <div>{isMultiple ? trls[TrlKeys.multiple] : trls[TrlKeys.single]}</div>;
};

export default {
    Element: translation(VacancyProlongateErrorNotification),
    kind: 'error',
    autoClose: true,
    autoCloseDelay: 3000,
};
