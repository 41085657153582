export interface RenewalStep {
    ids: number[];
    vacancyProperties: {
        converted: {
            shortcut: {
                typeTrl: string;
            };
        };
    };
    scheduleTypesWithUpdatesCount: { [key: string]: { count: number } };
    closestPossibleProlongation: string;
    maxLastUpdateDateTime: string;
    prolongationFailureReason: string;
}

export type CombinedSteps = Omit<RenewalStep, 'vacancyProperties' | 'prolongationFailureReason'> & {
    warnings: {
        vacancyProperties: RenewalStep['vacancyProperties'];
        prolongationFailureReason: RenewalStep['prolongationFailureReason'];
    }[];
};

// проходим по всем шагам и группируем их в один шаг
export default (renewalSteps: RenewalStep[]): CombinedSteps => {
    const { vacancyProperties, prolongationFailureReason, ...rest } = renewalSteps[0];
    const scheduleTypes = Object.keys(rest.scheduleTypesWithUpdatesCount);
    const stepToStart = {
        ...rest,
        warnings: prolongationFailureReason
            ? [
                  {
                      prolongationFailureReason,
                      vacancyProperties,
                  },
              ]
            : [],
    };

    return renewalSteps.reduce((acc: CombinedSteps, curr, index): CombinedSteps => {
        // первый шаг уже учтен, в stepToStart
        if (index === 0) {
            return acc;
        }
        // складываем все публикации из всех шагов и группируем их по scheduleType
        scheduleTypes.forEach((scheduleType) => {
            acc.scheduleTypesWithUpdatesCount[scheduleType] = {
                count:
                    acc.scheduleTypesWithUpdatesCount[scheduleType].count +
                    curr.scheduleTypesWithUpdatesCount[scheduleType].count,
            };
        });

        // складываем все ids в общий массив
        acc.ids.push(...curr.ids);

        if (curr.prolongationFailureReason) {
            // складываем все warnings в общий массив,
            // для отрисовки warning нужно св-во публикации(для отображения типа)
            // и текст с причиной warning
            acc.warnings.push({
                prolongationFailureReason: curr.prolongationFailureReason,
                vacancyProperties: curr.vacancyProperties,
            });
        }

        return acc;
    }, stepToStart);
};
