export enum StateId {
    Consider = 'consider',
    PhoneInterview = 'phone_interview',
    Assessment = 'assessment',
    Interview = 'interview',
    Offer = 'offer',
    Hired = 'hired',
    DiscardByEmployer = 'discard_by_employer',
    DiscardByApplicant = 'discard_by_applicant',
    DiscardNoInteraction = 'discard_no_interaction',
    DiscardToOtherVacancy = 'discard_to_other_vacancy',
    DiscardVacancyClosed = 'discard_vacancy_closed',
}

const DISCARD_STATE_IDS = [
    StateId.DiscardByEmployer,
    StateId.DiscardByApplicant,
    StateId.DiscardNoInteraction,
    StateId.DiscardToOtherVacancy,
    StateId.DiscardVacancyClosed,
] as const;

type DiscardStateId = (typeof DISCARD_STATE_IDS)[number];

export const isDiscardStateId = (stateId: unknown): stateId is DiscardStateId => DISCARD_STATE_IDS.includes(stateId);
