import { FC, useCallback, useEffect, useRef, memo } from 'react';

import { ActionList, Button } from '@hh.ru/magritte-ui';
import { EllipsisHorizontalOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { ControlsTriggerActionType } from 'lux/components/ControlsTrigger';
import { useShownRawAnalyticsWithInnerButtons } from 'lux/hooks/useMyVacanciesAnalytics';
import useToggleState from 'lux/hooks/useToggleState';

import MenuContent from 'lux/components/AbstractControls/MenuContent';

import styles from './abstract-controls.less';

const MENU_BUTTON_MAX_WIDTH = 360;

interface Control {
    name: string;
    condition: boolean;
    Component: FC<Record<string, unknown>>;
    additionalProps?: Record<string, unknown>;
    actionType?: ControlsTriggerActionType;
}

export interface AbstractControlsProps {
    controlsList: Control[];
    controlProps: Record<string, unknown>;
    displayAsMenu?: boolean;
    analyticsOnMenuShow?: string;
    dataQa: {
        menu: string;
        button: string;
    };
    actionListDirection?: 'right' | 'left';
    actionListAlignment?: 'top' | 'bottom';
    onMenuStateChange?: (visible: boolean) => void;
}

const AbstractControls: FC<AbstractControlsProps> = ({
    controlsList,
    controlProps,
    displayAsMenu = false,
    analyticsOnMenuShow,
    dataQa,
    onMenuStateChange,
    actionListDirection = 'right',
    actionListAlignment = 'top',
}) => {
    const [isMenuVisible, toggleMenu, setMenuVisible] = useToggleState(false);

    const menuRef = useRef(null);
    const activatorRef = useRef(null);
    const shownMenuRawAnalytics = useShownRawAnalyticsWithInnerButtons(menuRef);

    useEffect(() => {
        onMenuStateChange?.(isMenuVisible);
    }, [onMenuStateChange, isMenuVisible]);

    const renderTriggers = useCallback(
        (triggerType: string, onClose?: () => void) => (
            <>
                {controlsList.map(
                    ({ name, condition, Component, actionType, additionalProps }) =>
                        condition && (
                            <Component
                                key={name}
                                triggerType={triggerType}
                                onClose={onClose}
                                actionType={actionType}
                                {...controlProps}
                                {...additionalProps}
                            />
                        )
                )}
            </>
        ),
        [controlProps, controlsList]
    );

    const renderMenuTriggers = useCallback(
        () => <MenuContent ref={menuRef}>{renderTriggers('buttonMenu', toggleMenu)}</MenuContent>,
        [toggleMenu, renderTriggers]
    );

    if (displayAsMenu) {
        const toggleMenuAndSendAnalytics = () => {
            toggleMenu();

            if (!isMenuVisible && analyticsOnMenuShow) {
                shownMenuRawAnalytics(analyticsOnMenuShow);
            }
        };

        // есть кейсы когда дроп рендерится меньше по высоте и со скроллом, хотя места хватает
        // для того чтобы это исправить задаем минимальную высоту дроп контента исходя из количества кнопок в меню
        const menuButtonHeight = 50;
        const menuOptionsCount = controlsList.filter((control) => control.condition).length;
        const minHeightForDropContent = menuButtonHeight * menuOptionsCount;

        return (
            <div data-qa={dataQa.menu}>
                <div className={styles.abstractControlsMenuButtonWrapper}>
                    <Button
                        ref={activatorRef}
                        mode="secondary"
                        hideLabel
                        aria-label="Controls"
                        onClick={toggleMenuAndSendAnalytics}
                        icon={<EllipsisHorizontalOutlinedSize24 initial="primary" />}
                        data-qa={dataQa.button}
                    />
                </div>
                <ActionList
                    visible={isMenuVisible}
                    onClose={() => setMenuVisible(false)}
                    dropProps={{
                        activatorRef,
                        role: 'status',
                        direction: actionListDirection,
                        alignment: actionListAlignment,
                        minHeightForContent: minHeightForDropContent,
                        maxWidth: MENU_BUTTON_MAX_WIDTH,
                    }}
                >
                    {renderMenuTriggers()}
                </ActionList>
            </div>
        );
    }

    return renderTriggers('link');
};

export default memo(AbstractControls);
