import { LangTrls } from 'bloko/common/hooks/useTranslations';

import { EmployerVacanciesRiseProps } from 'lux/models/employerVacanciesRise';
import { ProlongateInfo } from 'lux/models/prolongateInfo';
import routePaths from 'lux/modules/routePaths';

const TrlKeys = {
    errorNonProlongableVacanciesOne: 'vacancy.rise.error.nonProlongableVacancies.one',
    errorNonProlongableVacanciesMany: 'vacancy.rise.error.nonProlongableVacancies.many',

    ARCHIVED: 'vacancy.rise.error.archivedOrClosed',
    STANDARD_PLUS_NOT_MINIMUM_DAYS_TO_PROLONGATION: 'vacancy.rise.error.standardPlusNotMinimumDaysToProlongation',
    STANDARD_PLUS_CLOSED: 'vacancy.rise.error.archivedOrClosed',
    ACCESS_DENIED: 'vacancy.rise.error.accessDenied',
};

// Парсит ответ с POST /employer/vacancy/prolongate_info(срабатывает при клике на кнопку «Поднять»)
// Если возвращает null, то это значит что поднятие валидно и возможно,
// иначе возвращает сообщение-причину(message) почему нельзя поднять.
export const validateResponse = (
    { availableToPurchase, availableToSpend, availableToFreeProlongate, unavailable }: ProlongateInfo,
    trls: LangTrls
): EmployerVacanciesRiseProps['error'] | null => {
    const isSomethingPossible =
        availableToSpend.vacancyIds.length > 0 ||
        availableToFreeProlongate.vacancyIds.length > 0 ||
        availableToPurchase.vacancyIds.length > 0;
    const { reasons = {} } = unavailable;

    // если возможно что-то поднять из пачки
    if (isSomethingPossible) {
        return null;
    }

    // если причин несколько почему нельзя поднять вакансии, то пишем общую ошибку "Не получится поднять вакансии"
    const reasonsLength = Object.keys(reasons).length;
    if (reasonsLength > 1) {
        return { message: trls[TrlKeys.errorNonProlongableVacanciesMany] };
    }
    // если причина почему нельзя поднять вакансию одна, то
    // если ошибка из тех что мы обрабатываем по стайлгайду, то берем ее из словаря,
    // иначе пишем общую ошибку "Не получится поднять вакансию"
    if (reasonsLength === 1) {
        const [reason] = Object.values(reasons);
        if (Object.keys(TrlKeys).includes(reason)) {
            return { message: trls[TrlKeys[reason as keyof typeof TrlKeys]] };
        }

        return { message: trls[TrlKeys.errorNonProlongableVacanciesOne] };
    }
    return null;
};

export const openBlankRisePage = (ids: number[], isRegularly?: boolean): void => {
    const params = new URLSearchParams();
    ids.forEach((id) => params.append('id', `${id}`));
    if (isRegularly) {
        params.append('type', 'regularly');
    }
    window.open(`${routePaths.employerVacanciesRise}?${params.toString()}`, '_blank');
};
