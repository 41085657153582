import { useCallback, useState, useMemo, useContext } from 'react';
import { useDispatch } from 'react-redux';

import ConversionNumber from 'bloko/blocks/conversion';
import { ModalTitle } from 'bloko/blocks/modal';
import TreeSelectorDummy from 'bloko/blocks/treeSelector/Dummy';
import TreeSelectorPopup from 'bloko/blocks/treeSelectorPopup';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';

import CheckContext from 'lux/components/EmployerVacancies/CheckContext';
import { UNCHECK_ALL } from 'lux/components/EmployerVacancies/checkReducer';
import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';
import { fetchAndUpdateVacanciesCount } from 'lux/models/employerVacancies/vacanciesCount';
import { EmployerManager } from 'lux/models/employerVacancies/vacancy.types';
import { ManagerListItem } from 'lux/models/managersList';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { useSelector } from 'lux/modules/useSelector';

import moveDrafts from 'lux/components/VacancyModals/TransferDrafts/moveDrafts';

const getTreeCollection = (managersList: ManagerListItem[]) =>
    fromTree(
        managersList.map(({ id, name, firstName, middleName, lastName }) => ({
            id: `${id}`,
            text: name,
            additional: {
                managerId: id,
                firstName,
                middleName,
                lastName,
            },
        }))
    );

const TrlKeys = {
    titlePrefix: 'employer.myVacancies.draft.move.titlePrefix',
    error: 'employer.myVacancies.draft.move.error',
    cancel: 'employer.myVacancies.draft.move.cancel',
    transferSubmit: 'employer.myVacancies.draft.move',
    transferSubmitOne: 'employer.myVacancies.draft.moveOne',
    transferSome: 'draft.some',
    transferMany: 'draft.many',
};

interface TransferDraftsProps {
    isVisible: boolean;
    draftsIds: number[];
    handleCloseModal: () => void;
}

const TransferDrafts: TranslatedComponent<TransferDraftsProps> = ({ isVisible, draftsIds, handleCloseModal, trls }) => {
    const dispatch = useDispatch();
    const { dispatchCheckDraft } = useContext(CheckContext);
    const currentManagerId = useSelector((state) => parseInt(state.employerManager?.id || '', 10));
    const managersList = useSelector((state) => state.managersList);
    const managersTree = useMemo(() => getTreeCollection(managersList), [managersList]);
    const { addNotification } = useNotification();

    const [isError, setError] = useState(false);
    const [selected, setManager] = useState('');

    const draftsCount = draftsIds?.length || 1;

    const handleSubmit = useCallback(async () => {
        try {
            await dispatch(
                moveDrafts(
                    {
                        draftsIds,
                        managerToTransfer: managersTree.getModel(selected)?.additional as EmployerManager,
                        currentManagerId,
                    },
                    addNotification
                )
            );
        } catch (e) {
            setError(true);
            return;
        }
        setError(false);
        handleCloseModal();
        dispatchCheckDraft({ type: UNCHECK_ALL });
        void fetchAndUpdateVacanciesCount(dispatch);
    }, [
        handleCloseModal,
        dispatchCheckDraft,
        dispatch,
        draftsIds,
        managersTree,
        selected,
        currentManagerId,
        addNotification,
    ]);
    const popupTrl = useMemo(
        () => ({
            submit: draftsCount === 1 ? trls[TrlKeys.transferSubmitOne] : trls[TrlKeys.transferSubmit],
            cancel: trls[TrlKeys.cancel],
        }),
        [trls, draftsCount]
    );

    return (
        <TreeSelectorPopup
            visible={isVisible}
            selected={[selected]}
            trl={popupTrl}
            onClose={handleCloseModal}
            error={isError ? trls[TrlKeys.error] : ''}
            onSubmit={handleSubmit}
            disabledSubmit={!selected}
            hideOnSubmitClick={false}
            title={
                <ModalTitle>
                    {trls[TrlKeys.titlePrefix]}
                    {NON_BREAKING_SPACE}
                    <ConversionNumber
                        value={draftsCount}
                        one={trls[TrlKeys.transferSome]}
                        some={trls[TrlKeys.transferMany]}
                        many={trls[TrlKeys.transferMany]}
                    />
                </ModalTitle>
            }
        >
            <TreeSelectorDummy singleChoice onChange={(id) => setManager(id)} collection={managersTree} />
        </TreeSelectorPopup>
    );
};

export default translation(TransferDrafts);
