import { generatePath } from 'react-router';

import { VacancyAdviceMarketSalary } from 'lux/models/employerVacancies/vacancy.types';
import {
    VacancyAdvice,
    VacancyAdviceWithStatistics,
    VacancyAdviceSeverity,
} from 'lux/models/vacancy/vacancyAdvices.types';
import paths from 'lux/modules/routePaths';
import { EN_DASH } from 'lux/modules/symbols';
import formatToInt from 'lux/utils/formatToInt';

import {
    ADVICE_TO_ANCHOR,
    VacancyAdviceGroup,
    VacancyAdviceToGroup,
    SALARY_VACANCY_ADVICES,
} from 'lux/components/VacancyModals/VacancyAdviceModal/constants';

export const sortAdviceByGroup = (advice: VacancyAdvice[]): VacancyAdvice[] => {
    const adviceImportant: VacancyAdvice[] = [];
    const advicePaid: VacancyAdvice[] = [];
    const adviceFree: VacancyAdvice[] = [];

    for (const item of advice) {
        switch (VacancyAdviceToGroup[item]) {
            case VacancyAdviceGroup.Important:
                if (SALARY_VACANCY_ADVICES.includes(item)) {
                    adviceImportant.unshift(item);
                } else {
                    adviceImportant.push(item);
                }
                break;
            case VacancyAdviceGroup.Paid:
                advicePaid.push(item);
                break;
            case VacancyAdviceGroup.Free:
                adviceFree.push(item);
                break;
        }
    }

    return [...adviceImportant, ...advicePaid, ...adviceFree];
};

export const sortBySeverity = (
    advices: VacancyAdvice[],
    adviceWithStatistics?: VacancyAdviceWithStatistics[]
): VacancyAdvice[] => {
    const majorAdvices: VacancyAdvice[] = [];
    const minorAdvices: VacancyAdvice[] = [];

    if (!adviceWithStatistics) {
        return [];
    }

    advices.forEach((adviceName) => {
        const { severity } = adviceWithStatistics.find((advice) => advice.advice === adviceName) ?? {
            severity: VacancyAdviceSeverity.Minor,
        };

        if (severity === VacancyAdviceSeverity.Major) {
            majorAdvices.push(adviceName);
        } else {
            minorAdvices.push(adviceName);
        }
    });

    return [...sortAdviceByGroup(majorAdvices), ...sortAdviceByGroup(minorAdvices)];
};

interface UserAgentDetails {
    browser: string;
}

export const isSafari = (): boolean => {
    if (typeof window?.bloko?.getUserAgentDetails !== 'function') {
        return true;
    }

    const userAgentInfo = window.bloko.getUserAgentDetails(window.navigator.userAgent) as UserAgentDetails;
    return userAgentInfo?.browser === 'safari';
};

export const getLinkUrl = (adviceItem: VacancyAdvice, vacancyId: number): string => {
    switch (adviceItem) {
        case VacancyAdvice.UseSearchTop:
            return `/vacancy/${vacancyId}?runAction=useSearchTop`;
        case VacancyAdvice.UseStandardPlus:
            return `/vacancy/${vacancyId}?runAction=upgrade`;
        case VacancyAdvice.UseResumeSearch:
            return (
                `/search/resume?vacancy_id=${vacancyId}&order_by=relevance` +
                '&search_period=365&from=my_vacancy_list_advice_modal'
            );
        case VacancyAdvice.ChangeVacancyName:
            return (
                `/employer/statistics/competitor_analysis/vacancy/${vacancyId}` +
                '?from=employer_vacancies&activeTab=competitorsAnalysis'
            );

        default:
            if (ADVICE_TO_ANCHOR[adviceItem] && !isSafari()) {
                return `${generatePath(paths.vacancyEdit, { vacancyId })}?anchor=${ADVICE_TO_ANCHOR[adviceItem]}`;
            }
            return generatePath(paths.vacancyEdit, { vacancyId });
    }
};

export const formatSalary = (marketSalary: VacancyAdviceMarketSalary): string =>
    marketSalary?.from && marketSalary?.to
        ? `: ${String(formatToInt(marketSalary.from))} ${EN_DASH} ${String(formatToInt(marketSalary.to))}`
        : '';
