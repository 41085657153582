import { Tag } from '@hh.ru/magritte-ui';
import { AstrostarOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import {
    VacancyAdvice,
    VacancyAdviceMetric,
    VacancyAdviceOutcome,
    VacancyAdviceSeverity,
    VacancyAdviceWithStatistics,
} from 'lux/models/vacancy/vacancyAdvices.types';

import useAnalyticsEventShownOnce from 'lux/components/VacancyModals/VacancyAdviceModal/useAnalyticsEventShownOnce';

interface VacancyStatisticTagProps {
    adviceItem: VacancyAdvice;
    adviceWithStatisticsItem: VacancyAdviceWithStatistics;
    vacancyId: number;
}

const TrlKeys = {
    outcome: {
        [VacancyAdviceOutcome.ImproveVacancy]: 'employer.myVacancies.advice.outcome.improveVacancy',
        [VacancyAdviceOutcome.PromoteVacancy]: 'employer.myVacancies.advice.outcome.promoteVacancy',
        [VacancyAdviceOutcome.AccelerateHiring]: 'employer.myVacancies.advice.outcome.accelerateHiring',
        [VacancyAdviceOutcome.IncreaseViews]: 'employer.myVacancies.advice.outcome.increaseViews',
        [VacancyAdviceOutcome.IncreaseMetricsRel]: '',
    },
    metric: {
        [VacancyAdviceMetric.ViewCount]: 'employer.myVacancies.advice.metric.viewCount',
        [VacancyAdviceMetric.ResponseCount]: 'employer.myVacancies.advice.metric.responseCount',
    },
    upTo: 'employer.myVacancies.advice.upTo',
};

const NO_PERCENT = '-';

const getPercentValue = (delta?: number): number | string => {
    if (!delta || !Number.isFinite(delta)) {
        return NO_PERCENT;
    }

    const percent = (delta * 100) / 2;
    return percent > 100 ? 100 : Math.ceil(percent);
};

const VacancyStatisticTag: TranslatedComponent<VacancyStatisticTagProps> = ({
    adviceItem,
    adviceWithStatisticsItem,
    vacancyId,
    trls,
}) => {
    const [firstDelta] = adviceWithStatisticsItem.deltas;
    const percent = getPercentValue(firstDelta?.deltaRel);

    const tagAdviceRef = useAnalyticsEventShownOnce({
        name: 'employer_vacancies_advice_modal_tag_shown',
        vacancyId,
        advice: adviceItem,
        severity: adviceWithStatisticsItem?.severity,
        outcome: adviceWithStatisticsItem?.outcome,
        metric: firstDelta?.metric,
        deltaPercent: firstDelta?.deltaRel,
        displayedPercent:
            adviceWithStatisticsItem.outcome === VacancyAdviceOutcome.IncreaseMetricsRel ? percent : NO_PERCENT,
    });

    return (
        <div ref={tagAdviceRef}>
            <Tag
                icon={AstrostarOutlinedSize16}
                style={adviceWithStatisticsItem.severity === VacancyAdviceSeverity.Major ? 'special' : 'neutral'}
            >
                {adviceWithStatisticsItem.outcome === VacancyAdviceOutcome.IncreaseMetricsRel
                    ? `${trls[TrlKeys.upTo]} +${percent}% ${trls[TrlKeys.metric[firstDelta?.metric]]}`
                    : trls[TrlKeys.outcome[adviceWithStatisticsItem.outcome]]}
            </Tag>
        </div>
    );
};

export default translation(VacancyStatisticTag);
