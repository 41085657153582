import { useCallback, memo, Dispatch } from 'react';
import { AnyAction } from 'redux';

import { FolderOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { BasicTrigger, ControlsTriggerActionType, TriggerType } from 'lux/components/ControlsTrigger';
import { MODAL_ARCHIVE, ADD_MODAL } from 'lux/components/VacancyModals/constants';
import translation from 'lux/components/translation';

import getMenuButtonIconProps from 'lux/components/VacancyActions/getMenuButtonIconProps';

const TrlKeys = {
    archive: 'employer.myVacancyes.archivate',
};

interface ArchiveActionProps {
    Component: React.FC<BasicTrigger>;
    triggerType: TriggerType;
    vacanciesIds: number[];
    vacancyName: string;
    dispatchModal: Dispatch<AnyAction>;
    additionalAnalyticsParams?: Record<string, string>;
    actionType?: ControlsTriggerActionType;
    icon?: React.ReactNode;
    onArchiveSuccess: (params: { isFeedbackSent: boolean; responseQuery: string }) => void;
    onClose?: () => void;
    onCancel?: () => void;
}

const ArchiveAction: TranslatedComponent<ArchiveActionProps> = ({
    Component,
    triggerType,
    vacanciesIds,
    vacancyName,
    dispatchModal,
    additionalAnalyticsParams,
    actionType,
    icon,
    trls,
    onArchiveSuccess,
    onClose,
    onCancel,
}) => {
    const triggerName = 'archive';
    const iconProps = getMenuButtonIconProps({
        triggerType,
        triggerName,
        icon: <FolderOutlinedSize24 initial="negative" />,
    });

    const openModal = useCallback(() => {
        dispatchModal({
            type: ADD_MODAL,
            payload: {
                modalType: MODAL_ARCHIVE,
                data: { vacanciesIds, vacancyName, onArchiveSuccess, onCancel },
            },
        });

        onClose && onClose();
    }, [dispatchModal, vacanciesIds, vacancyName, onArchiveSuccess, onCancel, onClose]);

    return (
        <Component
            triggerName={triggerName}
            triggerType={triggerType}
            onTrigger={openModal}
            batchLength={vacanciesIds.length}
            additionalAnalyticsParams={additionalAnalyticsParams}
            actionType={actionType}
            isPermitted
            icon={icon}
            {...iconProps}
        >
            {trls[TrlKeys.archive]}
        </Component>
    );
};

export default memo(translation(ArchiveAction));
