import { push } from 'connected-react-router';

import {
    autoProlongationPurchaseSuccess,
    autoProlongationPurchaseSuccesses,
    vacancyAutoProlongationError,
    vacancyAutoProlongationErrors,
    vacancyAutoProlongationSuccess,
    vacancyAutoProlongationSuccesses,
} from 'lux/components/Notifications/EmployerVacancies';
import { updateManagerVacancies } from 'lux/models/employerVacancies/managerVacancies';
import fetcher from 'lux/modules/fetcher';

export default ({ action, vacanciesIds, withPurchasing = false, agreementId, location }, addNotification) =>
    (dispatch, getState) => {
        const vacancies = getState()
            .managerVacancies.vacancies.list.filter((item) => vacanciesIds.includes(item.vacancyId))
            .map((item) => ({
                ...item,
                ...{
                    canEnableAutoProlongation: action === 'disable',
                    canDisableAutoProlongation: action === 'enable',
                },
            }));

        return fetcher
            .post(`/shards/employer/vacancies/auto_prolongation/${action}`, {
                vacanciesIds,
                withPurchasing,
                agreementId,
            })
            .then(
                (response) => {
                    if (location.pathname.match('/vacancy/')) {
                        dispatch(push(`${location.pathname}${response.data.query}`));
                        return;
                    }
                    if (vacanciesIds.length === 1) {
                        addNotification(vacancyAutoProlongationSuccess, {
                            props: { name: vacancies[0].name, action },
                        });
                        withPurchasing && addNotification(autoProlongationPurchaseSuccess);
                    } else {
                        addNotification(vacancyAutoProlongationSuccesses);
                        withPurchasing && addNotification(autoProlongationPurchaseSuccesses);
                    }

                    dispatch(updateManagerVacancies({ vacancies }));
                },
                () => {
                    if (vacanciesIds.length === 1) {
                        addNotification(vacancyAutoProlongationError, { props: { name: vacancies[0].name, action } });
                    } else {
                        addNotification(vacancyAutoProlongationErrors);
                    }
                }
            );
    };
