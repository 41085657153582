import { Dispatch, useEffect, useRef, useState } from 'react';
import { AnyAction } from 'redux';

import Analytics from '@hh.ru/analytics-js';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import useExperiment from 'lux/hooks/useExperiment';
import { VacancyAdviceInfo } from 'lux/models/employerVacancies/vacancy.types';
import { VacancyAdvice } from 'lux/models/vacancy/vacancyAdvices.types';
import { MIDDLE_DOT } from 'lux/modules/symbols';

import VacancyAdviceCard from 'lux/components/VacancyModals/VacancyAdviceModal/VacancyAdviceCard';
import VacancyAdviceModalMagritte from 'lux/components/VacancyModals/VacancyAdviceModal/VacancyAdviceModalMagritte';
import { sortAdviceByGroup, sortBySeverity } from 'lux/components/VacancyModals/VacancyAdviceModal/utils';

const TrlKeys = {
    title: 'employer.myVacancies.advice.modal.title',
    feedbackText: 'employer.myVacancies.advice.feedback.modal.parent.text',
    feedbackButton: 'employer.myVacancies.advice.feedback.modal.parent.button',
};

interface VacancyAdviceModalProps extends VacancyAdviceInfo {
    vacancyId: number;
    vacancyName: string;
    vacancyAreaName: string;
    isVisible: boolean;
    setAdvice: (advice: VacancyAdvice[]) => void;
    handleCloseModal: () => void;
    dispatchModal: Dispatch<AnyAction>;
    withFeedback?: boolean;
}

const VacancyAdviceModal: TranslatedComponent<VacancyAdviceModalProps> = ({
    advice,
    // tempexp_31119_next_line
    adviceWithStatistics,
    setAdvice,
    marketSalary,
    vacancyId,
    vacancyName,
    vacancyAreaName,
    isVisible,
    handleCloseModal,
    trls,
}) => {
    const [modalAdvice, setModalAdvice] = useState<VacancyAdvice[]>(advice);
    const feedbackButtonElementShownRef = useRef<HTMLDivElement>(null);
    const tagsAdviceModalExp = useExperiment('tags_advice_modal');

    const adviceSorted = tagsAdviceModalExp
        ? sortBySeverity(modalAdvice, adviceWithStatistics)
        : sortAdviceByGroup(modalAdvice);

    const hideAdviceItem = (adviceItem: VacancyAdvice) => {
        const newAdvice = modalAdvice.filter((currentAdviceItem: VacancyAdvice) => currentAdviceItem !== adviceItem);
        setModalAdvice(newAdvice);
        setAdvice(newAdvice);
        if (newAdvice.length === 0) {
            handleCloseModal();
        }
    };

    useEffect(() => {
        if (feedbackButtonElementShownRef.current) {
            Analytics.sendHHEventElementShown(feedbackButtonElementShownRef.current, {
                name: 'employer_vacancies_advice_feedback_button',
                vacancyId,
            });
        }
    }, [adviceSorted, isVisible, vacancyId]);

    const onCloseModal = () => {
        handleCloseModal();
        Analytics.sendHHEvent('employer_vacancies_advice_modal_close', {
            vacancyId,
        });
    };

    // tempexp_31119_next_line
    let modalContent = adviceSorted.map((advice, ind) => (
        <VacancyAdviceCard
            key={advice}
            adviceItem={advice}
            numInList={ind + 1}
            totalInList={adviceSorted.length}
            vacancyId={vacancyId}
            marketSalary={marketSalary}
            hideAdviceItem={hideAdviceItem}
        />
    ));

    // tempexp_31119_start
    if (tagsAdviceModalExp && adviceWithStatistics) {
        modalContent = adviceSorted.map((adviceName, ind) => {
            const adviceItem = adviceWithStatistics.find((advice) => advice.advice === adviceName);
            return (
                <VacancyAdviceCard
                    key={adviceName}
                    adviceItem={adviceName}
                    adviceWithStatisticsItem={adviceItem}
                    numInList={ind + 1}
                    totalInList={adviceSorted.length}
                    vacancyId={vacancyId}
                    marketSalary={marketSalary}
                    hideAdviceItem={hideAdviceItem}
                />
            );
        });
    }
    // tempexp_31119_end

    return (
        <VacancyAdviceModalMagritte
            // tempexp_31119_next_line
            adviceWithStatistics={adviceWithStatistics}
            advices={adviceSorted}
            visible={isVisible}
            onClose={onCloseModal}
            vacancyId={vacancyId}
            title={trls[TrlKeys.title]}
            titleDescription={`${vacancyName} ${MIDDLE_DOT} ${vacancyAreaName}`}
        >
            {modalContent}
        </VacancyAdviceModalMagritte>
    );
};

export default translation(VacancyAdviceModal);
