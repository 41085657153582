import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useNotification } from 'lux/components/Notifications/Provider';
import { fetchAndUpdateVacanciesCount } from 'lux/models/employerVacancies/vacanciesCount';
import { removeUnpremoderatedVacancyAction } from 'lux/models/unpremoderatedVacancyList';
import fetcher from 'lux/modules/fetcher';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

const APPROVE_URL = '/employer/unpremoderatedvacancylist/approve';

declare global {
    interface FetcherPostApi {
        [APPROVE_URL]: {
            queryParams: void;
            body: {
                id: number;
            };
            response: {
                id: string;
            };
        };
    }
}

export const useUnpremoderatedVacanciesActionsFetcher: () => {
    approveUnpremoderatedVacancy: (moderatorId: number, vacancyId: number) => Promise<void>;
    isLoading: boolean;
} = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { addNotification } = useNotification();
    const dispatch = useDispatch();

    const approveUnpremoderatedVacancy = async (moderatorId: number, vacancyId: number) => {
        if (isLoading) {
            return;
        }

        try {
            setIsLoading(true);

            await fetcher.postFormData(APPROVE_URL, {
                id: vacancyId,
            });

            dispatch(removeUnpremoderatedVacancyAction({ moderatorId, vacancyId }));
            void fetchAndUpdateVacanciesCount(dispatch, moderatorId);
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
        } finally {
            setIsLoading(false);
        }
    };

    return { isLoading, approveUnpremoderatedVacancy };
};
