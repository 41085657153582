import { DefaultRootState } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { ChangeContactFormValues } from 'lux/components/VacancyContactsChangeModal/ChangeContactsForm';
import { CardValue } from 'lux/components/VacancyContactsChangeModal/utils';
import { moveManagerVacancies } from 'lux/models/employerVacancies/managerVacancies';
import { EmployerManager } from 'lux/models/employerVacancies/vacancy.types';
import fetcher from 'lux/modules/fetcher';

const MOVE_VACANCIES_AND_CHANGE_CONTACTS_URL = '/employer/movevacancies_changecontacts';

declare global {
    interface FetcherPostApi {
        [MOVE_VACANCIES_AND_CHANGE_CONTACTS_URL]: {
            queryParams: void;
            body: {
                id: number[];
                targetManagerId: number;
                fio: string;
                phone?: string;
                email?: string;
                comment?: string;
                additionalPhone?: string;
                additionalComment?: string;
                updateContactsAction: CardValue;
            };
            response: void;
        };
    }
}
export default ({
        vacanciesIds,
        filterEmployerManagerIdList,
        managerToTransfer,
        groupId,
        currentGroupManagersIdsSet,
        changeContactFormValues,
        updateContactsAction,
    }: {
        vacanciesIds: number[];
        filterEmployerManagerIdList: string[];
        managerToTransfer: EmployerManager;
        groupId: string | undefined;
        currentGroupManagersIdsSet: Set<number>;
        changeContactFormValues: ChangeContactFormValues;
        updateContactsAction: CardValue;
    }): ThunkAction<Promise<unknown>, DefaultRootState, unknown, AnyAction> =>
    async (dispatch) => {
        return fetcher
            .postFormData(MOVE_VACANCIES_AND_CHANGE_CONTACTS_URL, {
                id: vacanciesIds,
                targetManagerId: managerToTransfer.managerId,
                updateContactsAction,
                ...changeContactFormValues,
            })
            .then(() => {
                dispatch(
                    moveManagerVacancies({
                        checkedVacancies: vacanciesIds,
                        managerToTransfer,
                        filterEmployerManagerIdList,
                        groupId,
                        groupManagersIds: currentGroupManagersIdsSet,
                    })
                );
            })
            .catch((err) => {
                throw err;
            });
    };
