import PropTypes from 'prop-types';

export const RenewalObjectType = PropTypes.shape({
    ids: PropTypes.arrayOf(PropTypes.number),
    vacancyProperties: PropTypes.object,
    scheduleTypesWithUpdatesCount: PropTypes.object,
    closestPossibleProlongation: PropTypes.string,
    maxLastUpdateDateTime: PropTypes.string,
    prolongationFailureReason: PropTypes.string,
});
