import { Fragment, memo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import removeScheduleButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/vacancies/remove_schedule_button_click';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Modal, { ModalHeader, ModalTitle, ModalContent, ModalFooter } from 'bloko/blocks/modal';
import { formatToReactComponent } from 'bloko/common/trl';

import HumanDate from 'lux/components/HumanDate';
import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';

import { removeSchedule } from 'lux/components/VacancyModals/AutoUpdateModal/autoUpdateSchedule';

const RemoveAutoUpdateModal = ({ handleCloseModal, expireTime, vacancyId, isVisible, vacancyName, trls }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const [loading, setLoading] = useState(false);
    const handleSubmit = useCallback(() => {
        setLoading(true);
        removeScheduleButtonClick({ vacancyId });
        dispatch(removeSchedule({ vacancyId }, vacancyName, addNotification)).then(handleCloseModal, () =>
            setLoading(false)
        );
    }, [addNotification, dispatch, handleCloseModal, vacancyId, vacancyName]);
    return (
        <Modal visible={isVisible} onClose={handleCloseModal}>
            <Fragment>
                <ModalHeader>
                    <ModalTitle>{trls[RemoveAutoUpdateModal.trls.title]}</ModalTitle>
                </ModalHeader>
                <ModalContent>
                    {formatToReactComponent(trls[RemoveAutoUpdateModal.trls.description], {
                        '{0}': <HumanDate date={expireTime} textify />,
                    })}
                </ModalContent>
                <ModalFooter>
                    <Button onClick={handleCloseModal} data-qa="vacancy-auto-update-remove-close">
                        {trls[RemoveAutoUpdateModal.trls.cancel]}
                    </Button>
                    <FormSpacer>
                        <Button
                            onClick={handleSubmit}
                            kind={ButtonKind.Primary}
                            loading={loading && <Loading scale={LoadingScale.Small} />}
                            data-qa="vacancy-auto-update-remove-submit"
                        >
                            {trls[RemoveAutoUpdateModal.trls.submit]}
                        </Button>
                    </FormSpacer>
                </ModalFooter>
            </Fragment>
        </Modal>
    );
};

RemoveAutoUpdateModal.propTypes = {
    isVisible: PropTypes.bool,
    vacancyName: PropTypes.string,
    vacancyId: PropTypes.number,
    expireTime: PropTypes.number,
    handleCloseModal: PropTypes.func,
    trls: PropTypes.object,
};

RemoveAutoUpdateModal.trls = {
    title: 'vacancy.autoUpdate.remove.title',
    submit: 'vacancy.autoUpdate.remove.submit',
    cancel: 'vacancy.autoUpdate.remove.cancel',
    description: 'vacancy.autoUpdate.remove.description',
};

export default memo(translation(RemoveAutoUpdateModal));
