import { AnyAction, Dispatch } from 'redux';

import updateVacancyInfoAfterSuccessAction from 'lux/components/EmployerVacancies/updateVacancyInfoAfterSuccessAction';
import { AddNotification } from 'lux/components/Notifications/Provider/types';
import {
    autoUpdatesEdited,
    autoUpdatesScheduled,
} from 'lux/components/VacancyModals/AutoUpdateModal/AutoUpdatesEditNotification';
import fetcher from 'lux/modules/fetcher';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

const POST_AUTO_UPDATE_URL = '/shards/employer/vacancies/auto_update/schedule';

declare global {
    interface FetcherPostApi {
        [POST_AUTO_UPDATE_URL]: {
            queryParams: PostParams;
            body: PostBodyParams;
            response: void;
        };
    }
}

interface PostParams {
    scheduleType: string;
    start: string;
    end: string;
}

interface PostBodyParams {
    vacancyId: number[];
}

const postUpdate = (params: PostParams, bodyParams: PostBodyParams) =>
    fetcher.post(POST_AUTO_UPDATE_URL, bodyParams, { params });

const schedule =
    (params: PostParams & PostBodyParams, isEdit: boolean, isGroup = false, addNotification: AddNotification) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        const { vacancyId, ...otherParams } = params;
        try {
            await postUpdate(otherParams, { vacancyId });
        } catch (err) {
            defaultRequestErrorHandler(err, addNotification);
            throw err;
        }

        try {
            await dispatch(updateVacancyInfoAfterSuccessAction({ addNotification, vacancyIds: vacancyId }));
        } catch (err) {
            defaultRequestErrorHandler(err, addNotification);
            throw err;
        }

        if (isGroup) {
            return;
        }

        addNotification(isEdit ? autoUpdatesEdited : autoUpdatesScheduled);
    };
export default schedule;
