import Analytics from '@hh.ru/analytics-js';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';
import MetallicVacancyType from 'lux/models/vacancy/metallicVacancyType.types';

import prolongateUpgradeVacancy from 'lux/components/VacancyModals/Prolongation/prolongateUpgradeVacancy';

import styles from './upgrade.less';

interface UpgradeProps {
    afterSuccessAction: ({
        handleCloseModal,
        queryParams,
        vacancyIds,
    }: {
        handleCloseModal: () => void;
        queryParams: Record<string, number[]>;
        vacancyIds: number[];
    }) => void;
    handleCloseModal: () => void;
    loading: boolean;
    startLoading: () => void;
    finishLoading: () => void;
}

const TrlKeys = {
    refresh: 'vacancyRefresh.link',
    upgrade: 'employer.myVacancies.prolongate.upgrade',
};

const Upgrade: TranslatedComponent<UpgradeProps> = ({
    afterSuccessAction,
    handleCloseModal,
    loading,
    startLoading,
    finishLoading,
    trls,
}) => {
    const availableToUpgrade = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToUpgrade);
    // Блок upgrade рисуется только вместе с покупкой
    const vacancyIds = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToPurchase.vacancyIds);

    const { addNotification } = useNotification();

    const upgrade = async (targetMetallic: MetallicVacancyType) => {
        if (loading) {
            return;
        }

        Analytics.sendHHEventButtonClick('modal_prolongation_link', {
            vacanciesIds: vacancyIds,
        });

        startLoading();
        try {
            const queryParams = await prolongateUpgradeVacancy({
                addNotification,
                targetMetallic,
                vacancyId: vacancyIds[0],
            });

            if (afterSuccessAction) {
                afterSuccessAction({
                    handleCloseModal,
                    queryParams,
                    vacancyIds,
                });

                return;
            }
            // Нельзя написать в finally, т.к. будет закрывать любую модалку, в т.ч. открытую в afterSuccessAction
            handleCloseModal();
        } catch (ignore) {
            console.error(ignore);
            handleCloseModal();
        } finally {
            finishLoading();
        }
    };

    if (!availableToUpgrade) {
        return null;
    }

    return (
        <>
            <Text>{trls[TrlKeys.upgrade]}</Text>
            <VSpacing base={2} />
            <div>
                {availableToUpgrade.map(({ service, serviceTrl }) => (
                    <div className={styles.wrapper} key={service} data-qa={`prolongate-upgrade_${service}`}>
                        <Text>{serviceTrl}</Text>
                        <VSpacing base={1} />
                        <Link
                            appearance={LinkAppearance.Pseudo}
                            data-qa={`prolongate-upgrade-button_${service}`}
                            onClick={async () => {
                                await upgrade(service);
                            }}
                        >
                            {trls[TrlKeys.refresh]}
                            {loading && <Loading scale={LoadingScale.Small} />}
                        </Link>
                    </div>
                ))}
            </div>
            <VSpacing base={6} />
        </>
    );
};

export default translation(Upgrade);
