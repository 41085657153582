import formatISO from 'date-fns/formatISO';

import { DateIsoString } from 'bloko/blocks/calendar/datesHelper';

import { AddNotification } from 'lux/components/Notifications/Provider/types';
import fetcher from 'lux/modules/fetcher';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

const GET_AUTO_UPDATE_COUNT_URL = '/shards/employer/vacancies/auto_update/count';

type UpdateCountByScheduleType = Record<string, { count: number }>;

declare global {
    interface FetcherGetApi {
        [GET_AUTO_UPDATE_COUNT_URL]: {
            queryParams: {
                amount: number;
                scheduleType: string[];
                start: DateIsoString;
                end: DateIsoString;
            };
            response: {
                updateCountByScheduleType: UpdateCountByScheduleType;
            };
        };
    }
}
interface Params {
    scheduleTypes: string[];
    startDate: Date;
    endDate: Date;
    amount: number;
}
const getAutoUpdateCount = ({ scheduleTypes, startDate, endDate, amount }: Params) =>
    fetcher.get(GET_AUTO_UPDATE_COUNT_URL, {
        params: {
            amount,
            scheduleType: scheduleTypes,
            start: formatISO(startDate),
            end: formatISO(endDate),
        },
    });

const autoUpdateCount =
    (params: Params, addNotification: AddNotification) => async (): Promise<UpdateCountByScheduleType> => {
        try {
            const { updateCountByScheduleType } = await getAutoUpdateCount(params);
            return updateCountByScheduleType;
        } catch (err) {
            defaultRequestErrorHandler(err, addNotification);
            throw err;
        }
    };
export default autoUpdateCount;
