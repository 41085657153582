import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ProlongateInfoAvailableToPurchase from 'lux/components/VacancyModals/components/ProlongateInfoAvailableToPurchase';
import translation from 'lux/components/translation';

const TrlKeys = {
    title: 'vacancy.autoUpdate.detail.buyPlan.haveToBuyTitle',
};

const AvailableToPurchase: TranslatedComponent = ({ trls }) => {
    const availableToSpendIds = useSelectorNonNullable(
        ({ autoUpdateInfo }) => autoUpdateInfo.availableToSpend.vacancyIds
    );
    const unavailableProducts = useSelectorNonNullable(({ autoUpdateInfo }) => autoUpdateInfo.unavailable.vacancyIds);

    const isAvailableToSpendIds = availableToSpendIds.length > 0;
    const isUnavailableProducts = unavailableProducts.length > 0;

    const purchase = useSelectorNonNullable(({ autoUpdateInfo }) => autoUpdateInfo.availableToPurchase.purchase);

    if (!purchase || purchase.products.length === 0) {
        return null;
    }

    return (
        <div>
            {isAvailableToSpendIds || isUnavailableProducts ? (
                <div>
                    <H3>{trls[TrlKeys.title]}</H3>
                    <VSpacing base={4} />
                </div>
            ) : null}
            <ProlongateInfoAvailableToPurchase purchase={purchase} />
            <VSpacing base={6} />
        </div>
    );
};

export default translation(AvailableToPurchase);
