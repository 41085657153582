import { useCallback } from 'react';

import { Button, ButtonMode, ButtonSize } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useUnpremoderatedVacanciesActionsFetcher } from 'lux/components/VacancyModals/UnpremoderatedVacancyPreview/useFetcher';
import translation from 'lux/components/translation';
import { UnpremoderatedVacancy } from 'lux/models/unpremoderatedVacancyList';

const TrlKeys = {
    approve: 'employer.unpremoderated.vacancyApprove',
};

interface ApproveButtonProps {
    vacancy: UnpremoderatedVacancy;
    moderatorId: number;
    handleClick?: () => void;
    stretched?: boolean;
    mode?: ButtonMode;
    size?: ButtonSize;
}

const ApproveButton: TranslatedComponent<ApproveButtonProps> = ({
    trls,
    vacancy,
    moderatorId,
    handleClick,
    stretched = false,
    mode = 'primary',
    size = 'small',
}) => {
    const { isLoading, approveUnpremoderatedVacancy } = useUnpremoderatedVacanciesActionsFetcher();

    const handleApproveClick = useCallback(async () => {
        await approveUnpremoderatedVacancy(moderatorId, vacancy.id);

        handleClick?.();
    }, [approveUnpremoderatedVacancy, moderatorId, handleClick, vacancy.id]);

    return (
        <Button
            style="accent"
            mode={mode}
            data-id={vacancy.id}
            onClick={handleApproveClick}
            disabled={isLoading}
            stretched={stretched}
            size={size}
        >
            {trls[TrlKeys.approve]}
        </Button>
    );
};

export default translation(ApproveButton);
