import { createContext, Dispatch } from 'react';
import { AnyAction } from 'redux';

interface CheckContextType {
    checkedDrafts: number[];
    dispatchCheckDraft: Dispatch<AnyAction>;
}

const DUMMY_FUNCTION = () => {
    // do nothing
};

const CheckContext = createContext<CheckContextType>({
    checkedDrafts: [],
    dispatchCheckDraft: DUMMY_FUNCTION,
});

export default CheckContext;
