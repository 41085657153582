import { AnyAction, Dispatch } from 'redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer/lib/createReducer';
import { DateIsoString } from 'bloko/blocks/calendar/datesHelper';

import { AddNotification } from 'lux/components/Notifications/Provider/types';
import { AutoUpdateInfo } from 'lux/models/autoUpdateInfo';
import fetcher from 'lux/modules/fetcher';

import vacancyAutoUpdateErrorNotification from 'lux/components/VacancyModals/AutoUpdateModalExp/VacancyAutoUpdateErrorNotification';

const GET_AUTO_UPDATE_INFO_URL = '/employer/vacancy/auto_update_info';
const setAutoUpdateInfo = makeSetStoreField('autoUpdateInfo');

declare global {
    interface FetcherGetApi {
        [GET_AUTO_UPDATE_INFO_URL]: {
            queryParams: {
                vacanciesIds: number[];
                scheduleType: string;
                start: DateIsoString;
                end: DateIsoString;
            };
            response: AutoUpdateInfo;
        };
    }
}
interface Params {
    ids: number[];
    start: DateIsoString;
    end: DateIsoString;
    scheduleType: string;
}
const getAutoUpdateInfo = ({ ids, start, end, scheduleType }: Params) =>
    fetcher.get(GET_AUTO_UPDATE_INFO_URL, {
        params: {
            scheduleType,
            vacanciesIds: ids,
            start,
            end,
        },
    });

const autoUpdateInfo =
    (params: Params, addNotification: AddNotification) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        try {
            const info = await getAutoUpdateInfo(params);
            dispatch(setAutoUpdateInfo(info));
        } catch (error) {
            addNotification(vacancyAutoUpdateErrorNotification);
            throw error;
        }
    };
export default autoUpdateInfo;
