import { useState } from 'react';

import modalArchiveApplicantsFeedbackButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/archive/modal_archive_applicants_feedback_button_click';
import {
    BottomSheet,
    BottomSheetFooter,
    Button,
    Modal,
    NavigationBar,
    Text,
    TextArea,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import VSpacing from 'bloko/blocks/vSpacing';
import format from 'bloko/common/format';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import defaultError from 'lux/components/Notifications/DefaultError';
import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';

const SEND_FEEDBACK_MESSAGES = '/shards/employer/vacancies/archive_vacancy_applicants_feedback';

interface FeedbackMessageType {
    message: string;
    vacancyIds: number[];
    disableChatForApplicant?: boolean;
}

declare global {
    interface FetcherPostApi {
        [SEND_FEEDBACK_MESSAGES]: {
            body: FeedbackMessageType;
            response: void;
            queryParams: void;
        };
    }
}

type OnCloseParams = { feedbackSent: boolean };
interface Props {
    visible: boolean;
    vacanciesIds: number[];
    onClose: (params?: OnCloseParams) => void;
}

const TrlKeys = {
    messageTemplate: 'employer.vacancy.archive.applicantsFeedback.modal.messageTemplate',
    headerOne: 'employer.vacancy.archive.applicantsFeedback.modal.header.one',
    headerMany: 'employer.vacancy.archive.applicantsFeedback.modal.header.many',
    text: 'employer.vacancy.archive.applicantsFeedback.modal.text',
    close: 'employer.vacancy.archive.applicantsFeedback.modal.close',
    sendMessage: 'employer.vacancy.archive.applicantsFeedback.modal.sendMessage',
};

const ArchiveVacancyApplicantsFeedback: TranslatedComponent<Props> = ({ visible, vacanciesIds, onClose, trls }) => {
    const { isMobile } = useBreakpoint();
    const { addNotification } = useNotification();
    const employerManager = useSelector((state) => state.employerManager);
    const [message, setMessage] = useState(
        format(trls[TrlKeys.messageTemplate], {
            '{0}': employerManager ? `${employerManager.lastName} ${employerManager.firstName}` : '',
        })
    );
    const sendFeedback = async () => {
        try {
            await fetcher.post(SEND_FEEDBACK_MESSAGES, {
                message,
                vacancyIds: vacanciesIds,
            });
            modalArchiveApplicantsFeedbackButtonClick({
                type: 'send_message',
                vacancyIdList: vacanciesIds.toString(),
            });
            onClose({ feedbackSent: true });
        } catch (e) {
            addNotification(defaultError);
        }
    };

    const modalTitle = vacanciesIds.length === 1 ? trls[TrlKeys.headerOne] : trls[TrlKeys.headerMany];
    const modalContent = (
        <>
            <Text typography="paragraph-2-regular">{trls[TrlKeys.text]}</Text>
            <VSpacing base={6} />
            <TextArea
                data-qa="archive-vacancy-feedback-text"
                layout="hug"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
            />
        </>
    );
    const modalButtons = (
        <>
            <Button
                data-qa="archive-vacancy-feedback-close"
                mode="secondary"
                style="accent"
                onClick={() => {
                    modalArchiveApplicantsFeedbackButtonClick({
                        type: 'cancel_message',
                        vacancyIdList: vacanciesIds.toString(),
                    });
                    onClose();
                }}
            >
                {trls[TrlKeys.close]}
            </Button>
            <Button data-qa="archive-vacancy-feedback-submit" mode="primary" style="accent" onClick={sendFeedback}>
                {trls[TrlKeys.sendMessage]}
            </Button>
        </>
    );

    return (
        <>
            <Modal
                visible={visible}
                title={modalTitle}
                titleSize="medium"
                rightButtons={modalButtons}
                onClose={onClose as VoidFunction}
            >
                {modalContent}
            </Modal>
            <BottomSheet
                visible={isMobile && visible}
                header={<NavigationBar title={modalTitle} />}
                footer={<BottomSheetFooter>{modalButtons}</BottomSheetFooter>}
                onClose={onClose}
            >
                {modalContent}
            </BottomSheet>
        </>
    );
};

export default translation(ArchiveVacancyApplicantsFeedback);
