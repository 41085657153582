import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { IconColor } from 'bloko/blocks/icon';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';
import { VacancyToProlongate } from 'lux/models/employerVacanciesRise';
import { Purchase } from 'lux/models/prolongateInfo';

import prolongateAndBuyVacancy from 'lux/components/VacancyModals/Prolongation/prolongateAndBuyVacancy';

interface ProlongateAndBuyButtonProps {
    handleCloseModal: () => void;
    loading: boolean;
    startLoading: () => void;
    finishLoading: () => void;
    vacanciesInfoToProlongate: VacancyToProlongate[];
}

const TrlKeys = {
    prolongateAndBuy: 'vacancy.prolongate.submit.prolongateAndBuy',
};

const ProlongateAndBuyButton: TranslatedComponent<ProlongateAndBuyButtonProps> = ({
    handleCloseModal,
    loading,
    startLoading,
    finishLoading,
    vacanciesInfoToProlongate,
    trls,
}) => {
    const availableToFreeProlongateIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds
    );
    const availableToSpendIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToSpend.vacancyIds
    );
    const availableToPurchaseIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToPurchase.vacancyIds
    );
    const products = useSelectorNonNullable(
        ({ prolongateInfo }) => (prolongateInfo.availableToPurchase.purchase as Purchase).products
    );

    const { addNotification } = useNotification();

    const isXs = useBreakpoint() === Breakpoint.XS;

    const dispatch = useDispatch();

    const vacanciesToBuy = useMemo(
        () => vacanciesInfoToProlongate.filter(({ vacancyId }) => availableToPurchaseIds.includes(vacancyId)),
        [availableToPurchaseIds, vacanciesInfoToProlongate]
    );

    const prolongateAndBuy = async () => {
        if (loading) {
            return;
        }

        Analytics.sendHHEventButtonClick(
            'modal_prolongation_prolongate_and_buy_button',
            {},
            // Из-за большого кол-ва вакансий используем body, а не query параметры
            {
                availableToFreeProlongateIds,
                availableToSpendIds,
                availableToPurchaseIds,
            }
        );

        startLoading();
        try {
            await dispatch(
                prolongateAndBuyVacancy({
                    addNotification,
                    availableToSpendVacancyIds: availableToSpendIds,
                    freeRenewalVacancyIds: availableToFreeProlongateIds,
                    products,
                    purchaseParams: {
                        source: 'prolongatePopup',
                    },
                    vacanciesToBuy,
                })
            );
        } catch (ignore) {
            console.error(ignore);
            finishLoading();
            handleCloseModal();
        }
    };

    return (
        <Button
            data-qa="prolongate-and-buy-button"
            disabled={loading}
            kind={ButtonKind.Primary}
            loading={loading && <Loading scale={LoadingScale.Small} initial={IconColor.White} />}
            onClick={prolongateAndBuy}
            stretched={isXs}
        >
            {trls[TrlKeys.prolongateAndBuy]}
        </Button>
    );
};

export default translation(ProlongateAndBuyButton);
