import { ChangeEventHandler } from 'react';

import { Text, CheckableCard } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

import { CardValue } from 'lux/components/VacancyContactsChangeModal/utils';

import styles from './vacancy-contacts-change-modal.less';

const TrlKeys = {
    [CardValue.Update]: 'vacancy.contacts.change.modal.button.update',
    [CardValue.Hide]: 'vacancy.contacts.change.modal.button.hide',
    [CardValue.DontChange]: 'vacancy.contacts.change.modal.button.dont.change',
};

const CardComponent: TranslatedComponent<{
    value: CardValue;
    setValue: (value: CardValue) => void;
    checkedCardValue: CardValue;
}> = ({ trls, value, setValue, checkedCardValue }) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
        setValue(event.target.value as CardValue);
    };

    return (
        <CheckableCard
            padding={16}
            borderRadius={12}
            type="checkbox"
            flexible
            value={value}
            checked={checkedCardValue === value}
            onChange={handleChange}
        >
            <span className={styles.controlsText}>
                <Text typography="label-2-regular">{trls[TrlKeys[value]]}</Text>
            </span>
        </CheckableCard>
    );
};

export default translation(CardComponent);
