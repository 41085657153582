import NumberFormatter from 'bloko/common/numberFormatter';

import { NARROW_NON_BREAKING_SPACE } from 'lux/modules/symbols';

/**
 * Форматирует число в удобный для чтения формат. Пример '12 345'
 */
const formatToInt = (value: number | null | undefined): string | null => {
    if (value === null || value === undefined) {
        return null;
    }
    const result = Math.round(value);
    return NumberFormatter.format(String(result), {
        groupSeparator: NARROW_NON_BREAKING_SPACE,
        decimalLength: 0,
    });
};

export default formatToInt;
