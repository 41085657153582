import { useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import modalResponseWithMessageSubmitButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/vacancies/modal_response_with_message_submit_button_click';
import {
    Action,
    BottomSheet,
    Button,
    Divider,
    Modal,
    NavigationBar,
    Text,
    TextArea,
    Title,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import format from 'bloko/common/format';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import archiveVacancyApplicantsFeedbackSuccess from 'lux/components/Notifications/ArchiveVacancyApplicantsFeedbackSuccess';
import defaultError from 'lux/components/Notifications/DefaultError';
import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';
import { updateManagerVacancyNegotiationsNotProcessedResponsesCount } from 'lux/models/employerVacancies/managerVacancies';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';

import styles from './response-with-message.less';

const SEND_MESSAGES = '/shards/employer/vacancies/archive_vacancy_applicants_feedback';
interface MessageType {
    message: string;
    vacancyIds: number[];
    disableChatForApplicant?: boolean;
}

declare global {
    interface FetcherPostApi {
        [SEND_MESSAGES]: {
            body: MessageType;
            response: void;
            queryParams: void;
        };
    }
}

interface ResponseWithMessageProps {
    isVisible: boolean;
    vacancyId: number;
    notProcessedResponses: number;
    onClose: () => void;
}

const TrlKeys = {
    messageTemplate: 'employer.vacancy.responseWithMessage.modal.messageTemplate',
    title: 'employer.vacancy.responseWithMessage.modal.title',
    description: 'employer.vacancy.responseWithMessage.modal.description',
    hint: 'employer.vacancy.responseWithMessage.modal.hint',
    candidatesOne: 'employer.vacancy.responseWithMessage.modal.candidates.one',
    candidatesMany: 'employer.vacancy.responseWithMessage.modal.candidates.many',
    sendMessage: 'employer.vacancy.responseWithMessage.modal.sendMessage',
    placeholder: 'employer.vacancy.responseWithMessage.modal.placeholder',
};

const ResponseWithMessage: TranslatedComponent<ResponseWithMessageProps> = ({
    isVisible,
    vacancyId,
    notProcessedResponses,
    onClose,
    trls,
}) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const employerManager = useSelector((state) => state.employerManager);
    const [message, setMessage] = useState(
        format(trls[TrlKeys.messageTemplate], {
            '{0}': employerManager ? `${employerManager.lastName} ${employerManager.firstName}` : '',
        })
    );

    const sendMessages = async () => {
        try {
            await fetcher.post(SEND_MESSAGES, {
                message,
                vacancyIds: [vacancyId],
                disableChatForApplicant: false,
            });
            modalResponseWithMessageSubmitButtonClick({ vacancyId: vacancyId.toString() });
            dispatch(updateManagerVacancyNegotiationsNotProcessedResponsesCount({ vacancyId }));
            addNotification(archiveVacancyApplicantsFeedbackSuccess);

            onClose();
        } catch (e) {
            addNotification(defaultError);
        }
    };

    const modalContent = (
        <>
            <TextArea
                placeholder={trls[TrlKeys.placeholder]}
                elevatePlaceholder
                layout="hug"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
            />
            <VSpacing default={4} />
            <Text typography="label-3-regular" style="secondary">
                {trls[TrlKeys.hint]}
            </Text>
        </>
    );

    const candidatesText = notProcessedResponses === 1 ? TrlKeys.candidatesOne : TrlKeys.candidatesMany;
    const modalButtons = (
        <>
            <Text typography="label-3-regular" style="secondary">
                {format(trls[candidatesText], { '{0}': notProcessedResponses })}
            </Text>
            <Button mode="primary" style="accent" onClick={sendMessages}>
                {trls[TrlKeys.sendMessage]}
            </Button>
        </>
    );

    return (
        <>
            <Modal
                actions={<Action mode="secondary" onClick={onClose} icon={CrossOutlinedSize24} />}
                visible={isVisible}
                title={trls[TrlKeys.title]}
                titleSize="medium"
                titleDescription={trls[TrlKeys.description]}
                titleDescriptionStyle="secondary"
                rightButtons={<div className={styles.buttons}>{modalButtons}</div>}
                onClose={onClose}
            >
                {modalContent}
            </Modal>
            <BottomSheet
                visible={isVisible}
                header={
                    <>
                        <NavigationBar right={{ icon: CrossOutlinedSize24, onClick: onClose }} />
                        <div className={classnames(styles.headerModal)}>
                            <Title Element="h4" size="medium">
                                {trls[TrlKeys.title]}
                            </Title>
                            <VSpacing default={4} />
                            <Text typography="label-2-regular" style="secondary">
                                {trls[TrlKeys.description]}
                            </Text>
                        </div>
                    </>
                }
                footer={
                    <>
                        <VSpacing default={16} />
                        <Divider />
                        <div className={styles.buttonsMobile}>{modalButtons}</div>
                    </>
                }
                onClose={onClose}
            >
                {modalContent}
            </BottomSheet>
        </>
    );
};

export default translation(ResponseWithMessage);
