import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ProlongateInfoAvailableToSpend from 'lux/components/VacancyModals/components/ProlongateInfoAvailableToSpend';
import translation from 'lux/components/translation';

const TrlKeys = {
    title: 'vacancy.autoUpdate.detail.buyPlan.existPlanTitle',
};

const AvailableToSpend: TranslatedComponent = ({ trls }) => {
    const purchaseProducts = useSelectorNonNullable(
        ({ autoUpdateInfo }) => autoUpdateInfo.availableToPurchase.purchase?.products
    );

    const unavailableProducts = useSelectorNonNullable(({ autoUpdateInfo }) => autoUpdateInfo.unavailable.vacancyIds);

    const isPurchaseProducts = purchaseProducts ? purchaseProducts.length > 0 : false;
    const isUnavailableProducts = unavailableProducts.length > 0;

    const publications = useSelectorNonNullable(({ autoUpdateInfo }) => autoUpdateInfo.availableToSpend.publications);

    if (!publications || publications.length === 0) {
        return null;
    }

    return (
        <>
            {isPurchaseProducts || isUnavailableProducts ? (
                <div>
                    <H3>{trls[TrlKeys.title]}</H3>
                    <VSpacing base={4} />
                </div>
            ) : null}
            <ProlongateInfoAvailableToSpend publications={publications} />
            <VSpacing base={6} />
        </>
    );
};

export default translation(AvailableToSpend);
