import { useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from 'bloko/blocks/button';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import Modal, { ModalHeader, ModalTitle, ModalFooter, ModalContent } from 'bloko/blocks/modal';
import Text, { TextImportance } from 'bloko/blocks/text';

import { UNCHECK_ALL } from 'lux/components/EmployerVacancies/checkReducer';
import translation from 'lux/components/translation';

import VacanciesWithLocation from 'lux/components/VacancyModals/AutoUpdateModal/VacanciesWithLocation';

const GroupAutoUpdateSummary = ({
    updatedIds = [],
    failedIds = [],
    handleCloseModal,
    dispatchCheckVacancy,
    isVisible,
    trls,
    afterSuccessAction,
}) => {
    const hasFailedVacancies = failedIds.length > 0;

    const handleCloseUnchecked = useCallback(() => {
        dispatchCheckVacancy({ type: UNCHECK_ALL });

        if (afterSuccessAction && !hasFailedVacancies) {
            afterSuccessAction();

            return;
        }

        handleCloseModal();
    }, [dispatchCheckVacancy, handleCloseModal, afterSuccessAction, hasFailedVacancies]);

    const renderUpdatedVacancies = () => {
        if (!updatedIds.length) {
            return null;
        }

        const updatedVacancies = (
            <VacanciesWithLocation ids={updatedIds} title={trls[GroupAutoUpdateSummary.trls.succeeded]} />
        );

        if (hasFailedVacancies) {
            return <Gap bottom>{updatedVacancies}</Gap>;
        }

        return updatedVacancies;
    };

    return (
        <Modal visible={isVisible} onClose={handleCloseUnchecked}>
            <ModalHeader outlined>
                <ModalTitle>{trls[GroupAutoUpdateSummary.trls.title]}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <Column xs="4" s="7" m="8" l="8" container>
                    {renderUpdatedVacancies()}
                    {hasFailedVacancies && (
                        <>
                            <VacanciesWithLocation ids={failedIds} title={trls[GroupAutoUpdateSummary.trls.failed]} />
                            <div className="vacancy-auto-update-sub-info">
                                <Text importance={TextImportance.Tertiary}>
                                    {trls[GroupAutoUpdateSummary.trls.failedReason]}
                                </Text>
                            </div>
                        </>
                    )}
                </Column>
            </ModalContent>
            <ModalFooter>
                <Button onClick={handleCloseUnchecked} data-qa="group_auto_update_summary_close">
                    {trls[GroupAutoUpdateSummary.trls.close]}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

GroupAutoUpdateSummary.propTypes = {
    isVisible: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    dispatchCheckVacancy: PropTypes.func,
    updatedIds: PropTypes.arrayOf(PropTypes.number),
    failedIds: PropTypes.arrayOf(PropTypes.number),
    trls: PropTypes.object,
    afterSuccessAction: PropTypes.func,
};

GroupAutoUpdateSummary.trls = {
    succeeded: 'vacancy.autoUpdate.group.planned',
    failed: 'vacancy.autoUpdate.group.failed',
    failedReason: 'vacancy.autoUpdate.group.failedReason',
    title: 'vacancy.autoUpdate.title',
    close: 'close',
};

export default translation(GroupAutoUpdateSummary);
