import { memo } from 'react';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import startOfDay from 'date-fns/startOfDay';
import PropTypes from 'prop-types';

import CustomSelect, { CustomSelectOption, CustomSelectLayer } from 'bloko/blocks/customSelect';
import { CalendarScaleSmall, IconColor } from 'bloko/blocks/icon';

import formatDate from 'Modules/formatDate';

const renderPlaceholder = (value) => (
    <>
        <span className="vacancy-auto-update-selectors__icon">
            <CalendarScaleSmall initial={IconColor.Gray60} />
        </span>
        <span suppressHydrationWarning>{formatDate(value, 'd\u00a0MMMM, EEEE')}</span>
    </>
);

const DateSelect = ({ start, end, date, setDate, isDisabled, title }) => (
    <CustomSelect
        value={startOfDay(date).getTime()}
        onChange={(value) => setDate(new Date(value))}
        layer={CustomSelectLayer.AboveOverlayContent}
        placeholderTemplate={renderPlaceholder}
        title={title}
    >
        {eachDayOfInterval({ start, end }).map((nextDate) => (
            <CustomSelectOption key={nextDate.getTime()} value={nextDate.getTime()} disabled={isDisabled(nextDate)}>
                {formatDate(nextDate, 'd\u00a0MMMM, EEEE')}
            </CustomSelectOption>
        ))}
    </CustomSelect>
);

DateSelect.propTypes = {
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
    date: PropTypes.object,
    setDate: PropTypes.func.isRequired,
    isDisabled: PropTypes.func.isRequired,
    title: PropTypes.string,
};

export default memo(DateSelect);
