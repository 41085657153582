import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import addDays from 'date-fns/addDays';
import isSameDay from 'date-fns/isSameDay';

import { useNotification } from 'lux/components/Notifications/Provider';
import { CombinedSteps } from 'lux/components/VacancyModals/AutoUpdateModal/combineSteps';

import autoUpdateCount from 'lux/components/VacancyModals/AutoUpdateModalExp/autoUpdateCount';

interface Fields {
    scheduleType: string;
    setScheduleType: (type: string) => void;
    startDate: Date;
    setStartDate: (date: Date) => void;
    endDate: Date;
    setEndDate: (date: Date) => void;
    time: number;
    setTime: (time: number) => void;
    setUpdatesCount: (count: number) => void;
    updatesCount: number;
    updateCountByScheduleType: { [key: string]: { count: number } };
    setUpdateCountByScheduleType: (count: { [key: string]: { count: number } }) => void;
    handleChangeScheduleType: ({ target: { value } }: ChangeEvent<HTMLInputElement>) => void;
    isTimeDisabled: (time: number) => boolean;
    scheduleTypes: string[];
    timezoneOffsetHours: number;
    firstPossibleDate: Date;
    lastPossibleDate: Date;
}

function makeDefaultEndDate(startDate: Date) {
    return addDays(startDate, 30);
}

export default ({
    ids,
    scheduleTypesWithUpdatesCount,
    closestPossibleProlongation,
    maxLastUpdateDateTime,
}: Omit<CombinedSteps, 'warnings'>): Fields => {
    const dispatch = useDispatch();

    const { addNotification } = useNotification();

    const scheduleTypes = useMemo(
        () => Object.keys(scheduleTypesWithUpdatesCount) as string[],
        [scheduleTypesWithUpdatesCount]
    );
    const firstPossibleDate = useMemo(() => new Date(closestPossibleProlongation), [closestPossibleProlongation]);
    const lastPossibleDate = useMemo(() => new Date(maxLastUpdateDateTime), [maxLastUpdateDateTime]);
    const timezoneOffsetHours = useMemo(() => -new Date().getTimezoneOffset() / 60, []);

    const [startDate, setStartDate] = useState(firstPossibleDate);
    const [endDate, setEndDate] = useState(makeDefaultEndDate(firstPossibleDate));
    const [time, setTime] = useState(firstPossibleDate.getHours() + 1);

    const [scheduleType, setScheduleType] = useState(scheduleTypes[0]);

    const [updatesCount, setUpdatesCount] = useState(0);
    const [updateCountByScheduleType, setUpdateCountByScheduleType] = useState(scheduleTypesWithUpdatesCount);
    const handleChangeScheduleType = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => setScheduleType(value);

    const isTimeDisabled = useCallback(
        (hour: number) => {
            return isSameDay(firstPossibleDate, startDate) ? hour <= firstPossibleDate.getHours() : false;
        },
        [firstPossibleDate, startDate]
    );

    useEffect(() => {
        dispatch(autoUpdateCount({ scheduleTypes, startDate, endDate, amount: ids.length }, addNotification))
            .then((updateCountByScheduleType) => {
                setUpdateCountByScheduleType(updateCountByScheduleType);
            })
            .catch(console.error);
    }, [scheduleTypes, startDate, endDate, ids.length, dispatch, addNotification]);

    useEffect(() => {
        const publicationsCount = updateCountByScheduleType[scheduleType];

        if (publicationsCount) {
            setUpdatesCount(publicationsCount.count);
        }
    }, [updateCountByScheduleType, scheduleType]);

    useEffect(() => {
        setStartDate(firstPossibleDate);
        setEndDate(makeDefaultEndDate(firstPossibleDate));
        setTime(firstPossibleDate.getHours() + 1);
    }, [firstPossibleDate]);

    return {
        scheduleTypes,
        scheduleType,
        setScheduleType,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        time,
        setTime,
        setUpdatesCount,
        updatesCount,
        updateCountByScheduleType,
        setUpdateCountByScheduleType,
        handleChangeScheduleType,
        isTimeDisabled,
        timezoneOffsetHours,
        firstPossibleDate,
        lastPossibleDate,
    };
};
