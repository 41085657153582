import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    title: 'vacancy.contacts.change.modal.title',
    titleMany: 'vacancy.contacts.change.modal.title.many',
};

const TitleComponent: TranslatedComponent<{ vacanciesCount?: number }> = ({ trls, vacanciesCount }) => {
    if (!vacanciesCount) {
        return <>{trls[TrlKeys.titleMany]}</>;
    }

    return (
        <ConversionNumber
            hasValue={false}
            value={vacanciesCount}
            one={trls[TrlKeys.title]}
            some={trls[TrlKeys.titleMany]}
            many={trls[TrlKeys.titleMany]}
        />
    );
};

export default translation(TitleComponent);
