import { FC, Fragment } from 'react';

import { FormSpacer } from 'bloko/blocks/form';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';

import { VacancyToProlongate } from 'lux/models/employerVacanciesRise';

import ProlongateAndBuyButton from 'lux/components/VacancyModals/Prolongation/ProlongateAndBuyButton';
import ProlongateButton from 'lux/components/VacancyModals/Prolongation/ProlongateButton';
import PurchaseButton from 'lux/components/VacancyModals/Prolongation/PurchaseButton';
import useSubmitButtons, { SubmitButtonType } from 'lux/components/VacancyModals/Prolongation/useSubmitButtons';

interface SubmitButtonsProps {
    afterSuccessAction: ({
        handleCloseModal,
        queryParams,
        vacancyIds,
    }: {
        handleCloseModal: () => void;
        queryParams: Record<string, number[]>;
        vacancyIds: number[];
    }) => void;
    handleCloseModal: () => void;
    loading: boolean;
    startLoading: () => void;
    finishLoading: () => void;
    vacanciesInfoToProlongate: VacancyToProlongate[];
}

const SubmitButtons: FC<SubmitButtonsProps> = ({
    afterSuccessAction,
    handleCloseModal,
    loading,
    startLoading,
    finishLoading,
    vacanciesInfoToProlongate,
}) => {
    const submitButtonType = useSubmitButtons();

    const isXs = useBreakpoint() === Breakpoint.XS;

    const Wrapper = isXs ? Fragment : FormSpacer;

    if (submitButtonType === SubmitButtonType.ProlongateAndBuy) {
        return (
            <Wrapper>
                <ProlongateAndBuyButton
                    handleCloseModal={handleCloseModal}
                    loading={loading}
                    startLoading={startLoading}
                    finishLoading={finishLoading}
                    vacanciesInfoToProlongate={vacanciesInfoToProlongate}
                />
            </Wrapper>
        );
    }

    if (submitButtonType === SubmitButtonType.Purchase) {
        return (
            <Wrapper>
                <PurchaseButton
                    handleCloseModal={handleCloseModal}
                    loading={loading}
                    startLoading={startLoading}
                    finishLoading={finishLoading}
                    vacanciesInfoToProlongate={vacanciesInfoToProlongate}
                />
            </Wrapper>
        );
    }

    if (submitButtonType === SubmitButtonType.Prolongate) {
        return (
            <Wrapper>
                <ProlongateButton
                    afterSuccessAction={afterSuccessAction}
                    handleCloseModal={handleCloseModal}
                    loading={loading}
                    startLoading={startLoading}
                    finishLoading={finishLoading}
                />
            </Wrapper>
        );
    }

    return null;
};

export default SubmitButtons;
