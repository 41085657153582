import { useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Action, Button, ButtonMode, ButtonStyle, Card, Tag, Text, VSpacing } from '@hh.ru/magritte-ui';
import {
    AstrostarOutlinedSize16,
    ExclamationTriangleOutlinedSize16,
    EyeCrossedOutlinedSize16,
    InfoCircleOutlinedSize16,
} from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { CurrencyType } from 'lux/models/currencies.types';
import { VacancyAdviceMarketSalary } from 'lux/models/employerVacancies/vacancy.types';
import {
    VacancyAdvice,
    VacancyAdviceSeverity,
    VacancyAdviceWithStatistics,
} from 'lux/models/vacancy/vacancyAdvices.types';
import fetcher from 'lux/modules/fetcher';

import VacancyStatisticTag from 'lux/components/VacancyModals/VacancyAdviceModal/VacancyStatisticTag';
import {
    HIDE_ADVICE_URL,
    VacancyAdviceGroup,
    VacancyAdviceToGroup,
} from 'lux/components/VacancyModals/VacancyAdviceModal/constants';
import useAnalyticsEventShownOnce from 'lux/components/VacancyModals/VacancyAdviceModal/useAnalyticsEventShownOnce';
import { formatSalary, getLinkUrl } from 'lux/components/VacancyModals/VacancyAdviceModal/utils';

import styles from './vacancy-advice-card.less';

interface VacancyAdviceCardProps {
    adviceItem: VacancyAdvice;
    // tempexp_31119_next_line
    adviceWithStatisticsItem?: VacancyAdviceWithStatistics;
    marketSalary: VacancyAdviceMarketSalary;
    vacancyId: number;
    numInList: number;
    totalInList: number;
    hideAdviceItem: (adviceItem: VacancyAdvice) => void;
}

const TrlKeys = {
    buttonHide: 'employer.myVacancies.advice.modal.hide',
    advice: {
        [VacancyAdvice.WorkAddress]: {
            title: 'employer.myVacancies.advice.list.free.ADD_WORK_ADDRESS.title',
            description: 'employer.myVacancies.advice.list.free.ADD_WORK_ADDRESS.description',
            link: 'employer.myVacancies.advice.list.free.ADD_WORK_ADDRESS.link',
        },
        [VacancyAdvice.ConditionsToDescription]: {
            title: 'employer.myVacancies.advice.list.free.ADD_CONDITIONS_TO_DESCRIPTION.title',
            description: 'employer.myVacancies.advice.list.free.ADD_CONDITIONS_TO_DESCRIPTION.description',
            link: 'employer.myVacancies.advice.list.free.ADD_CONDITIONS_TO_DESCRIPTION.link',
        },
        [VacancyAdvice.RequirementsToDescription]: {
            title: 'employer.myVacancies.advice.list.free.ADD_REQUIREMENTS_TO_DESCRIPTION.title',
            description: 'employer.myVacancies.advice.list.free.ADD_REQUIREMENTS_TO_DESCRIPTION.description',
            link: 'employer.myVacancies.advice.list.free.ADD_REQUIREMENTS_TO_DESCRIPTION.link',
        },
        [VacancyAdvice.ResponsibilitiesToDescription]: {
            title: 'employer.myVacancies.advice.list.free.ADD_RESPONSIBILITIES_TO_DESCRIPTION.title',
            description: 'employer.myVacancies.advice.list.free.ADD_RESPONSIBILITIES_TO_DESCRIPTION.description',
            link: 'employer.myVacancies.advice.list.free.ADD_RESPONSIBILITIES_TO_DESCRIPTION.link',
        },
        [VacancyAdvice.SalaryCloserToMarker]: {
            title: 'employer.myVacancies.advice.list.important.MAKE_SALARY_CLOSER_TO_MARKET.title',
            description: 'employer.myVacancies.advice.list.important.MAKE_SALARY_CLOSER_TO_MARKET.description',
            link: 'employer.myVacancies.advice.list.important.MAKE_SALARY_CLOSER_TO_MARKET.link',
        },
        [VacancyAdvice.UseStandardPlus]: {
            title: 'employer.myVacancies.advice.list.paid.USE_STANDARD_PLUS.title',
            description: 'employer.myVacancies.advice.list.paid.USE_STANDARD_PLUS.description',
            link: 'employer.myVacancies.advice.list.paid.USE_STANDARD_PLUS.link',
        },
        [VacancyAdvice.PublishSalary]: {
            title: 'employer.myVacancies.advice.list.important.PUBLISH_SALARY.title',
            description: 'employer.myVacancies.advice.list.important.PUBLISH_SALARY.description',
            link: 'employer.myVacancies.advice.list.important.PUBLISH_SALARY.link',
        },
        [VacancyAdvice.EmployerContacts]: {
            title: 'employer.myVacancies.advice.list.free.ADD_EMPLOYER_CONTACTS.title',
            description: 'employer.myVacancies.advice.list.free.ADD_EMPLOYER_CONTACTS.description',
            link: 'employer.myVacancies.advice.list.free.ADD_EMPLOYER_CONTACTS.link',
        },
        [VacancyAdvice.UseResumeSearch]: {
            title: 'employer.myVacancies.advice.list.paid.USE_RESUME_SEARCH.title',
            description: 'employer.myVacancies.advice.list.paid.USE_RESUME_SEARCH.description',
            link: 'employer.myVacancies.advice.list.paid.USE_RESUME_SEARCH.link',
        },
        [VacancyAdvice.UseSearchTop]: {
            title: 'employer.myVacancies.advice.list.paid.USE_SEARCH_TOP.title',
            description: 'employer.myVacancies.advice.list.paid.USE_SEARCH_TOP.description',
            link: 'employer.myVacancies.advice.list.paid.USE_SEARCH_TOP.link',
        },
        [VacancyAdvice.DecreaseWorkExperience]: {
            title: 'employer.myVacancies.advice.list.free.DECREASE_WORK_EXPERIENCE.title',
            description: 'employer.myVacancies.advice.list.free.DECREASE_WORK_EXPERIENCE.description',
            link: 'employer.myVacancies.advice.list.free.DECREASE_WORK_EXPERIENCE.link',
        },
        [VacancyAdvice.ChangeVacancyName]: {
            title: 'employer.myVacancies.advice.list.important.CHANGE_VACANCY_NAME.title',
            description: 'employer.myVacancies.advice.list.important.CHANGE_VACANCY_NAME.description',
            link: 'employer.myVacancies.advice.list.important.CHANGE_VACANCY_NAME.link',
        },
    },
    currency: {
        [CurrencyType.AZN]: 'AZN',
        [CurrencyType.BYR]: 'BYR',
        [CurrencyType.EUR]: 'EUR',
        [CurrencyType.KZT]: 'KZT',
        [CurrencyType.RUR]: 'RUR',
        [CurrencyType.UAH]: 'UAH',
        [CurrencyType.USD]: 'USD',
        [CurrencyType.UZS]: 'UZS',
        [CurrencyType.GEL]: 'GEL',
        [CurrencyType.KGS]: 'KGS',
    },
};

interface FormValues {
    vacancyId: number;
    adviceCode: VacancyAdvice;
}

declare global {
    interface FetcherPostApi {
        [HIDE_ADVICE_URL]: {
            queryParams: void;
            body: FormValues;
            response: void;
        };
    }
}

const VacancyAdviceCard: TranslatedComponent<VacancyAdviceCardProps> = ({
    adviceItem,
    // tempexp_31119_next_line
    adviceWithStatisticsItem,
    hideAdviceItem,
    marketSalary,
    vacancyId,
    numInList,
    totalInList,
    trls,
}) => {
    const adviceType: VacancyAdviceGroup = VacancyAdviceToGroup[adviceItem];

    const ADVICE_TYPE_TO_ICON: Record<VacancyAdviceGroup, JSX.Element> = {
        [VacancyAdviceGroup.Important]: (
            <Tag icon={ExclamationTriangleOutlinedSize16} style="negative" size="large" aria-label="" />
        ),
        [VacancyAdviceGroup.Paid]: <Tag icon={AstrostarOutlinedSize16} style="special" size="large" aria-label="" />,
        [VacancyAdviceGroup.Free]: <Tag icon={InfoCircleOutlinedSize16} style="neutral" size="large" aria-label="" />,
    };

    const cardAdviceRef = useAnalyticsEventShownOnce({
        name: 'employer_vacancies_advice_item',
        advice: adviceItem,
        vacancyId,
        numInList,
        totalInList,
        salaryFrom: marketSalary.from,
        salaryTo: marketSalary.to,
        // tempexp_31119_start
        severity: adviceWithStatisticsItem?.severity,
    });

    const onHideAdvice = () => {
        Analytics.sendHHEventButtonClick('employer_vacancies_advice_item_hide', {
            vacancyId,
            advice: adviceItem,
            // tempexp_31119_start
            severity: adviceWithStatisticsItem?.severity,
        });
        fetcher
            .postFormData(HIDE_ADVICE_URL, {
                vacancyId,
                adviceCode: adviceItem,
            })
            .catch(console.error);
        hideAdviceItem(adviceItem);
    };

    const onClickToLink = useCallback(() => {
        Analytics.sendHHEventButtonClick('employer_vacancies_advice_item_link', {
            vacancyId,
            advice: adviceItem,
            numInList,
            totalInList,
            salaryFrom: marketSalary.from,
            salaryTo: marketSalary.to,
            // tempexp_31119_start
            severity: adviceWithStatisticsItem?.severity,
        });
    }, [vacancyId, adviceItem, numInList, totalInList, marketSalary.from, marketSalary.to, adviceWithStatisticsItem]);

    let adviceTitle = trls[TrlKeys.advice[adviceItem].title];
    adviceTitle +=
        adviceItem === VacancyAdvice.SalaryCloserToMarker &&
        marketSalary.from &&
        marketSalary.to &&
        marketSalary.currency
            ? `${formatSalary(marketSalary)} ${trls[TrlKeys.currency[marketSalary.currency]]}`
            : '';

    const buttonLink = getLinkUrl(adviceItem, vacancyId);

    let buttonLinkProps: { mode: ButtonMode; style?: ButtonStyle } =
        adviceType === VacancyAdviceGroup.Free ? { mode: 'secondary' } : { mode: 'primary', style: 'accent' };

    // tempexp_31119_start
    if (adviceWithStatisticsItem) {
        buttonLinkProps =
            adviceWithStatisticsItem?.severity === VacancyAdviceSeverity.Minor
                ? { mode: 'secondary' }
                : { mode: 'primary', style: 'accent' };
    }

    return (
        <div ref={cardAdviceRef} className={styles.vacancyAdviceCard}>
            <Card padding={12} borderRadius={24} showBorder stretched>
                <div className={styles.vacancyAdviceCardContent}>
                    <div className={styles.vacancyAdviceIcons}>
                        {adviceWithStatisticsItem ? (
                            <VacancyStatisticTag
                                adviceItem={adviceItem}
                                adviceWithStatisticsItem={adviceWithStatisticsItem}
                                vacancyId={vacancyId}
                            />
                        ) : (
                            <>{ADVICE_TYPE_TO_ICON[adviceType]}</>
                        )}
                        <span className={styles.vacancyAdviceIconHide}>
                            <Action onClick={onHideAdvice} mode="secondary" icon={EyeCrossedOutlinedSize16} />
                        </span>
                    </div>
                    <div>
                        <Text typography="subtitle-1-semibold" Element="span">
                            {adviceTitle}
                        </Text>
                        <VSpacing default={8} />
                        <Text Element="span" style="secondary" typography="paragraph-3-regular">
                            {trls[TrlKeys.advice[adviceItem].description]}
                        </Text>
                    </div>
                </div>
                <Button
                    onClick={onClickToLink}
                    stretched
                    Element={Link}
                    target="_blank"
                    to={buttonLink}
                    size="small"
                    {...buttonLinkProps}
                >
                    {trls[TrlKeys.advice[adviceItem].link]}
                </Button>
            </Card>
        </div>
    );
    // tempexp_31119_end
};

export default translation(VacancyAdviceCard);
