import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Conversion from 'bloko/blocks/conversion';
import { FormError } from 'bloko/blocks/form';
import { H1 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { formatToReactComponent } from 'bloko/common/trl';

import Strings from 'Utils/Strings';
import PayerAccountSelect from 'lux/components/Employer/PayerAccountSelect';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

const AutoProlongationPurchase = ({
    showError,
    onSelect,
    vacancyPropertiesPackageTrls,
    totalCost,
    totalCount,
    currency,
    agreements,
    trls,
}) => {
    const currencyName = useSelector(({ currencies: { list } }) => list.find(({ code }) => code === currency).name);

    return (
        <>
            {formatToReactComponent(trls[AutoProlongationPurchase.trls.publicationsNeeded], {
                '{0}': (
                    <Conversion
                        one={trls[AutoProlongationPurchase.trls.publicationsOne]}
                        some={trls[AutoProlongationPurchase.trls.publicationsSome]}
                        many={trls[AutoProlongationPurchase.trls.publicationsMany]}
                        value={totalCount}
                        hasValue
                    />
                ),
                '{1}': (
                    <span>
                        {vacancyPropertiesPackageTrls.map((trl, index) => (
                            <Fragment key={index}>
                                «{trl}»{index !== vacancyPropertiesPackageTrls.length - 1 && ', '}
                            </Fragment>
                        ))}
                    </span>
                ),
            })}
            <VSpacing base={2} />
            {trls[AutoProlongationPurchase.trls.accountDescription]}
            <VSpacing base={2} />
            <H1 Element="span" lite>
                {`${Strings.formatCost(totalCost)} ${currencyName}`}
            </H1>
            <VSpacing base={10} />
            <PayerAccountSelect
                accounts={agreements}
                minimumValueInCents={totalCost * 100}
                onChange={onSelect}
                invalid={showError}
            />
            <VSpacing base={2} />
            <FormError show={showError}>{trls[AutoProlongationPurchase.trls.accountError]}</FormError>
        </>
    );
};

AutoProlongationPurchase.propTypes = {
    showError: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    vacancyPropertiesPackageTrls: PropTypes.arrayOf(PropTypes.string),
    totalCount: PropTypes.number,
    totalCost: PropTypes.number,
    currency: PropTypes.string,
    agreements: PropTypes.array,
    trls: PropTypes.object,
};

AutoProlongationPurchase.trls = {
    publicationError: 'employer.vacancies.autoprolongation.popup.publication.error',
    accountDescription: 'employer.vacancies.autoprolongation.popup.account.description',
    accountError: 'employer.vacancies.autoprolongation.popup.account.error',
    publicationsNeeded: 'employer.vacancies.autoprolongation.popup.account.type',
    publicationsOne: 'employer.vacancies.autoprolongation.popup.account.type.one',
    publicationsSome: 'employer.vacancies.autoprolongation.popup.account.type.some',
    publicationsMany: 'employer.vacancies.autoprolongation.popup.account.type.many',
};

export default translation(AutoProlongationPurchase);
