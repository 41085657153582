import { FC } from 'react';

import VSpacing from 'bloko/blocks/vSpacing';

import useShowRisePage from 'lux/components/Rise/useShowRisePage';
import ProlongateInfoSubtitle from 'lux/components/VacancyModals/components/ProlongateInfoSubtitle';

import AvailableToFreeProlongate from 'lux/components/VacancyModals/Prolongation/AvailableToFreeProlongate';
import AvailableToPurchase from 'lux/components/VacancyModals/Prolongation/AvailableToPurchase';
import AvailableToSpend from 'lux/components/VacancyModals/Prolongation/AvailableToSpend';
import FreeProlongationTimer from 'lux/components/VacancyModals/Prolongation/FreeProlongationTimer';
import Unavailable from 'lux/components/VacancyModals/Prolongation/Unavailable';
import Upgrade from 'lux/components/VacancyModals/Prolongation/Upgrade';

interface ContentProps {
    afterSuccessAction: ({
        handleCloseModal,
        queryParams,
        vacancyIds,
    }: {
        handleCloseModal: () => void;
        queryParams: Record<string, number[]>;
        vacancyIds: number[];
    }) => void;
    handleCloseModal: () => void;
    freeRenewalParameters: {
        intervalMinutes: string | null;
        lastActivationTimeIso: string | null;
    };
    loading: boolean;
    startLoading: () => void;
    finishLoading: () => void;
}

const Content: FC<ContentProps> = ({
    afterSuccessAction,
    handleCloseModal,
    freeRenewalParameters,
    loading,
    startLoading,
    finishLoading,
}) => {
    // tempexp_30986_next_line
    const { isRisePageModalExperimentEnabled } = useShowRisePage();

    return (
        <>
            <ProlongateInfoSubtitle />
            <VSpacing base={6} />
            <FreeProlongationTimer freeRenewalParameters={freeRenewalParameters} />
            <AvailableToSpend />
            <AvailableToFreeProlongate />
            <AvailableToPurchase />
            {/* tempexp_30986_next_line */}
            {!isRisePageModalExperimentEnabled && (
                <Upgrade
                    afterSuccessAction={afterSuccessAction}
                    handleCloseModal={handleCloseModal}
                    loading={loading}
                    startLoading={startLoading}
                    finishLoading={finishLoading}
                />
            )}
            <Unavailable />
        </>
    );
};

export default Content;
