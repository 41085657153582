import { FC, memo, useEffect } from 'react';

import CustomSelect, { CustomSelectLayer, CustomSelectOption } from 'bloko/blocks/customSelect';
import { ClockScaleSmallKindDefault, IconColor } from 'bloko/blocks/icon';

const timeIntervals = [...Array(24).keys()];

const formatHour = (hour: number) => `${String(hour).padStart(2, '0')}:00`;
const formatTime = (hour: number) => `${formatHour(hour)}—${formatHour(hour + 1)}`;

const renderPlaceholder = (value: number) => (
    <>
        <span className="vacancy-auto-update-selectors__icon">
            <ClockScaleSmallKindDefault initial={IconColor.Gray60} />
        </span>
        {formatTime(value)}
    </>
);
interface TimeSelectProps {
    time: number;
    setTime: (hour: number) => void;
    isDisabled: (hour: number) => boolean;
    title: string;
}
const TimeSelect: FC<TimeSelectProps> = ({ time, setTime, isDisabled, title }) => {
    useEffect(() => {
        const interval = timeIntervals.find((hour) => time === hour);
        if (interval !== undefined && isDisabled(interval)) {
            setTime(timeIntervals.find((hour) => !isDisabled(hour)) as number);
        }
    }, [isDisabled, setTime, time]);

    return (
        <CustomSelect
            value={time}
            onChange={setTime}
            layer={CustomSelectLayer.AboveOverlayContent}
            placeholderTemplate={renderPlaceholder}
            title={title}
        >
            {timeIntervals.map((hour) => (
                <CustomSelectOption key={hour} value={hour} disabled={isDisabled(hour)}>
                    {formatTime(hour)}
                </CustomSelectOption>
            ))}
        </CustomSelect>
    );
};

export default memo(TimeSelect);
