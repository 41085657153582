import { Divider } from '@hh.ru/magritte-ui';
import { H3 } from 'bloko/blocks/header';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FormatMoney from 'lux/components/FormatMoney';
import translation from 'lux/components/translation';
import { ServiceItemCode } from 'lux/models/price/productCodes';
import { Purchase } from 'lux/models/prolongateInfo';
import { NARROW_NON_BREAKING_SPACE, NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { getPublicationCode } from 'lux/utils/price/products';

import styles from './index.less';

type PublicationCodeAvailableForPurchase =
    | ServiceItemCode.Standart
    | ServiceItemCode.StandartPlus
    | ServiceItemCode.Premium
    | ServiceItemCode.Anonymous
    | ServiceItemCode.ZpPromo
    | ServiceItemCode.ZpBusiness
    | ServiceItemCode.ZpAnonymous;

const TrlKeys = {
    publicationTitle: {
        [ServiceItemCode.Standart]: 'service.entry.VP.short',
        [ServiceItemCode.StandartPlus]: 'service.entry.RENEWAL_VP.short',
        [ServiceItemCode.Premium]: 'service.entry.VPREM.short',
        [ServiceItemCode.Anonymous]: 'service.entry.AP.short',
        [ServiceItemCode.ZpPromo]: 'service.entry.ZP_PROMO.short',
        [ServiceItemCode.ZpBusiness]: 'service.entry.ZP_BUSINESS.short',
        [ServiceItemCode.ZpAnonymous]: 'service.entry.ZP_ANONYMOUS.short',
    },
    title: 'employer.myVacancies.prolongate.availableToPurchase.title',
    total: 'employer.myVacancies.prolongate.availableToPurchase.total',
};

interface ProlongateInfoAvailableToPurchaseProps {
    purchase: Purchase;
}
const ProlongateInfoAvailableToPurchase: TranslatedComponent<ProlongateInfoAvailableToPurchaseProps> = ({
    trls,
    purchase,
}) => {
    return (
        <>
            <div>
                {purchase.products.map((product, index) => {
                    const code = getPublicationCode(product);

                    return (
                        <div className={styles.wrapper} key={index}>
                            <div>
                                <Text>
                                    {trls[TrlKeys.publicationTitle[code as PublicationCodeAvailableForPurchase]]}
                                    {`${NON_BREAKING_SPACE}×${NON_BREAKING_SPACE}${product.count}`}
                                </Text>
                                <Text importance={TextImportance.Tertiary}>
                                    {`${product.regionTrl}, ${product.profRoleGroupsTrls.join(', ')}`}
                                </Text>
                            </div>
                            <div>
                                <FormatMoney
                                    currency={product.currency}
                                    groupSeparator={NARROW_NON_BREAKING_SPACE}
                                    inCents
                                >
                                    {product.price}
                                </FormatMoney>
                            </div>
                        </div>
                    );
                })}
            </div>
            {purchase.products.length > 1 && (
                <>
                    <VSpacing base={4} />
                    <Divider />
                    <VSpacing base={4} />
                    <div className={styles.wrapper}>
                        <Text>{trls[TrlKeys.total]}</Text>
                        <H3>
                            <FormatMoney currency={purchase.currency} inCents>
                                {purchase.totalPrice}
                            </FormatMoney>
                        </H3>
                    </div>
                </>
            )}
            <VSpacing base={6} />
        </>
    );
};

export default translation(ProlongateInfoAvailableToPurchase);
