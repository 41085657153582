import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { Publication } from 'lux/models/prolongateInfo';
import { REGULAR_SPACE } from 'lux/modules/symbols';

import styles from './index.less';

const TrlKeys = {
    anyProfRoleGroup: 'any.profRoleGroup',
    anyRegion: 'any.region',
};

interface ProlongateInfoAvailableToSpendProps {
    publications: Publication[];
}

const ProlongateInfoAvailableToSpend: TranslatedComponent<ProlongateInfoAvailableToSpendProps> = ({
    trls,
    publications,
}) => {
    return (
        <div>
            {publications.map(({ count, profRoleGroupsTrls, regionsTrls, serviceName }, index) => {
                return (
                    <div className={styles.wrapper} key={index}>
                        <div>
                            <Text data-qa="vacancies-updatePopup__type">{serviceName}</Text>
                            <Text importance={TextImportance.Tertiary}>
                                {regionsTrls.length > 0 ? regionsTrls.join(',') : trls[TrlKeys.anyRegion]},
                                {REGULAR_SPACE}
                                {profRoleGroupsTrls.length > 0
                                    ? profRoleGroupsTrls.join(',')
                                    : trls[TrlKeys.anyProfRoleGroup]}
                            </Text>
                        </div>
                        <Text>{count}</Text>
                    </div>
                );
            })}
        </div>
    );
};

export default translation(ProlongateInfoAvailableToSpend);
