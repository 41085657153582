import { FC } from 'react';

import VSpacing from 'bloko/blocks/vSpacing';

import ProlongateInfoSubtitle from 'lux/components/VacancyModals/components/ProlongateInfoSubtitle';

import AvailableToPurchase from 'lux/components/VacancyModals/AutoUpdateModalExp/ProlongationSchedule/AvailableToPurchase';
import AvailableToSpend from 'lux/components/VacancyModals/AutoUpdateModalExp/ProlongationSchedule/AvailableToSpend';
import Unavailable from 'lux/components/VacancyModals/AutoUpdateModalExp/ProlongationSchedule/Unavailable';

const Content: FC = () => {
    return (
        <>
            <ProlongateInfoSubtitle />
            <VSpacing base={6} />
            <AvailableToSpend />
            <AvailableToPurchase />
            <Unavailable />
        </>
    );
};

export default Content;
