import NotificationHeading from 'bloko/blocks/notificationManager/NotificationHeading';
import { NotificationKind } from 'bloko/blocks/notificationManager/constants';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    title: 'hiringPlan.notification.title',
    text: 'hiringPlan.notification.text',
};

const NotificattionContent: TranslatedComponent = ({ trls }) => (
    <>
        <NotificationHeading>{trls[TrlKeys.title]}</NotificationHeading>
        {trls[TrlKeys.text]}
    </>
);

export default {
    Element: translation(NotificattionContent),
    kind: NotificationKind.Ok,
    autoClose: true,
};
