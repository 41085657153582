import { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import formatISO from 'date-fns/formatISO';
import isSameDay from 'date-fns/isSameDay';
import set from 'date-fns/set';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { useBreakpoint } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { FormItem } from 'bloko/blocks/form';
import Gap from 'bloko/blocks/gap';
import BlokoLink from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Modal, { ModalHeader, ModalTitle, ModalFooter } from 'bloko/blocks/modal';
import Radio from 'bloko/blocks/radio';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { formatToReactComponent } from 'bloko/common/trl';

import ExperimentCheckOnElementShown from 'lux/components/ExperimentCheckOnElementShown';
import { useNotification } from 'lux/components/Notifications/Provider';
import { ADD_MODAL, MODAL_AUTO_UPDATE_SUMMARY } from 'lux/components/VacancyModals/constants';
import translation from 'lux/components/translation';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';

import CountText from 'lux/components/VacancyModals/AutoUpdateModal/CountText';
import DateSelect from 'lux/components/VacancyModals/AutoUpdateModal/DateSelect';
import { RenewalObjectType } from 'lux/components/VacancyModals/AutoUpdateModal/RenewalObjectType';
import TimeSelect from 'lux/components/VacancyModals/AutoUpdateModal/TimeSelect';
import VacanciesWithLocation from 'lux/components/VacancyModals/AutoUpdateModal/VacanciesWithLocation';
import Warnings from 'lux/components/VacancyModals/AutoUpdateModal/Warnings';
import { addUpdateSchedule } from 'lux/components/VacancyModals/AutoUpdateModal/autoUpdateSchedule';
import combineSteps from 'lux/components/VacancyModals/AutoUpdateModal/combineSteps';

import styles from './index.less';

const APPLICANT_ACTIVITY_REPORT_URL =
    'https://public.tableau.com/profile/alkadarskiy.selim#!/vizhome/232020_15863625148330/232020';
const ANALYTICS_STEPS_COUNT = 1;

const getAutoUpdateCount = (scheduleTypes, startDate, endDate, amount) =>
    fetcher.get('/shards/employer/vacancies/auto_update/count', {
        params: {
            amount,
            scheduleType: scheduleTypes,
            start: formatISO(startDate),
            end: formatISO(endDate),
        },
    });

const AutoUpdateModal = ({
    vacancyId,
    groupId,
    autoRenewal,
    renewalSteps = [],
    handleCloseModal,
    dispatchModal,
    failedToUpdate,
    isEdit,
    isVisible,
    trls,
    afterSuccessAction,
}) => {
    const updatedIds = useRef([]);
    const modalRef = useRef();
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const { isXS } = useBreakpoint();
    const isGroup = renewalSteps.length > 0;
    const steps = isGroup ? renewalSteps : [autoRenewal];
    const { ids, scheduleTypesWithUpdatesCount, closestPossibleProlongation, maxLastUpdateDateTime, warnings } =
        useMemo(() => {
            return combineSteps(steps);
        }, [steps]);

    const scheduleTypes = useMemo(() => Object.keys(scheduleTypesWithUpdatesCount), [scheduleTypesWithUpdatesCount]);
    const firstPossibleDate = useMemo(() => new Date(closestPossibleProlongation), [closestPossibleProlongation]);
    const lastPossibleDate = useMemo(() => new Date(maxLastUpdateDateTime), [maxLastUpdateDateTime]);
    const timezoneOffsetHours = useMemo(() => -new Date().getTimezoneOffset() / 60, []);
    const employerId = useSelector((state) => state.employerId);

    const [startDate, setStartDate] = useState(firstPossibleDate);
    const [endDate, setEndDate] = useState(lastPossibleDate);

    const [scheduleType, setScheduleType] = useState(scheduleTypes[0]);
    const handleChangeScheduleType = useCallback(({ target: { value } }) => setScheduleType(value), []);

    const [time, setTime] = useState(firstPossibleDate.getHours() + 1);
    const isTimeDisabled = useCallback(
        (hour) => isSameDay(firstPossibleDate, startDate) && hour <= firstPossibleDate.getHours(),
        [firstPossibleDate, startDate]
    );
    const handleCloseWithAnalytics = useCallback(() => {
        if (isGroup) {
            Analytics.sendHHEventButtonClick('cancel_auto_update_step', {
                labelGroupId: groupId,
                labelCurrentStep: ANALYTICS_STEPS_COUNT,
            });
        }

        handleCloseModal();
    }, [groupId, handleCloseModal, isGroup]);

    useEffect(() => {
        setStartDate(firstPossibleDate);
        setEndDate(lastPossibleDate);
        setTime(firstPossibleDate.getHours() + 1);
    }, [firstPossibleDate, lastPossibleDate]);

    const [loading, setLoading] = useState(false);
    const handleSubmit = useCallback(async () => {
        const start = formatISO(set(startDate, { hours: time, minutes: 0, seconds: 0, milliseconds: 0 }));
        const end = formatISO(set(endDate, { hours: time, minutes: 0, seconds: 0, milliseconds: 0 }));

        Analytics.sendHHEventButtonClick('modal_auto_update_button', {
            vacanciesIds: ids,
            scheduleType,
            startDate: start,
            endDate: end,
            isMultiSelection: !!isGroup,
            isEdit,
        });

        setLoading(true);
        try {
            await dispatch(
                addUpdateSchedule(
                    { vacancyId: isGroup ? ids : vacancyId, scheduleType, start, end },
                    isEdit,
                    isGroup,
                    addNotification
                )
            );
        } catch (error) {
            console.error(error);
            return;
        } finally {
            setLoading(false);
        }

        if (isGroup) {
            Analytics.sendHHEventButtonClick('submit_auto_update_step', {
                labelGroupId: groupId,
                labelSubmittedIds: ids.join(),
                labelCurrentStep: ANALYTICS_STEPS_COUNT,
            });
            updatedIds.current.push(...ids);
        }

        Analytics.sendHHEvent('submit_auto_update', {
            vacancyId: ids.join(),
            labelGroupId: groupId,
            employerId,
            timezoneOffsetHours,
            isMultiSelection: !!isGroup,
        });

        if (isGroup) {
            dispatchModal({
                type: ADD_MODAL,
                payload: {
                    modalType: MODAL_AUTO_UPDATE_SUMMARY,
                    data: {
                        failedToUpdate,
                        updatedIds: updatedIds.current,
                        afterSuccessAction,
                    },
                },
            });

            return;
        }

        if (afterSuccessAction) {
            afterSuccessAction();

            return;
        }

        handleCloseModal();
    }, [
        startDate,
        time,
        endDate,
        ids,
        scheduleType,
        isGroup,
        isEdit,
        groupId,
        employerId,
        timezoneOffsetHours,
        afterSuccessAction,
        handleCloseModal,
        dispatch,
        vacancyId,
        addNotification,
        dispatchModal,
        failedToUpdate,
    ]);

    const sendReportClickAnalytics = () => {
        Analytics.sendHHEventButtonClick('applicant_activity_report_link', {
            timezoneOffsetHours,
        });
    };

    const [updateCountByScheduleType, setUpdateCountByScheduleType] = useState(scheduleTypesWithUpdatesCount);
    useEffect(() => {
        getAutoUpdateCount(scheduleTypes, startDate, endDate, ids.length)
            .then(({ updateCountByScheduleType }) => {
                setUpdateCountByScheduleType(updateCountByScheduleType);
            })
            .catch(console.error);
    }, [vacancyId, scheduleTypes, startDate, endDate, ids.length]);

    const [updatesCount, setUpdatesCount] = useState(0);
    useEffect(() => {
        const publicationsCount = updateCountByScheduleType[scheduleType];

        if (publicationsCount) {
            setUpdatesCount(publicationsCount.count);
        }
    }, [updateCountByScheduleType, scheduleType]);

    useEffect(() => {
        if (isVisible) {
            if (isGroup) {
                Analytics.sendHHEventElementShown(modalRef.current, {
                    name: 'group_auto_update',
                    labelGroupId: groupId,
                    labelVacanciesToUpdate: renewalSteps.map(({ ids }) => ids).join(),
                    labelFailedToUpdate: failedToUpdate.join(),
                    labelStepsCount: ANALYTICS_STEPS_COUNT,
                });

                return;
            }

            Analytics.sendHHEventElementShown(modalRef.current, {
                name: 'auto_update',
                vacancyId,
                employerId,
            });
        }
    }, [isGroup, employerId, vacancyId, groupId, renewalSteps, failedToUpdate, isVisible]);

    return (
        <Modal visible={isVisible} onClose={handleCloseWithAnalytics}>
            <ExperimentCheckOnElementShown experimentName="exp_30287_auto_update_purchase">
                <ModalHeader>
                    <ModalTitle>
                        {isEdit ? trls[AutoUpdateModal.trls.titleEdit] : trls[AutoUpdateModal.trls.titlePlan]}
                    </ModalTitle>
                    <div className="vacancy-auto-update-title-addition">
                        <Text importance={TextImportance.Tertiary}>{trls[AutoUpdateModal.trls.timezone]}</Text>
                    </div>
                </ModalHeader>
                <div className="vacancy-auto-update-popup" ref={modalRef}>
                    <div className="vacancy-auto-update-section">
                        <div className="vacancy-auto-update-interval">
                            {isGroup && (
                                <Gap bottom>
                                    <VacanciesWithLocation
                                        covered
                                        ids={ids}
                                        title={trls[AutoUpdateModal.trls.vacanciesGroup]}
                                    />
                                </Gap>
                            )}
                            <Text strong>{trls[AutoUpdateModal.trls.firstDate]}</Text>
                            <div className="vacancy-auto-update-selectors">
                                <div
                                    className="vacancy-auto-update-selectors__date"
                                    data-qa="vacancy-auto-update-date-interval-start"
                                >
                                    <DateSelect
                                        start={firstPossibleDate}
                                        end={lastPossibleDate}
                                        date={startDate}
                                        setDate={setStartDate}
                                        isDisabled={(date) => date >= endDate}
                                        title={trls[AutoUpdateModal.trls.firstDate]}
                                    />
                                </div>
                                <div
                                    className="vacancy-auto-update-selectors__time"
                                    data-qa="vacancy-auto-update-time-interval-start"
                                >
                                    <TimeSelect
                                        time={time}
                                        setTime={setTime}
                                        isDisabled={isTimeDisabled}
                                        title={trls[AutoUpdateModal.trls.firstTime]}
                                    />
                                </div>
                            </div>
                            <Text importance={TextImportance.Tertiary}>{trls[AutoUpdateModal.trls.popularTime]}</Text>
                            <VSpacing base={2} />
                            <Text importance={TextImportance.Tertiary}>
                                {formatToReactComponent(trls[AutoUpdateModal.trls.applicantActivityHint], {
                                    '{0}': (
                                        <BlokoLink
                                            disableVisited
                                            Element={Link}
                                            to={APPLICANT_ACTIVITY_REPORT_URL}
                                            target="_blank"
                                            onClick={sendReportClickAnalytics}
                                        >
                                            {trls[AutoUpdateModal.trls.applicantActivityLink]}
                                        </BlokoLink>
                                    ),
                                })}
                            </Text>
                        </div>
                        <div className="vacancy-auto-update-interval">
                            <Text strong>{trls[AutoUpdateModal.trls.lastDate]}</Text>
                            <div className="vacancy-auto-update-selectors">
                                <div
                                    className="vacancy-auto-update-selectors__date"
                                    data-qa="vacancy-auto-update-date-interval-end"
                                >
                                    <DateSelect
                                        start={firstPossibleDate}
                                        end={lastPossibleDate}
                                        date={endDate}
                                        setDate={setEndDate}
                                        isDisabled={(date) => date <= startDate}
                                        title={trls[AutoUpdateModal.trls.lastDate]}
                                    />
                                </div>
                            </div>
                            <Text importance={TextImportance.Tertiary}>{trls[AutoUpdateModal.trls.sameTime]}</Text>
                        </div>
                    </div>
                    <Text strong>{trls[AutoUpdateModal.trls.scheduleType]}</Text>
                    <div className="vacancy-auto-update-frequency">
                        {scheduleTypes.map((option) => (
                            <FormItem baseline={true} key={option} data-qa={`vacancy-auto-update-option-${option}`}>
                                <Radio
                                    name="scheduleType"
                                    checked={scheduleType === option}
                                    value={option}
                                    onChange={handleChangeScheduleType}
                                >
                                    {trls[AutoUpdateModal.trls[option]]}
                                </Radio>
                            </FormItem>
                        ))}
                    </div>
                </div>
                {warnings.length ? (
                    <div className="vacancy-auto-update-footer">
                        <VSpacing base={5} />
                        {warnings.map(({ vacancyProperties, prolongationFailureReason }) => {
                            return (
                                <Warnings
                                    key={vacancyProperties.converted.shortcut.typeTrl}
                                    typeTrl={vacancyProperties.converted.shortcut.typeTrl}
                                    prolongationFailureReason={prolongationFailureReason}
                                    afterSuccessAction={afterSuccessAction}
                                />
                            );
                        })}
                    </div>
                ) : null}
                <ModalFooter>
                    {isXS ? (
                        <>
                            <CountText count={updatesCount} />
                            <VSpacing base={5} />
                        </>
                    ) : null}
                    <div className={styles.footer}>
                        <Button onClick={handleCloseWithAnalytics} data-qa="vacancy-auto-update-cancel">
                            {trls[AutoUpdateModal.trls.cancel]}
                        </Button>

                        <div className={styles.right}>
                            {isXS ? null : <CountText count={updatesCount} />}

                            <Button
                                data-qa="vacancy-auto-update-submit"
                                disabled={loading}
                                onClick={handleSubmit}
                                loading={loading && <Loading scale={LoadingScale.Small} />}
                                kind={ButtonKind.Primary}
                            >
                                {trls[AutoUpdateModal.trls.submit]}
                            </Button>
                        </div>
                    </div>
                </ModalFooter>
            </ExperimentCheckOnElementShown>
        </Modal>
    );
};

AutoUpdateModal.propTypes = {
    isEdit: PropTypes.bool,
    isVisible: PropTypes.bool,
    groupId: PropTypes.string,
    handleCloseModal: PropTypes.func,
    dispatchModal: PropTypes.func,
    failedToUpdate: PropTypes.arrayOf(PropTypes.number),
    firstDate: PropTypes.number,
    vacancyId: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    autoRenewal: RenewalObjectType,
    renewalSteps: PropTypes.arrayOf(RenewalObjectType),
    trls: PropTypes.object,
    afterSuccessAction: PropTypes.func,
};

AutoUpdateModal.trls = {
    firstDate: 'vacancy.autoUpdate.firstDate',
    firstTime: 'vacancy.autoUpdate.firstTime',
    lastDate: 'vacancy.autoUpdate.lastDate',
    popularTime: 'vacancy.autoUpdate.popularTime',
    sameTime: 'vacancy.autoUpdate.sameTime',
    scheduleType: 'vacancy.autoUpdate.frequency',
    applicantActivityHint: 'vacancy.autoUpdate.applicantActivity.hint',
    applicantActivityLink: 'vacancy.autoUpdate.applicantActivity.link',

    EVERY_DAY: 'vacancy.autoUpdate.everyDay',
    EVERY_WORKING_DAY: 'vacancy.autoUpdate.everyWorkDay',
    EVERY_WEEK: 'vacancy.autoUpdate.oncePerWeek',
    EVERY_TWO_WEEKS: 'vacancy.autoUpdate.oncePerTwoWeeks',
    EVERY_SIXTH_DAY: 'vacancy.autoUpdate.sixthDay',
    EVERY_FOURTH_DAY: 'vacancy.autoUpdate.fourthDay',

    titlePlan: 'vacancy.autoUpdate.title',
    titleEdit: 'vacancy.autoUpdate.title.edit',
    timezone: 'vacancy.autoUpdate.timezone',
    step: 'vacancy.autoUpdate.step',
    cancel: 'employer.myVacancyes.move.cancel',
    submit: 'vacancy.autoUpdate.schedule',

    vacanciesGroup: 'vacancy.autoUpdate.group.for',
};

export default translation(AutoUpdateModal);
