import { ReactNode, useCallback } from 'react';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { IconColor } from 'bloko/blocks/icon';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import { ModalContent, ModalFooter, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { PublicationProductTypeWithTrls } from 'lux/models/prolongateInfo';

import Content from 'lux/components/VacancyModals/AutoUpdateModalExp/ProlongationSchedule/Content';
import useSubmitButtons, {
    SubmitButtonType,
} from 'lux/components/VacancyModals/AutoUpdateModalExp/ProlongationSchedule/useSubmitButtons';

import styles from './index.less';

const TrlKeys = {
    back: 'Back',
    autoUpdateAndBuy: 'vacancy.autoUpdate.detail.buyPlan.autoUpdateAndBuy',
    autoUpdate: 'vacancy.autoUpdate.schedule',
};
interface ProlongationScheduleProps {
    title: ReactNode;
    onBack: () => void;
    onSubmit: (scheduleAndBuyParams?: { products: PublicationProductTypeWithTrls[] }) => void;
    loading: boolean;
}
const ProlongationSchedule: TranslatedComponent<ProlongationScheduleProps> = ({
    trls,
    onSubmit,
    onBack,
    loading,
    title,
}) => {
    const isXs = useBreakpoint() === Breakpoint.XS;
    const submitButtonType = useSubmitButtons();
    const products = useSelectorNonNullable(
        ({ autoUpdateInfo }) => autoUpdateInfo.availableToPurchase.purchase?.products
    );

    const isScheduleAndBuy = submitButtonType === SubmitButtonType.ScheduleAndBuy;

    const handleOnClick = useCallback(() => {
        onSubmit(isScheduleAndBuy && products ? { products } : undefined);
    }, [isScheduleAndBuy, onSubmit, products]);
    return (
        <>
            <ModalHeader>
                <ModalTitle>{title}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <div className={styles.content}>
                    <Content />
                </div>
            </ModalContent>
            <ModalFooter>
                <div className={styles.footer}>
                    <Button onClick={onBack} stretched={isXs}>
                        {trls[TrlKeys.back]}
                    </Button>

                    <Button
                        stretched={isXs}
                        disabled={loading}
                        kind={ButtonKind.Primary}
                        loading={loading && <Loading scale={LoadingScale.Small} initial={IconColor.White} />}
                        onClick={handleOnClick}
                    >
                        {isScheduleAndBuy ? trls[TrlKeys.autoUpdateAndBuy] : trls[TrlKeys.autoUpdate]}
                    </Button>
                </div>
            </ModalFooter>
        </>
    );
};

export default translation(ProlongationSchedule);
