import { DefaultRootState } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { updateVacanciesGroups, VacancyGroup } from 'lux/models/employerVacancies/managerVacanciesGroups';
import fetcher from 'lux/modules/fetcher';

const VACANCIES_GROUPS_URL = '/shards/employer/vacancies_dashboard/groups';

declare global {
    interface FetcherGetApi {
        [VACANCIES_GROUPS_URL]: {
            queryParams: void;
            response: { managerVacanciesGroups: VacancyGroup[] };
        };
    }
}
export default (currentGroupId: string): ThunkAction<Promise<unknown>, DefaultRootState, unknown, AnyAction> =>
    async (dispatch) => {
        let groupsResponse = null;
        try {
            groupsResponse = await fetcher.get(VACANCIES_GROUPS_URL);
        } catch (e) {
            console.error(e);
        }

        if (groupsResponse) {
            dispatch(updateVacanciesGroups({ groups: groupsResponse.managerVacanciesGroups, currentGroupId }));
        }
    };
