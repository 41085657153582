import { TextArea, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

import PresetLabels from 'lux/components/VacancyModals/CancelUnpremoderatedVacancyModal/PresetChips';

interface ModalContentProps {
    value: string;
    onChange: (value: string) => void;
}

const TrlKeys = {
    placeholder: 'employer.unpremoderated.cancel.modal.textarea.placeholder',
};

const ModalContent: TranslatedComponent<ModalContentProps> = ({ value, onChange, trls }) => {
    return (
        <>
            <TextArea
                rows={5}
                layout="fill-horizontal"
                value={value}
                placeholder={trls[TrlKeys.placeholder]}
                onChange={({ target }) => {
                    onChange(target.value);
                }}
            />
            <VSpacing default={16} xs={10} s={10} />
            <PresetLabels onSelect={onChange} />
        </>
    );
};

export default translation(ModalContent);
