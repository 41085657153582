import { createContext } from 'react';

import { DEFAULT_FILTERS_VALUES, VacanciesFilters } from 'lux/models/employerVacancies/vacancy.types';

interface FilterContextType {
    filters: VacanciesFilters;
    dispatchFilter: ({ type }: { type: string; payload?: VacanciesFilters }) => void;
}

const DUMMY_FUNCTION = () => {
    // do nothing
};

const FilterContext = createContext<FilterContextType>({
    filters: DEFAULT_FILTERS_VALUES,
    dispatchFilter: DUMMY_FUNCTION,
});

export default FilterContext;
