import { useState, useEffect } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { AxiosError } from 'axios';

import { Text, VSpacing, Card } from '@hh.ru/magritte-ui';
import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'lux/components/Form';
import translation from 'lux/components/translation';
import fetcher from 'lux/modules/fetcher';

import CardComponent from 'lux/components/VacancyContactsChangeModal/CardComponent';
import ChangeContactsForm, {
    ChangeContactFormValues,
} from 'lux/components/VacancyContactsChangeModal/ChangeContactsForm';
import { CardValue } from 'lux/components/VacancyContactsChangeModal/utils';

import styles from './vacancy-contacts-change-modal.less';

const TrlKeys = {
    newContactsHint: 'vacancy.contacts.change.modal.new.contacts.hint',
    newContactsHintMany: 'vacancy.contacts.change.modal.new.contacts.hint.many',
    hideHint: 'vacancy.contacts.change.modal.hide.hint',
    hideHintMany: 'vacancy.contacts.change.modal.hide.hint.many',
    dontChangeHint: 'vacancy.contacts.change.modal.dont.change.hint',
    dontChangeHintMany: 'vacancy.contacts.change.modal.dont.change.hint.many',

    cantLoadManagerData: 'vacancy.contacts.change.modal.error.cantLoadManagerData',
    moveError: 'employer.myVacancyes.move.error',
};

const GET_MANAGER_CONTACTS = '/shards/employer/get_manager_contacts';
declare global {
    interface FetcherGetApi {
        [GET_MANAGER_CONTACTS]: {
            queryParams: {
                managerId: string;
            };
            response: ChangeContactFormValues;
        };
    }
}

interface ErrorResponse {
    email?: {
        errors: string[];
    };
    fio?: {
        errors: string[];
    };
    phones?: {
        phone: {
            '0'?: {
                fullPhoneNumber: {
                    errors: string[];
                };
            };
            '1'?: {
                fullPhoneNumber: {
                    errors: string[];
                };
            };
        };
    };
    phoneOrEmailRule?: {
        errors: string[];
    };
}

interface VacancyContactsChangeModalProps {
    vacanciesCount?: number;
    managerId: string;
    onSubmit: (formValues: ChangeContactFormValues, updateContactsAction: CardValue) => Promise<void>;
    setError: (error: string) => void;
    type: 'delete' | 'move';
}

const updateContactsActionCardsMove = [CardValue.Update, CardValue.Hide, CardValue.DontChange];
const updateContactsActionCardsDelete = [CardValue.Update, CardValue.Hide];

const handleValidate = (values: Partial<ChangeContactFormValues>) => {
    let errors = {};
    if (!values.fio) {
        errors = { fio: 'empty' };
    }
    return errors;
};

const errorConstructor = (error: ErrorResponse = {}) => {
    const emailError = error.email?.errors[0];
    const fioError = error.fio?.errors[0];
    const phoneError = error.phones?.phone?.['0']?.fullPhoneNumber.errors[0];
    const additionalPhoneError = error.phones?.phone?.['1']?.fullPhoneNumber.errors[0];

    if (error.phoneOrEmailRule?.errors.includes('phoneOrEmailRule')) {
        return {
            email: 'phoneOrEmailRule',
        };
    }

    return {
        ...(emailError && { email: emailError }),
        ...(fioError && { fio: fioError }),
        ...(phoneError && { phone: phoneError }),
        ...(additionalPhoneError && { additionalPhone: additionalPhoneError }),
    };
};

const VacancyContactsChangeModal: TranslatedComponent<VacancyContactsChangeModalProps> = ({
    trls,
    vacanciesCount,
    managerId,
    onSubmit,
    setError,
    type,
}) => {
    const [checkedCardValue, setCheckedCardValue] = useState(CardValue.Update);
    const [initialValues, setInitialValues] = useState<ChangeContactFormValues>({
        fio: '',
        email: '',
        phone: '',
        comment: '',
        additionalPhone: '',
        additionalComment: '',
    });

    const updateContactsActionCards =
        type === 'delete' ? updateContactsActionCardsDelete : updateContactsActionCardsMove;

    useEffect(() => {
        const getFormData = async () => {
            try {
                const response = await fetcher.get(GET_MANAGER_CONTACTS, {
                    params: { managerId },
                });
                setInitialValues(response);
            } catch (err) {
                setError(trls[TrlKeys.cantLoadManagerData]);
            }
        };

        void getFormData();
    }, [managerId, setError, trls]);

    const submitForm = async (formValues: ChangeContactFormValues) => {
        const trimmedFormValues = { ...formValues };
        Object.keys(formValues).forEach(
            (formValue) => (trimmedFormValues[formValue] = trimmedFormValues[formValue].trim())
        );
        try {
            await onSubmit(trimmedFormValues, checkedCardValue);
            return {};
        } catch (error) {
            const axiosError = error as AxiosError<{ errors: ErrorResponse }>;
            if (!axiosError?.response?.data?.errors) {
                setError(trls[TrlKeys.moveError]);
            }

            return errorConstructor(axiosError?.response?.data?.errors);
        }
    };

    return (
        <FinalForm
            initialValues={initialValues}
            validate={handleValidate}
            onSubmit={submitForm}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} method="post" id="change-contacts-form">
                    <div className={styles.controlsContainer}>
                        {updateContactsActionCards.map((value) => {
                            return (
                                <CardComponent
                                    key={value}
                                    value={value}
                                    setValue={setCheckedCardValue}
                                    checkedCardValue={checkedCardValue}
                                />
                            );
                        })}
                    </div>
                    <VSpacing default={24} />
                    {checkedCardValue === CardValue.Update && (
                        <>
                            <Text typography="label-2-regular" style="secondary">
                                {!vacanciesCount ? (
                                    trls[TrlKeys.newContactsHintMany]
                                ) : (
                                    <ConversionNumber
                                        hasValue={false}
                                        value={vacanciesCount}
                                        one={trls[TrlKeys.newContactsHint]}
                                        some={trls[TrlKeys.newContactsHintMany]}
                                        many={trls[TrlKeys.newContactsHintMany]}
                                    />
                                )}
                            </Text>
                            <VSpacing default={12} />
                            <ChangeContactsForm initialValues={initialValues} />
                        </>
                    )}
                    {checkedCardValue === CardValue.Hide && (
                        <Card padding={16} borderRadius={16} style="neutral">
                            <Text typography="paragraph-2-regular">
                                {!vacanciesCount ? (
                                    trls[TrlKeys.hideHintMany]
                                ) : (
                                    <ConversionNumber
                                        hasValue={false}
                                        value={vacanciesCount}
                                        one={trls[TrlKeys.hideHint]}
                                        some={trls[TrlKeys.hideHintMany]}
                                        many={trls[TrlKeys.hideHintMany]}
                                    />
                                )}
                            </Text>
                        </Card>
                    )}
                    {checkedCardValue === CardValue.DontChange && (
                        <Card padding={16} borderRadius={16} style="warning">
                            <Text typography="paragraph-2-regular">
                                {!vacanciesCount ? (
                                    trls[TrlKeys.dontChangeHintMany]
                                ) : (
                                    <ConversionNumber
                                        hasValue={false}
                                        value={vacanciesCount}
                                        one={trls[TrlKeys.dontChangeHint]}
                                        some={trls[TrlKeys.dontChangeHintMany]}
                                        many={trls[TrlKeys.dontChangeHintMany]}
                                    />
                                )}
                            </Text>
                        </Card>
                    )}
                </Form>
            )}
        />
    );
};

export default translation(VacancyContactsChangeModal);
