import formatISO from 'date-fns/formatISO';
import set from 'date-fns/set';

import { DateIsoString } from 'bloko/blocks/calendar/datesHelper';

export const prepareStarDateEndDateToSubmit = ({
    startDate,
    endDate,
    time,
}: {
    startDate: Date;
    endDate: Date;
    time: number;
}): { start: DateIsoString; end: DateIsoString } => {
    const start = formatISO(set(startDate, { hours: time, minutes: 0, seconds: 0, milliseconds: 0 }));
    const end = formatISO(set(endDate, { hours: time, minutes: 0, seconds: 0, milliseconds: 0 }));
    return { start, end };
};
