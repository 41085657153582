import { updateManagerVacancies, upgradeManagerVacancy } from 'lux/models/employerVacancies/managerVacancies';
import fetcher from 'lux/modules/fetcher';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

import {
    autoUpdatesEdited,
    autoUpdatesRemoved,
    autoUpdatesScheduled,
} from 'lux/components/VacancyModals/AutoUpdateModal/AutoUpdatesEditNotification';

export const addUpdateSchedule =
    (params, isEdit, isGroup = false, addNotification) =>
    async (dispatch) => {
        const { vacancyId, ...otherParams } = params;

        try {
            await fetcher.post(
                '/shards/employer/vacancies/auto_update/schedule',
                { vacancyId },
                { params: otherParams }
            );
        } catch (err) {
            defaultRequestErrorHandler(err, addNotification);
        }

        let vacancies;

        try {
            vacancies = await fetcher.get('/shards/employer/vacancies/get_full_vacancy', {
                params: {
                    vacancyId: params.vacancyId,
                    isMultiple: true,
                },
            });
        } catch (err) {
            defaultRequestErrorHandler(err, addNotification);
            return;
        }

        dispatch(updateManagerVacancies(vacancies));

        if (isGroup) {
            return;
        }

        addNotification(isEdit ? autoUpdatesEdited : autoUpdatesScheduled);
    };

export const removeSchedule = (params, vacancyName, addNotification) => async (dispatch) => {
    let vacancy;

    try {
        await fetcher.delete('/shards/employer/vacancies/auto_update/schedule', { params });
        vacancy = (await fetcher.get('/shards/employer/vacancies/get_full_vacancy', { params })).vacancy;
    } catch (err) {
        defaultRequestErrorHandler(err, addNotification);

        return;
    }

    dispatch(upgradeManagerVacancy({ vacancy }));
    addNotification(autoUpdatesRemoved, { props: { vacancyId: params.vacancyId, vacancyName } });
};
