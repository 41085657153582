import ControlsTrigger, { BasicTrigger } from 'lux/components/ControlsTrigger';
import { useClickAnalytics } from 'lux/hooks/useMyVacanciesAnalytics';

const VacancyTrigger = (props: BasicTrigger): JSX.Element | null => {
    const { triggerType, triggerName, onTrigger, additionalAnalyticsParams } = props;

    const triggerTarget = ['statistics', 'edit', 'pfp-topup', 'print'].includes(triggerName) ? '_blank' : undefined;

    const clickAnalytics = useClickAnalytics();

    const dataQa = {
        element: `vacancies-dashboard-${triggerName}-trigger_${triggerType}`,
        buttonName: `employer_vacancies_action_${triggerType}`,
    };

    const onClick = () => {
        clickAnalytics(
            `employer_vacancies_action_${triggerType}`,
            {
                label: triggerName,
                ...additionalAnalyticsParams,
            },
            !!additionalAnalyticsParams
        );
        if (onTrigger) {
            onTrigger();
        }
    };

    return (
        <ControlsTrigger
            {...props}
            triggerTarget={triggerTarget}
            onClick={onClick}
            dataQa={dataQa}
            classNameForQuery="HH-Employer-Vacancies-Analytics"
            magritteRedesign
        />
    );
};

export default VacancyTrigger;
