import { useEffect, useRef, MutableRefObject } from 'react';

import Analytics from '@hh.ru/analytics-js';

interface AnalyticParams extends Required<{ name: string }> {
    [key: string]: unknown;
}

/**
 * Хук для отправки shown-события аналитики один раз при монтировании
 * @param params - параметры аналитики для отправки
 * @returns ref - элемент
 */
const useAnalyticsEventShownOnce = (params: AnalyticParams): MutableRefObject<HTMLDivElement | null> => {
    const elementRef = useRef<HTMLDivElement | null>(null);
    const mounted = useRef(false);

    useEffect(() => {
        if (!mounted.current && elementRef.current) {
            Analytics.sendHHEventElementShown(elementRef.current, params);
            mounted.current = true;
        }
    }, [params]);

    return elementRef;
};

export default useAnalyticsEventShownOnce;
