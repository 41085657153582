import { AddNotification } from 'lux/components/Notifications/Provider/types';
import MetallicVacancyType from 'lux/models/vacancy/metallicVacancyType.types';
import fetcher from 'lux/modules/fetcher';

import VacancyProlongateErrorNotification from 'lux/components/VacancyModals/Prolongation/VacancyProlongateErrorNotification';

const PROLONGATE_UPGRADE_VACANCY_URL = '/shards/employer/vacancies/prolongate/upgrade';

declare global {
    interface FetcherPostApi {
        [PROLONGATE_UPGRADE_VACANCY_URL]: {
            queryParams: {
                targetMetallic: MetallicVacancyType;
                vacancyId: number;
            };
            body: null;
            response: Record<string, number[]>;
        };
    }
}

const prolongateUpgradeVacancy = async ({
    addNotification,
    targetMetallic,
    vacancyId,
}: {
    addNotification: AddNotification;
    targetMetallic: MetallicVacancyType;
    vacancyId: number;
}): Promise<Record<string, number[]>> => {
    let response;
    try {
        response = await fetcher.post(PROLONGATE_UPGRADE_VACANCY_URL, null, { params: { targetMetallic, vacancyId } });
    } catch (error) {
        addNotification(VacancyProlongateErrorNotification, { props: { isMultiple: false } });
        throw error;
    }

    return response.data;
};

export default prolongateUpgradeVacancy;
