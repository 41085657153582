import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FreeRenewalTip, { getNextFreeRenewalInterval } from 'lux/components/VacancyActions/FreeRenewalTip';
import translation from 'lux/components/translation';

import styles from './free-prolongation-timer.less';

interface FreeProlongationTimerProps {
    freeRenewalParameters: {
        intervalMinutes: string | null;
        lastActivationTimeIso: string | null;
    };
}

const FreeProlongationTimer: TranslatedComponent<FreeProlongationTimerProps> = ({ freeRenewalParameters }) => {
    const vacancyIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds
    );

    if (
        !freeRenewalParameters.lastActivationTimeIso ||
        !freeRenewalParameters.intervalMinutes ||
        // Доступно бесплатное поднятие
        vacancyIds.length > 0
    ) {
        return null;
    }

    const nextFreeProlongationInterval = getNextFreeRenewalInterval(
        new Date(freeRenewalParameters.lastActivationTimeIso),
        Number(freeRenewalParameters.intervalMinutes)
    );

    if (nextFreeProlongationInterval <= 0) {
        return null;
    }

    return (
        <>
            <Text size={TextSize.Small}>
                <div className={styles.timerText}>
                    <FreeRenewalTip
                        freeRenewalInterval={freeRenewalParameters.intervalMinutes}
                        lastFreeRenewal={freeRenewalParameters.lastActivationTimeIso}
                    />
                </div>
            </Text>
            <VSpacing base={6} />
        </>
    );
};

export default translation(FreeProlongationTimer);
