import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ProlongateInfoAvailableToPurchase from 'lux/components/VacancyModals/components/ProlongateInfoAvailableToPurchase';
import translation from 'lux/components/translation';

const TrlKeys = {
    title: 'employer.myVacancies.prolongate.availableToPurchase.title',
};

const AvailableToPurchase: TranslatedComponent = ({ trls }) => {
    const purchase = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToPurchase.purchase);

    if (!purchase) {
        return null;
    }

    return (
        <>
            <H3>{trls[TrlKeys.title]}</H3>
            <VSpacing base={4} />
            <ProlongateInfoAvailableToPurchase purchase={purchase} />
            <VSpacing base={6} />
        </>
    );
};

export default translation(AvailableToPurchase);
