import { RenewalStep } from 'lux/components/VacancyModals/AutoUpdateModal/combineSteps';

interface RenewalInfo {
    unscheduled: Record<string, RenewalStep>;
    scheduled: Record<string, RenewalStep>;
    forbidden: {
        vacancies: Record<string, { reason: string }>;
    };
    singleVacancyProlongationFailureReason: null;
}
// проверяет все ли вакансии в пачке архивные/заблокированные для поднятий регулярно
export default (renewalInfo: RenewalInfo): boolean => {
    const renewalSteps = Object.values(renewalInfo.unscheduled).concat(Object.values(renewalInfo.scheduled));
    if (renewalSteps.length === 0) {
        const forbiddenVacanciesIds = Object.keys(renewalInfo.forbidden.vacancies).map((id) => Number(id));
        return forbiddenVacanciesIds.every(
            (id) => renewalInfo.forbidden.vacancies[id].reason === 'SECURITY_CHECK_FAILED'
        );
    }
    return false;
};
