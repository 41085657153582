import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import autoUpdateScheduleFormSubmit from '@hh.ru/analytics-js-events/build/xhh/employer/vacancies/auto_update_schedule_form_submit';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import ConversionNumber from 'bloko/blocks/conversion';
import { FormItem } from 'bloko/blocks/form';
import BlokoLink from 'bloko/blocks/link';
import Modal, { ModalFooter, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import Radio from 'bloko/blocks/radio';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import ExperimentCheckOnElementShown from 'lux/components/ExperimentCheckOnElementShown';
import { useNotification } from 'lux/components/Notifications/Provider';
import CountText from 'lux/components/VacancyModals/AutoUpdateModal/CountText';
import combineSteps, { RenewalStep } from 'lux/components/VacancyModals/AutoUpdateModal/combineSteps';
import { ADD_MODAL, MODAL_AUTO_UPDATE_SUMMARY } from 'lux/components/VacancyModals/constants';
import translation from 'lux/components/translation';
import useOnOffState from 'lux/hooks/useOnOffState';
import { PublicationProductTypeWithTrls } from 'lux/models/prolongateInfo';
import { useSelector } from 'lux/modules/useSelector';

import DateSelect from 'lux/components/VacancyModals/AutoUpdateModalExp/DateSelect';
import ProlongationSchedule from 'lux/components/VacancyModals/AutoUpdateModalExp/ProlongationSchedule';
import TimeSelect from 'lux/components/VacancyModals/AutoUpdateModalExp/TimeSelect';
import autoUpdateInfo from 'lux/components/VacancyModals/AutoUpdateModalExp/autoUpdateInfo';
import schedule from 'lux/components/VacancyModals/AutoUpdateModalExp/schedule';
import scheduleAndBuy from 'lux/components/VacancyModals/AutoUpdateModalExp/scheduleAndBuy';
import useFields from 'lux/components/VacancyModals/AutoUpdateModalExp/useFields';
import { prepareStarDateEndDateToSubmit } from 'lux/components/VacancyModals/AutoUpdateModalExp/utils';

import styles from './index.less';

interface AutoUpdateModalExpProps {
    isEdit: boolean;
    isVisible: boolean;
    groupId: string;
    handleCloseModal: () => void;
    dispatchModal: (payload: {
        type: string;
        payload: {
            modalType: string;
            data: {
                failedToUpdate: number[];
                updatedIds: number[];
                afterSuccessAction: () => void;
            };
        };
    }) => void;
    failedToUpdate: number[];
    firstDate: number;
    vacancyId: number | number[];
    autoRenewal: RenewalStep;
    renewalSteps: RenewalStep[];

    afterSuccessAction: () => void;
}

const TrlKeys = {
    title: 'vacancy.autoUpdate.buyPlan.title',
    detailTitle: 'vacancy.autoUpdate.detail.buyPlan.title',

    continue: 'vacancy.autoUpdate.buyPlan.continue',

    vacancyOne: 'vacancy.autoUpdate.buyPlan.vacancyOne',
    vacancySome: 'vacancy.autoUpdate.buyPlan.vacancySome',
    vacancyMany: 'vacancy.autoUpdate.buyPlan.vacancyMany',
    timezone: 'vacancy.autoUpdate.timezone',
    firstDate: 'vacancy.autoUpdate.firstDate',
    firstTime: 'vacancy.autoUpdate.firstTime',
    lastDate: 'vacancy.autoUpdate.lastDate',
    popularTime: 'vacancy.autoUpdate.popularTime',
    sameTime: 'vacancy.autoUpdate.sameTime',
    scheduleType: 'vacancy.autoUpdate.frequency',

    applicantActivityHint: 'vacancy.autoUpdate.applicantActivity.hint',
    applicantActivityLink: 'vacancy.autoUpdate.applicantActivity.link',

    EVERY_DAY: 'vacancy.autoUpdate.everyDay',
    EVERY_WORKING_DAY: 'vacancy.autoUpdate.everyWorkDay',
    EVERY_WEEK: 'vacancy.autoUpdate.oncePerWeek',
    EVERY_TWO_WEEKS: 'vacancy.autoUpdate.oncePerTwoWeeks',
    EVERY_SIXTH_DAY: 'vacancy.autoUpdate.sixthDay',
    EVERY_FOURTH_DAY: 'vacancy.autoUpdate.fourthDay',
};
const ANALYTICS_STEPS_COUNT = 1;
const APPLICANT_ACTIVITY_REPORT_URL =
    'https://public.tableau.com/profile/alkadarskiy.selim#!/vizhome/232020_15863625148330/232020';

const AutoUpdateModalExp: TranslatedComponent<AutoUpdateModalExpProps> = ({
    groupId,
    vacancyId,
    handleCloseModal,
    trls,
    isEdit,
    isVisible,
    renewalSteps = [],
    autoRenewal,
    failedToUpdate,
    afterSuccessAction,
    dispatchModal,
}) => {
    const dispatch = useDispatch();
    const employerId = useSelector((state) => state.employerId);
    const { addNotification } = useNotification();
    const modalRef = useRef<HTMLDivElement>(null);
    const isGroup = renewalSteps.length > 0;
    const steps = isGroup ? renewalSteps : [autoRenewal];
    const { ids, scheduleTypesWithUpdatesCount, closestPossibleProlongation, maxLastUpdateDateTime } = useMemo(() => {
        return combineSteps(steps);
    }, [steps]);
    const {
        scheduleTypes,
        scheduleType,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        time,
        setTime,
        updatesCount,
        timezoneOffsetHours,
        firstPossibleDate,
        lastPossibleDate,
        handleChangeScheduleType,
        isTimeDisabled,
    } = useFields({ ids, scheduleTypesWithUpdatesCount, closestPossibleProlongation, maxLastUpdateDateTime });

    const [infoModal, showInfoModal, hideInfoModal] = useOnOffState(false);
    const [loading, loadingOn, loadingOff] = useOnOffState(false);

    useEffect(() => {
        if (isVisible && modalRef.current) {
            if (isGroup) {
                Analytics.sendHHEventElementShown(modalRef.current, {
                    name: 'group_auto_update',
                    labelGroupId: groupId,
                    labelVacanciesToUpdate: renewalSteps.map(({ ids }) => ids).join(),
                    labelFailedToUpdate: failedToUpdate.join(),
                    labelStepsCount: ANALYTICS_STEPS_COUNT,
                });

                return;
            }

            Analytics.sendHHEventElementShown(modalRef.current, {
                name: 'auto_update',
                vacancyId,
                employerId,
            });
        }
    }, [isGroup, employerId, vacancyId, groupId, renewalSteps, failedToUpdate, isVisible]);

    const handleCloseWithAnalytics = useCallback(() => {
        if (isGroup) {
            Analytics.sendHHEventButtonClick('cancel_auto_update_step', {
                labelGroupId: groupId,
                labelCurrentStep: ANALYTICS_STEPS_COUNT,
            });
        }

        handleCloseModal();
    }, [groupId, handleCloseModal, isGroup]);

    const sendReportClickAnalytics = () => {
        Analytics.sendHHEventButtonClick('applicant_activity_report_link', {
            timezoneOffsetHours,
        });
    };

    const handleContinue = useCallback(async () => {
        loadingOn();
        const { start, end } = prepareStarDateEndDateToSubmit({ startDate, endDate, time });
        Analytics.sendHHEventButtonClick('auto_update_schedule_continue', {
            vacancyIds: ids,
            withdrawal: updatesCount,
        });
        try {
            await dispatch(autoUpdateInfo({ ids, start, end, scheduleType }, addNotification));
            showInfoModal();
            loadingOff();
        } catch (e) {
            handleCloseModal();
            loadingOff();
        }
    }, [
        updatesCount,
        loadingOn,
        dispatch,
        ids,
        startDate,
        endDate,
        time,
        scheduleType,
        addNotification,
        showInfoModal,
        loadingOff,
        handleCloseModal,
    ]);

    // scheduleAndBuyParams - будет undefined когда жмут «запланировать»,
    // scheduleAndBuyParams не undefined когда жмут «запланировать и оплатить»,
    const handleSubmit = useCallback(
        async (scheduleAndBuyParams?: { products: PublicationProductTypeWithTrls[] }) => {
            const { start, end } = prepareStarDateEndDateToSubmit({ startDate, endDate, time });
            Analytics.sendHHEventButtonClick('modal_auto_update_button', {
                vacanciesIds: ids,
                scheduleType,
                startDate: start,
                endDate: end,
                isMultiSelection: isGroup,
                isEdit,
            });
            loadingOn();
            let vacancyIdParam = isGroup ? ids : vacancyId;
            if (!Array.isArray(vacancyIdParam)) {
                vacancyIdParam = [vacancyIdParam];
            }
            try {
                if (scheduleAndBuyParams) {
                    await dispatch(
                        scheduleAndBuy(
                            {
                                products: scheduleAndBuyParams.products,
                                purchaseParams: {
                                    source: 'autoUpdatePopup',
                                },
                                vacancyId: vacancyIdParam,
                                scheduleType,
                                start,
                                end,
                            },
                            addNotification
                        )
                    );
                } else {
                    await dispatch(
                        schedule(
                            { vacancyId: vacancyIdParam, scheduleType, start, end },
                            isEdit,
                            isGroup,
                            addNotification
                        )
                    );
                }
            } catch (error) {
                console.error(error);
                handleCloseModal();
                return;
            }
            autoUpdateScheduleFormSubmit({
                submitType: scheduleAndBuyParams ? 'SCHEDULE_AND_BUY' : 'SCHEDULE',
                vacancyIds: vacancyIdParam.join(),
                purchase: scheduleAndBuyParams ? JSON.stringify(scheduleAndBuyParams.products) : null,
                employerId,
                scheduleType,
                start,
                end,
            });
            Analytics.sendHHEvent('submit_auto_update', {
                vacancyId: ids.join(),
                labelGroupId: groupId,
                employerId,
                timezoneOffsetHours,
                isMultiSelection: isGroup,
            });

            if (isGroup) {
                Analytics.sendHHEventButtonClick('submit_auto_update_step', {
                    labelGroupId: groupId,
                    labelSubmittedIds: ids.join(),
                    labelCurrentStep: ANALYTICS_STEPS_COUNT,
                });
                if (!scheduleAndBuyParams) {
                    dispatchModal({
                        type: ADD_MODAL,
                        payload: {
                            modalType: MODAL_AUTO_UPDATE_SUMMARY,
                            data: {
                                failedToUpdate,
                                updatedIds: ids,
                                afterSuccessAction,
                            },
                        },
                    });
                    return;
                }
            }

            if (!scheduleAndBuyParams) {
                if (afterSuccessAction) {
                    afterSuccessAction();
                } else {
                    handleCloseModal();
                }
            }
        },
        [
            startDate,
            endDate,
            time,
            ids,
            scheduleType,
            isGroup,
            isEdit,
            loadingOn,
            groupId,
            employerId,
            timezoneOffsetHours,
            vacancyId,
            dispatch,
            addNotification,
            handleCloseModal,
            dispatchModal,
            failedToUpdate,
            afterSuccessAction,
        ]
    );

    return (
        <Modal visible={isVisible} onClose={handleCloseWithAnalytics}>
            {infoModal ? (
                <ProlongationSchedule
                    title={formatToReactComponent(trls[TrlKeys.detailTitle], {
                        '{0}': (
                            <ConversionNumber
                                value={ids.length}
                                one={trls[TrlKeys.vacancySome]}
                                some={trls[TrlKeys.vacancyMany]}
                                many={trls[TrlKeys.vacancyMany]}
                            />
                        ),
                    })}
                    loading={loading}
                    onBack={hideInfoModal}
                    onSubmit={handleSubmit}
                />
            ) : (
                <ExperimentCheckOnElementShown experimentName="exp_30287_auto_update_purchase">
                    <ModalHeader>
                        <ModalTitle>
                            {formatToReactComponent(trls[TrlKeys.title], {
                                '{0}': (
                                    <ConversionNumber
                                        value={ids.length}
                                        one={trls[TrlKeys.vacancyOne]}
                                        some={trls[TrlKeys.vacancySome]}
                                        many={trls[TrlKeys.vacancyMany]}
                                    />
                                ),
                            })}
                        </ModalTitle>
                        <div className="vacancy-auto-update-title-addition">
                            <Text importance={TextImportance.Tertiary}>{trls[TrlKeys.timezone]}</Text>
                        </div>
                    </ModalHeader>
                    <div className="vacancy-auto-update-popup" ref={modalRef}>
                        <div className="vacancy-auto-update-section">
                            <div className="vacancy-auto-update-interval">
                                <Text strong>{trls[TrlKeys.firstDate]}</Text>
                                <div className="vacancy-auto-update-selectors">
                                    <div
                                        className="vacancy-auto-update-selectors__date"
                                        data-qa="vacancy-auto-update-date-interval-start"
                                    >
                                        <DateSelect
                                            start={firstPossibleDate}
                                            end={lastPossibleDate}
                                            date={startDate}
                                            setDate={setStartDate}
                                            isDisabled={(date) => date >= endDate}
                                            title={trls[TrlKeys.firstDate]}
                                        />
                                    </div>
                                    <div
                                        className="vacancy-auto-update-selectors__time"
                                        data-qa="vacancy-auto-update-time-interval-start"
                                    >
                                        <TimeSelect
                                            time={time}
                                            setTime={setTime}
                                            isDisabled={isTimeDisabled}
                                            title={trls[TrlKeys.firstTime]}
                                        />
                                    </div>
                                </div>
                                <Text importance={TextImportance.Tertiary}>{trls[TrlKeys.popularTime]}</Text>
                                <VSpacing base={2} />
                                <Text importance={TextImportance.Tertiary}>
                                    {formatToReactComponent(trls[TrlKeys.applicantActivityHint], {
                                        '{0}': (
                                            <BlokoLink
                                                disableVisited
                                                Element={Link}
                                                to={APPLICANT_ACTIVITY_REPORT_URL}
                                                target="_blank"
                                                onClick={sendReportClickAnalytics}
                                            >
                                                {trls[TrlKeys.applicantActivityLink]}
                                            </BlokoLink>
                                        ),
                                    })}
                                </Text>
                            </div>
                            <div className="vacancy-auto-update-interval">
                                <Text strong>{trls[TrlKeys.lastDate]}</Text>
                                <div className="vacancy-auto-update-selectors">
                                    <div
                                        className="vacancy-auto-update-selectors__date"
                                        data-qa="vacancy-auto-update-date-interval-end"
                                    >
                                        <DateSelect
                                            start={firstPossibleDate}
                                            end={lastPossibleDate}
                                            date={endDate}
                                            setDate={setEndDate}
                                            isDisabled={(date) => date <= startDate}
                                            title={trls[TrlKeys.lastDate]}
                                        />
                                    </div>
                                </div>
                                <Text importance={TextImportance.Tertiary}>{trls[TrlKeys.sameTime]}</Text>
                            </div>
                        </div>
                        <Text strong>{trls[TrlKeys.scheduleType]}</Text>
                        <div className="vacancy-auto-update-frequency">
                            {scheduleTypes.map((option) => (
                                <FormItem baseline={true} key={option} data-qa={`vacancy-auto-update-option-${option}`}>
                                    <Radio
                                        name="scheduleType"
                                        checked={scheduleType === option}
                                        value={`${option}`}
                                        onChange={handleChangeScheduleType}
                                    >
                                        {trls[TrlKeys[option as keyof typeof TrlKeys]]}
                                    </Radio>
                                </FormItem>
                            ))}
                        </div>
                    </div>

                    <ModalFooter>
                        <div className={styles.footer}>
                            <CountText count={updatesCount} />

                            <Button
                                disabled={loading}
                                data-qa="vacancy-auto-update-submit"
                                onClick={handleContinue}
                                kind={ButtonKind.Primary}
                            >
                                {trls[TrlKeys.continue]}
                            </Button>
                        </div>
                    </ModalFooter>
                </ExperimentCheckOnElementShown>
            )}
        </Modal>
    );
};

export default translation(AutoUpdateModalExp);
