import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { ArrowUpOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import axios from 'HHC/Axios';
import { useNotification } from 'lux/components/Notifications/Provider';
import useShowRisePage, {
    RISE_PAGE_DIRECT_EXPERIMENT,
    RISE_PAGE_MODAL_EXPERIMENT,
} from 'lux/components/Rise/useShowRisePage';
import {
    ADD_MODAL,
    MODAL_AUTO_UPDATE,
    MODAL_AUTO_UPDATE_EXP,
    MODAL_AUTO_UPDATE_SUMMARY,
} from 'lux/components/VacancyModals/constants';
import translation from 'lux/components/translation';
import useExperiment from 'lux/hooks/useExperiment';
import { useExperimentGroupCheckFunction } from 'lux/hooks/useExperimentCheckFunction';
import { useSelector } from 'lux/modules/useSelector';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

import VacancyAutoUpdateArchivedOrBlockedErrorNotification from 'lux/components/VacancyActions/VacancyAutoUpdateArchivedOrBlockedErrorNotification';
import getMenuButtonIconProps from 'lux/components/VacancyActions/getMenuButtonIconProps';
import isAllVacanciesArchivedOrClosed from 'lux/components/VacancyActions/isAllVacanciesArchivedOrClosed';
import { UPDATE_CREATE_PERMISSION } from 'lux/components/VacancyActions/permissions';
import { openBlankRisePage } from 'lux/components/VacancyActions/risePageExperimentUtils';

export const useAutoRenewalModal = (
    vacancyId,
    vacancyName,
    dispatchModal,
    isEdit,
    isLoading,
    setLoading,
    onClose = null,
    isGroup = false,
    afterSuccessAction
) => {
    const { addNotification } = useNotification();
    // tempexp_30287_next_line
    const isAutoUpdatePurchaseExpEnabled = useExperiment('exp_30287_auto_update_purchase', true, false);

    return useCallback(async () => {
        if (isLoading) {
            return;
        }
        setLoading(true);

        let renewalResponse;
        try {
            renewalResponse = await axios.putFormData('/shards/employer/vacancies/auto_update/info', {
                vacancyId,
            });
        } catch (err) {
            defaultRequestErrorHandler(err, addNotification);
            return;
        } finally {
            setLoading(false);
        }

        const { data: renewalInfo, headers } = renewalResponse;

        if (isAllVacanciesArchivedOrClosed(renewalInfo)) {
            addNotification(VacancyAutoUpdateArchivedOrBlockedErrorNotification, {
                props: { isMultiple: Array.isArray(vacancyId) && vacancyId.length > 1 },
            });
            return;
        }

        onClose?.();
        // tempexp_30287_next_line
        const modalType = isAutoUpdatePurchaseExpEnabled ? MODAL_AUTO_UPDATE_EXP : MODAL_AUTO_UPDATE;
        if (isGroup) {
            const groupId = headers['x-request-id'].split(',')[0];
            const renewalSteps = Object.values(renewalInfo.unscheduled).concat(Object.values(renewalInfo.scheduled));
            const failedToUpdate = Object.keys(renewalInfo.forbidden.vacancies).map((id) => Number(id));

            dispatchModal({
                type: ADD_MODAL,
                payload: {
                    modalType: renewalSteps.length === 0 ? MODAL_AUTO_UPDATE_SUMMARY : modalType,
                    data: {
                        vacancyName,
                        renewalSteps,
                        failedToUpdate,
                        groupId,
                        afterSuccessAction,
                    },
                },
            });

            return;
        }

        const prolongationFailureReason = renewalInfo.singleVacancyProlongationFailureReason;
        const [singleRenewalInfo] = Object.values(isEdit ? renewalInfo.scheduled : renewalInfo.unscheduled);

        dispatchModal({
            type: ADD_MODAL,
            payload: {
                modalType,
                data: {
                    vacancyId,
                    vacancyName,
                    autoRenewal: { ...singleRenewalInfo, prolongationFailureReason },
                    isEdit,
                    afterSuccessAction,
                },
            },
        });
    }, [
        isLoading,
        setLoading,
        onClose,
        isAutoUpdatePurchaseExpEnabled,
        isGroup,
        isEdit,
        dispatchModal,
        vacancyId,
        vacancyName,
        afterSuccessAction,
        addNotification,
    ]);
};

const AutoUpdateAction = ({
    Component,
    triggerType,
    vacancyId,
    dispatchModal,
    vacancyName,
    onClose,
    vacanciesIds = [],
    additionalAnalyticsParams,
    trls,
    afterSuccessAction,
    icon,
}) => {
    // tempexp_30986_start
    const { isAnyRisePageExperimentEnabled, sendCheck } = useShowRisePage();
    const getExperimentGroup = useExperimentGroupCheckFunction(sendCheck);
    // tempexp_30986_end
    const [isLoading, setLoading] = useState(false);
    const permissions = useSelector((state) => state.permissions);
    const triggerName = 'auto-update';
    const iconProps = getMenuButtonIconProps({
        triggerType,
        triggerName,
        icon: <ArrowUpOutlinedSize24 />,
    });

    const openModal = useAutoRenewalModal(
        vacancyId || vacanciesIds,
        vacancyName,
        dispatchModal,
        false,
        isLoading,
        setLoading,
        onClose,
        !vacancyId,
        afterSuccessAction
    );

    const onTrigger = () => {
        // tempexp_30986_start
        getExperimentGroup(RISE_PAGE_DIRECT_EXPERIMENT);
        getExperimentGroup(RISE_PAGE_MODAL_EXPERIMENT);
        // tempexp_30986_end
        // tempexp_30986_next_line
        if (isAnyRisePageExperimentEnabled) {
            openBlankRisePage(vacanciesIds, true);
        } else {
            openModal();
        }
    };

    return (
        <Component
            triggerName={triggerName}
            triggerType={triggerType}
            onTrigger={onTrigger}
            isLoading={isLoading}
            isPermitted={permissions.includes(UPDATE_CREATE_PERMISSION)}
            batchLength={vacanciesIds.length}
            additionalAnalyticsParams={{ ...additionalAnalyticsParams, action: 'new' }}
            icon={icon}
            {...iconProps}
        >
            {trls[AutoUpdateAction.trls.autoUpdate]}
        </Component>
    );
};

AutoUpdateAction.propTypes = {
    Component: PropTypes.elementType.isRequired,
    triggerType: PropTypes.string,
    vacanciesIds: PropTypes.array,
    vacancyId: PropTypes.number,
    dispatchModal: PropTypes.func,
    vacancyName: PropTypes.string,
    onClose: PropTypes.func,
    additionalAnalyticsParams: PropTypes.object,
    trls: PropTypes.object,
    afterSuccessAction: PropTypes.func,
    icon: PropTypes.element,
};

AutoUpdateAction.trls = {
    autoUpdate: 'vacancy.autoUpdate.trigger',
};

export default translation(AutoUpdateAction);
