import { useMemo } from 'react';

import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import ConversionNumber from 'bloko/blocks/conversion';
import { H3 } from 'bloko/blocks/header';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'lux/components/translation';
import { AutoUpdateUnavailableReason } from 'lux/models/autoUpdateInfo';

import styles from './index.less';

const TrlKeys = {
    reason: {
        [AutoUpdateUnavailableReason.UnableToPurchase]: 'vacancy.autoUpdate.detail.buyPlan.error.UNABLE_TO_PURCHASE',
        [AutoUpdateUnavailableReason.FreeRegularProlongationNotAvailable]:
            'vacancy.autoUpdate.detail.buyPlan.error.FREE_REGULAR_PROLONGATION_NOT_AVAILABLE',
        [AutoUpdateUnavailableReason.StandardPlusRegularProlongationNotAvailable]:
            'vacancy.autoUpdate.detail.buyPlan.error.STANDARD_PLUS_REGULAR_PROLONGATION_NOT_AVAILABLE',
        [AutoUpdateUnavailableReason.UnknownAutoUpdate]: 'vacancy.autoUpdate.detail.buyPlan.error.UNKNOWN',
        [AutoUpdateUnavailableReason.Unknown]: 'vacancy.autoUpdate.detail.buyPlan.error.UNKNOWN',
    },
    singleUnableToPurchaseReason: 'vacancy.autoUpdate.detail.buyPlan.error.single.UNABLE_TO_PURCHASE',
    title: 'vacancy.autoUpdate.detail.buyPlan.unavailable.title',
    vacancy: {
        one: 'vacancy.accusative.one',
        some: 'vacancy.accusative.some',
        many: 'vacancy.accusative.many',
    },
};

const Unavailable: TranslatedComponent = ({ trls }) => {
    const reasons = useSelectorNonNullable(({ autoUpdateInfo }) => autoUpdateInfo.unavailable.reasons);
    const vacancyIds = useSelectorNonNullable(({ autoUpdateInfo }) => autoUpdateInfo.unavailable.vacancyIds);

    const uniqueReasons = useMemo(
        () => [...new Set<AutoUpdateUnavailableReason>(Object.values(reasons || {}))],
        [reasons]
    );

    if (!uniqueReasons.length) {
        return null;
    }

    return (
        <>
            <H3>
                {formatToReactComponent(trls[TrlKeys.title], {
                    '{0}': (
                        <span className={styles.title}>
                            <ConversionNumber
                                value={vacancyIds.length}
                                one={trls[TrlKeys.vacancy.one]}
                                some={trls[TrlKeys.vacancy.some]}
                                many={trls[TrlKeys.vacancy.many]}
                            />
                        </span>
                    ),
                })}
            </H3>
            <VSpacing base={4} />
            <>
                {uniqueReasons.map((reason) => {
                    let text = TrlKeys.reason[AutoUpdateUnavailableReason.Unknown];
                    if (TrlKeys.reason[reason]) {
                        text =
                            vacancyIds.length === 1
                                ? trls[TrlKeys.singleUnableToPurchaseReason]
                                : trls[TrlKeys.reason[reason]];
                    }
                    return (
                        <div className={styles.reasonText} key={reason}>
                            <Text importance={TextImportance.Tertiary}>{text}</Text>
                        </div>
                    );
                })}
            </>
            <VSpacing base={6} />
        </>
    );
};

export default translation(Unavailable);
