import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

const TrlKeys = {
    text: 'vacancy.autoPublication.disableSuccess',
};

const AutoPublicationDisabled: TranslatedComponent = ({ trls }) => <>{trls[TrlKeys.text]}</>;

export default {
    Element: translation(AutoPublicationDisabled),
    kind: 'ok',
    autoClose: true,
};
