import { AddNotification } from 'lux/components/Notifications/Provider/types';
import fetcher from 'lux/modules/fetcher';

import VacancyProlongateErrorNotification from 'lux/components/VacancyModals/Prolongation/VacancyProlongateErrorNotification';

const PROLONGATE_VACANCY_URL = '/shards/employer/vacancies/prolongate';

declare global {
    interface FetcherPostApi {
        [PROLONGATE_VACANCY_URL]: {
            queryParams: void;
            body: {
                freeRenewalVacancyIds?: number[];
                vacancyIds?: number[];
            };
            response: Record<string, number[]>;
        };
    }
}

const prolongateVacancy = async ({
    addNotification,
    freeRenewalVacancyIds,
    vacancyIds,
}: {
    addNotification: AddNotification;
    freeRenewalVacancyIds: number[];
    vacancyIds: number[];
}): Promise<Record<string, number[]>> => {
    let response;
    try {
        response = await fetcher.post(PROLONGATE_VACANCY_URL, { freeRenewalVacancyIds, vacancyIds });
    } catch (error) {
        addNotification(VacancyProlongateErrorNotification, { props: { isMultiple: vacancyIds.length > 1 } });
        throw error;
    }

    return response.data;
};

export default prolongateVacancy;
