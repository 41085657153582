import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { IconColor } from 'bloko/blocks/icon';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';
import { VacancyToProlongate } from 'lux/models/employerVacanciesRise';
import { Purchase } from 'lux/models/prolongateInfo';

import purchaseVacancy from 'lux/components/VacancyModals/Prolongation/purchaseVacancy';

interface PurchaseButtonProps {
    handleCloseModal: () => void;
    loading: boolean;
    startLoading: () => void;
    finishLoading: () => void;
    vacanciesInfoToProlongate: VacancyToProlongate[];
}

const TrlKeys = {
    purchase: 'vacancy.prolongate.submit.purchase',
};

const PurchaseButton: TranslatedComponent<PurchaseButtonProps> = ({
    handleCloseModal,
    loading,
    startLoading,
    finishLoading,
    vacanciesInfoToProlongate,
    trls,
}) => {
    const products = useSelectorNonNullable(
        ({ prolongateInfo }) => (prolongateInfo.availableToPurchase.purchase as Purchase).products
    );
    const vacancyIds = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToPurchase.vacancyIds);

    const { addNotification } = useNotification();

    const isXs = useBreakpoint() === Breakpoint.XS;

    const dispatch = useDispatch();

    const vacanciesToBuy = useMemo(
        () => vacanciesInfoToProlongate.filter(({ vacancyId }) => vacancyIds.includes(vacancyId)),
        [vacanciesInfoToProlongate, vacancyIds]
    );

    const buy = async () => {
        if (loading) {
            return;
        }

        Analytics.sendHHEventButtonClick(
            'modal_prolongation_purchase_button',
            {},
            // Из-за большого кол-ва вакансий используем body, а не query параметры
            {
                availableToPurchaseIds: vacancyIds,
            }
        );

        startLoading();
        try {
            await dispatch(
                purchaseVacancy({
                    addNotification,
                    products,
                    purchaseParams: {
                        source: 'prolongatePopup',
                    },
                    vacanciesToBuy,
                })
            );
        } catch (ignore) {
            console.error(ignore);
            finishLoading();
            handleCloseModal();
        }
    };

    return (
        <Button
            data-qa="purchase-button"
            disabled={loading}
            kind={ButtonKind.Primary}
            loading={loading && <Loading scale={LoadingScale.Small} initial={IconColor.White} />}
            onClick={buy}
            stretched={isXs}
        >
            {trls[TrlKeys.purchase]}
        </Button>
    );
};

export default translation(PurchaseButton);
