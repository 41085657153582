import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';

export enum SubmitButtonType {
    None = 'None',
    ScheduleAndBuy = 'ScheduleAndBuy',
    Schedule = 'Schedule',
}

const useSubmitButtons = (): SubmitButtonType => {
    const isAnyVacancyCanBeAutoUpdated = useSelectorNonNullable(
        ({ autoUpdateInfo }) => autoUpdateInfo.availableToSpend.vacancyIds.length > 0
    );
    const isPurchaseProducts = useSelectorNonNullable(({ autoUpdateInfo }) =>
        autoUpdateInfo.availableToPurchase.purchase?.products
            ? autoUpdateInfo.availableToPurchase.purchase.products.length > 0
            : false
    );

    if (isPurchaseProducts) {
        return SubmitButtonType.ScheduleAndBuy;
    }

    if (isAnyVacancyCanBeAutoUpdated) {
        return SubmitButtonType.Schedule;
    }

    return SubmitButtonType.None;
};

export default useSubmitButtons;
