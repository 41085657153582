import { ReactNode, useState, useRef, useCallback } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Modal,
    NavigationBar,
    Title,
    useBreakpoint,
    Text,
    Button,
    TooltipHover,
    VSpacing,
    HSpacing,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24, ExclamationCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'lux/components/Notifications/Provider';
import translation from 'lux/components/translation';
import useExperiment from 'lux/hooks/useExperiment';
import { VacancyAdvice, VacancyAdviceWithStatistics } from 'lux/models/vacancy/vacancyAdvices.types';

import FeedbackModalContent from 'lux/components/VacancyModals/VacancyAdviceModal/FeedbackModalContent';
import RatingChooser from 'lux/components/VacancyModals/VacancyAdviceModal/RatingChooser';
import vacancyAdviceModalFeedbackSuccess from 'lux/components/VacancyModals/VacancyAdviceModal/VacancyAdviceModalFeedbackSuccess';
import { Rating, Reason } from 'lux/components/VacancyModals/VacancyAdviceModal/constants';
import useAnalyticsEventShownOnce from 'lux/components/VacancyModals/VacancyAdviceModal/useAnalyticsEventShownOnce';

import styles from './vacancy-advice-modal-magritte.less';

interface VacancyAdviceModalMagritteProps {
    visible: boolean;
    title: string;
    titleDescription: string;
    vacancyId: number;
    onClose: () => void;
    advices: VacancyAdvice[];
    adviceWithStatistics?: VacancyAdviceWithStatistics[];
    children: ReactNode;
}

const TrlKeys = {
    title: 'employer.myVacancies.advice.feedback.modal.title',
    feedbackText: {
        m: 'employer.myVacancies.advice.feedback.modal.parent.text',
        s: 'employer.myVacancies.advice.feedback.modal.parent.text.s',
        xs: 'employer.myVacancies.advice.feedback.modal.parent.text.xs',
    },
    sendButton: 'employer.myVacancies.advice.feedback.modal.buttons.send',
    backButton: 'Back',
    successNotification: 'employer.myVacancies.advice.feedback.modal.successNotification',
    // tempexp_31119_start
    helpfulAdvice: {
        one: 'employer.myVacancies.advice.helpfulAdvice.one',
        some: 'employer.myVacancies.advice.helpfulAdvice.some',
        many: 'employer.myVacancies.advice.helpfulAdvice.many',
    },
    helpfulAdviceHint1: 'employer.myVacancies.advice.helpfulAdviceHint.1',
    helpfulAdviceHint2: 'employer.myVacancies.advice.helpfulAdviceHint.2',
    // tempexp_31119_end
};

interface FormValues {
    reasons: Reason[];
    comment: string;
}

const DEFAULT_FORM_VALUES: FormValues = {
    reasons: [],
    comment: '',
};

const VacancyAdviceModalMagritte: TranslatedComponent<VacancyAdviceModalMagritteProps> = ({
    trls,
    advices,
    // tempexp_31119_next_line
    adviceWithStatistics,
    visible,
    title,
    titleDescription,
    vacancyId,
    onClose,
    children,
}) => {
    const { isMobile, isS, isXS } = useBreakpoint();
    const [selectedRating, setSelectedRating] = useState<Rating | null>(null);
    const formValues = useRef<FormValues>(DEFAULT_FORM_VALUES);
    const { addNotification } = useNotification();
    // tempexp_31119_start
    const tagsAdviceModalExp = useExperiment('tags_advice_modal');
    const activatorRef = useRef<HTMLSpanElement>(null);
    // tempexp_31119_end

    // tempexp_31119_start
    const adviceTitle =
        tagsAdviceModalExp && adviceWithStatistics ? (
            <>
                <Title size="medium" Element="h4">
                    <Conversion
                        value={advices.length}
                        one={trls[TrlKeys.helpfulAdvice.one]}
                        some={trls[TrlKeys.helpfulAdvice.some]}
                        many={trls[TrlKeys.helpfulAdvice.many]}
                        hasValue={advices.length > 1}
                    />
                    <HSpacing default={8} />
                    <span
                        ref={activatorRef}
                        onMouseEnter={() => {
                            Analytics.sendHHEvent('tags_advice_modal_header_info_shown', {
                                vacancyId,
                            });
                        }}
                    >
                        <ExclamationCircleOutlinedSize16 initial="secondary" />
                    </span>
                </Title>
                <TooltipHover direction="right" alignment="top" activatorRef={activatorRef}>
                    {trls[TrlKeys.helpfulAdviceHint1]}
                    <VSpacing default={4} />
                    {trls[TrlKeys.helpfulAdviceHint2]}
                </TooltipHover>
            </>
        ) : (
            <div className={styles.vacancyAdviceModalTitle}>
                <Title size="medium" Element="h4">
                    {title}
                </Title>
                <Title size="medium" Element="h4" style="secondary">
                    {advices.length}
                </Title>
            </div>
        );
    // tempexp_31119_end

    const modalTitle = selectedRating ? (
        <Title size="medium" Element="h4">
            {trls[TrlKeys.title]}
        </Title>
    ) : (
        // tempexp_31119_next_line
        adviceTitle
    );

    const onReset = () => {
        setSelectedRating(null);
        formValues.current = DEFAULT_FORM_VALUES;
    };

    const onCloseModal = () => {
        onReset();
        onClose();
    };

    const onCancelChoiceRating = () => {
        Analytics.sendHHEventButtonClick('employer_vacancies_advice_feedback_cancel', {
            vacancyId,
        });
        onReset();
    };

    const onSendFeedback = () => {
        Analytics.sendHHEvent('employer_vacancies_advice_feedback_sent', {
            vacancyId,
            rating: selectedRating,
            reasons: JSON.stringify(formValues.current.reasons),
            comment: formValues.current.comment,
        });

        addNotification(vacancyAdviceModalFeedbackSuccess);
        onCloseModal();
    };

    const onSelectRating = (rating: Rating) => {
        Analytics.sendHHEventButtonClick('employer_vacancies_advice_feedback_rating_item', {
            vacancyId,
            rating,
        });

        setSelectedRating(rating);
    };

    const onSelectReason = (reasons: Reason[]) => {
        Analytics.sendHHEventButtonClick('employer_vacancies_advice_feedback_reason_item', {
            vacancyId,
            reason: JSON.stringify(reasons),
        });

        formValues.current.reasons = reasons;
    };

    const onChangeFeedbackText = (text: string) => {
        formValues.current.comment = text;
    };

    const onShownNegativeSection = useCallback(
        (element: HTMLDivElement) => {
            if (element) {
                Analytics.sendHHEventElementShown(element, {
                    name: 'employer_vacancies_advice_feedback_reason',
                    vacancyId,
                });
            }
        },
        [vacancyId]
    );

    const modalAdviceContentRef = useAnalyticsEventShownOnce({
        name: 'employer_vacancies_advice_modal_shown',
        vacancyId,
        advices: JSON.stringify(advices),
        // tempexp_31119_next_line
        tagsAdviceModalExp,
    });

    const modalFeedbackContentRef = useAnalyticsEventShownOnce({
        name: 'employer_vacancies_advice_feedback_modal',
        vacancyId,
        rating: selectedRating,
    });

    const feedbackTextRef = useAnalyticsEventShownOnce({
        name: 'employer_vacancies_advice_feedback_button',
        vacancyId,
    });

    const modalContent = (
        <div ref={modalAdviceContentRef}>
            {selectedRating ? (
                <div ref={modalFeedbackContentRef}>
                    <FeedbackModalContent
                        selectedRating={selectedRating}
                        onSelectRating={onSelectRating}
                        onSelectReason={onSelectReason}
                        onChangeText={onChangeFeedbackText}
                        onShownNegativeSection={onShownNegativeSection}
                    />
                </div>
            ) : (
                children
            )}
        </div>
    );

    const getLeftButtonText = () => {
        if (tagsAdviceModalExp) {
            return TrlKeys.feedbackText.m;
        }
        if (isS) {
            return TrlKeys.feedbackText.s;
        }
        if (isXS) {
            return TrlKeys.feedbackText.xs;
        }
        return TrlKeys.feedbackText.m;
    };

    const leftButtons = (
        <span ref={feedbackTextRef}>
            {selectedRating ? (
                <Button mode="secondary" style="accent" onClick={onCancelChoiceRating}>
                    {trls[TrlKeys.backButton]}
                </Button>
            ) : (
                <Text>{trls[getLeftButtonText()]}</Text>
            )}
        </span>
    );

    const rightButtons = selectedRating ? (
        <Button mode="primary" style="accent" onClick={onSendFeedback}>
            {trls[TrlKeys.sendButton]}
        </Button>
    ) : (
        <RatingChooser selected={selectedRating} onSelect={onSelectRating} />
    );

    const subtitle = selectedRating ? undefined : (
        <Text typography="label-2-regular" style="secondary">
            {titleDescription}
        </Text>
    );

    const bottomSheetFooter = tagsAdviceModalExp ? (
        <div
            className={classnames(styles.vacancyAdviceModalBottomSheetFooter, {
                [styles.vacancyAdviceModalBottomSheetFooterColumn]: isXS,
            })}
        >
            {leftButtons}
            {rightButtons}
        </div>
    ) : (
        <div className={styles.vacancyAdviceModalBottomSheetFooter}>
            {leftButtons}
            {rightButtons}
        </div>
    );

    return isMobile ? (
        <BottomSheet
            header={
                <NavigationBar
                    title={<div className={styles.vacancyAdviceModalBottomSheetTitle}>{adviceTitle}</div>}
                    subtitle={tagsAdviceModalExp ? subtitle : undefined}
                    showDivider="always"
                    right={[{ key: '1', icon: CrossOutlinedSize24, onClick: onClose }]}
                />
            }
            height="full-screen"
            visible={visible}
            onClose={onCloseModal}
            showDivider="with-scroll"
            footer={<BottomSheetFooter>{bottomSheetFooter}</BottomSheetFooter>}
        >
            {modalContent}
        </BottomSheet>
    ) : (
        <Modal
            visible={visible}
            onClose={onCloseModal}
            titleDescription={subtitle}
            titleDescriptionStyle="secondary"
            actions={<Action mode="secondary" onClick={onClose} icon={CrossOutlinedSize24} />}
            title={modalTitle}
            leftButtons={leftButtons}
            rightButtons={rightButtons}
        >
            {modalContent}
        </Modal>
    );
};

export default translation(VacancyAdviceModalMagritte);
