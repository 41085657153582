import { FC, useEffect, useRef, useState } from 'react';

import Loading, { LoadingColor, LoadingScale } from 'bloko/blocks/loading';

import submitPostData from 'Utils/SubmitPostData';
import { UnpremoderatedVacancy } from 'lux/models/unpremoderatedVacancyList';

import styles from './styles.less';

const IFRAME_NAME = 'previewFrame';

interface PreivewProps {
    moderatorId: number;
    vacancy: UnpremoderatedVacancy;
    shouldFetchPreview: boolean;
}

const Preview: FC<PreivewProps> = ({ vacancy, shouldFetchPreview }) => {
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const resizeObserverRef = useRef<ResizeObserver>();
    const [isLoading, setIsLoading] = useState(false);

    const handleIframeLoad = () => {
        setIsLoading(false);

        if (iframeRef?.current?.contentWindow) {
            resizeObserverRef.current = new ResizeObserver((entries) => {
                entries.forEach((entry) => {
                    if (iframeRef.current) {
                        iframeRef.current.style.height = `${entry.target.scrollHeight}px`;
                    }
                });
            });
            resizeObserverRef.current.observe(iframeRef.current.contentWindow.document.body);
        }
    };

    useEffect(() => {
        if (shouldFetchPreview && iframeRef.current) {
            setIsLoading(true);
            submitPostData({
                url: `/vacancy/preview?id=${vacancy.id}&modPreview=true`,
                target: IFRAME_NAME,
                data: {},
            });
        }
    }, [shouldFetchPreview, vacancy.id]);

    useEffect(() => {
        return () => {
            resizeObserverRef.current?.disconnect();
        };
    }, []);

    return (
        <div className={styles.contentWrapper}>
            {isLoading && (
                <div className={styles.previewLoader}>
                    <Loading scale={LoadingScale.Medium} initial={LoadingColor.Blue60} />
                </div>
            )}

            <iframe
                ref={iframeRef}
                className={styles.previewIframe}
                data-qa="vacancycreate-preview-iframe"
                name={IFRAME_NAME}
                onLoad={handleIframeLoad}
                scrolling="no"
            />
        </div>
    );
};

export default Preview;
