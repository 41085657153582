import { forwardRef, PropsWithChildren } from 'react';

import VSpacingContainer from 'bloko/blocks/vSpacing/VSpacingContainer';

const MenuContent = forwardRef<HTMLDivElement, PropsWithChildren>(({ children }, ref) => {
    return (
        <div ref={ref}>
            <VSpacingContainer base={2}>{children}</VSpacingContainer>
        </div>
    );
});
export default MenuContent;
