import addMinutes from 'date-fns/addMinutes';
import differenceInSeconds from 'date-fns/differenceInSeconds';

import ConversionNumber from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'lux/components/translation';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';

const TrlKeys = {
    freeRenewalTip: 'vacancy.update.freeRenewalTip',
    freeRenewalAvailableNowTip: 'vacancy.update.freeRenewalAvailableNowTip',
    minutesOne: 'interval.minutes.0',
    minutesSome: 'interval.minutes.1',
    minutesMany: 'interval.minutes.2',
    hoursOne: 'interval.hours.0',
    hoursSome: 'interval.hours.1',
    hoursMany: 'interval.hours.2',
};
export const getNextFreeRenewalInterval = (lastActivation: Date, interval: number): number => {
    const nextActivation = addMinutes(lastActivation, interval);
    return differenceInSeconds(nextActivation, new Date());
};

interface FreeRenewalTipProps {
    lastFreeRenewal: string;
    freeRenewalInterval: string;
}

const FreeRenewalTip: TranslatedComponent<FreeRenewalTipProps> = ({ lastFreeRenewal, freeRenewalInterval, trls }) => {
    const nextFreeRenewalInterval = getNextFreeRenewalInterval(new Date(lastFreeRenewal), Number(freeRenewalInterval));

    if (nextFreeRenewalInterval <= 0) {
        return <>{trls[TrlKeys.freeRenewalAvailableNowTip]}</>;
    }

    const hours = nextFreeRenewalInterval / 3600;
    const roundedHours = Math.floor(hours);
    const minutes = (hours - roundedHours) * 60;
    const roundedMinutes = minutes < 59 ? Math.ceil(minutes) : Math.floor(minutes);

    return formatToReactComponent(trls[TrlKeys.freeRenewalTip], {
        '{0}': (
            <>
                <ConversionNumber
                    value={roundedHours}
                    many={trls[TrlKeys.hoursMany]}
                    some={trls[TrlKeys.hoursSome]}
                    one={trls[TrlKeys.hoursOne]}
                />
                {roundedHours > 0 && NON_BREAKING_SPACE}
                <ConversionNumber
                    value={roundedMinutes}
                    many={trls[TrlKeys.minutesMany]}
                    some={trls[TrlKeys.minutesSome]}
                    one={trls[TrlKeys.minutesOne]}
                />
            </>
        ),
    });
};

export default translation(FreeRenewalTip);
