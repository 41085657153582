import { memo, ReactElement } from 'react';
import classNames from 'classnames';

import {
    Button as MagritteButton,
    ButtonMode,
    ButtonStyle,
    Link as MagritteLink,
    TextTypography,
} from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import { MenuItem } from 'bloko/blocks/drop';
import BlokoLink from 'bloko/blocks/link';

import Debug from 'HHC/Debug';

import { BasicTrigger, ControlsTriggerActionType } from 'lux/components/ControlsTrigger/types';

import styles from './styles.less';

const getButtonPropsByActionType = (actionType: ControlsTriggerActionType) => {
    if (actionType === ControlsTriggerActionType.POSITIVE) {
        return {
            appearance: ButtonAppearance.Outlined,
            kind: ButtonKind.Primary,
        };
    }

    if (actionType === ControlsTriggerActionType.NEGATIVE) {
        return { appearance: ButtonAppearance.Flat, kind: ButtonKind.Warning };
    }

    return { appearance: ButtonAppearance.Flat };
};

const getMagritteButtonPropsByActionType = (
    actionType: ControlsTriggerActionType
): { mode: ButtonMode; style?: ButtonStyle } => {
    if (actionType === ControlsTriggerActionType.POSITIVE) {
        return { mode: 'primary', style: 'accent' };
    }

    if (actionType === ControlsTriggerActionType.LIGHT) {
        return { mode: 'secondary', style: 'accent' };
    }

    if (actionType === ControlsTriggerActionType.NEGATIVE) {
        return { mode: 'tertiary', style: 'negative' };
    }

    return { mode: 'tertiary' };
};

interface ControlsTriggerProps extends BasicTrigger {
    dataQa: {
        element: string;
        buttonName: string;
    };
    classNameForQuery?: string;
    onClick: () => void;
    displayInOwnColumn?: boolean;
}

const ControlsTrigger = ({
    children,
    triggerType,
    triggerName,
    onTrigger,
    onClick,
    triggerHref,
    triggerTarget,
    batchLength = 0,
    isPermitted,
    isLoading,
    icon,
    iconPosition,
    additionalAnalyticsParams,
    dataQa,
    classNameForQuery = '',
    actionType = ControlsTriggerActionType.NEUTRAL,
    magritteRedesign,
    displayInOwnColumn,
}: ControlsTriggerProps) => {
    const { fromPlace, action } = additionalAnalyticsParams || {};

    if (!isPermitted) {
        return null;
    }

    if (triggerType === 'batch' && onTrigger) {
        if (batchLength === 0) {
            return null;
        }

        return (
            <MagritteButton
                onClick={onClick}
                loading={isLoading}
                mode="secondary"
                data-qa={dataQa.element}
                postfix={`${batchLength}`}
            >
                <span
                    className={classNames(styles.wrapper, { [classNameForQuery]: !!classNameForQuery })}
                    data-analytics-button-name={dataQa.buttonName}
                    data-analytics-label={triggerName}
                    data-analytics-action={action}
                    data-analytics-from-place={fromPlace}
                >
                    {children}
                </span>
            </MagritteButton>
        );
    }

    if (triggerType === 'actionList') {
        if (!icon && !displayInOwnColumn) {
            Debug.log('error out', 'Icon required for type actionList');
            return null;
        }

        const buttonProps = {
            onClick,
            'data-qa': dataQa.element,
            icon: icon as ReactElement,
            stretched: true,
            ...getMagritteButtonPropsByActionType(actionType),
        };
        const buttonContent = (
            <span
                className={classNames({ [classNameForQuery]: !!classNameForQuery })}
                data-analytics-button-name={dataQa.buttonName}
                data-analytics-label={triggerName}
                data-analytics-action={action}
                data-analytics-from-place={fromPlace}
                data-qa="link-text"
            >
                {children}
            </span>
        );
        return triggerHref ? (
            <MagritteButton {...buttonProps} Element={Link} to={triggerHref} target={triggerTarget}>
                {buttonContent}
            </MagritteButton>
        ) : (
            <MagritteButton {...buttonProps}>{buttonContent}</MagritteButton>
        );
    }

    if (triggerType === 'menu') {
        return (
            <MenuItem href={triggerHref} onClick={onClick} data-qa={dataQa.element}>
                <span
                    className={classNames({ [classNameForQuery]: !!classNameForQuery })}
                    data-analytics-button-name={dataQa.buttonName}
                    data-analytics-label={triggerName}
                    data-analytics-action={action}
                    data-analytics-from-place={fromPlace}
                >
                    {children}
                </span>
            </MenuItem>
        );
    }

    if (triggerType === 'buttonMenu') {
        const iconProps = icon && iconPosition ? { icon, iconPosition } : {};

        const buttonProps = {
            onClick,
            'data-qa': dataQa.element,
            stretched: true,
            ...iconProps,
            ...getButtonPropsByActionType(actionType),
        };
        const buttonContent = (
            <span
                className={classNames({ [classNameForQuery]: !!classNameForQuery })}
                data-analytics-button-name={dataQa.buttonName}
                data-analytics-label={triggerName}
                data-analytics-action={action}
                data-analytics-from-place={fromPlace}
            >
                {children}
            </span>
        );

        return triggerHref ? (
            <Button {...buttonProps} Element={Link} to={triggerHref} target={triggerTarget}>
                {buttonContent}
            </Button>
        ) : (
            <Button {...buttonProps} Element="button">
                {buttonContent}
            </Button>
        );
    }

    if (triggerType === 'link') {
        const linkProps = {
            typography: 'label-3-regular' as TextTypography,
            target: triggerTarget,
            onClick,
            'data-qa': dataQa.element,
        };
        return (
            <span
                className={classNames({ [classNameForQuery]: !!classNameForQuery })}
                data-analytics-button-name={dataQa.buttonName}
                data-analytics-label={triggerName}
                data-analytics-action={action}
                data-analytics-from-place={fromPlace}
            >
                {magritteRedesign && (
                    <>
                        {triggerHref ? (
                            <MagritteLink {...linkProps} to={triggerHref} Element={Link}>
                                {children}
                            </MagritteLink>
                        ) : (
                            <MagritteLink {...linkProps} href={triggerHref}>
                                {children}
                            </MagritteLink>
                        )}
                    </>
                )}
                {!magritteRedesign && (
                    <BlokoLink
                        disableVisited
                        Element={Link}
                        to={triggerHref as string}
                        target={triggerTarget}
                        onClick={onClick}
                        data-qa={dataQa.element}
                    >
                        {children}
                    </BlokoLink>
                )}
            </span>
        );
    }

    return null;
};

export default memo(ControlsTrigger);
export * from 'lux/components/ControlsTrigger/types';
