import { useMemo, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import ConversionNumber from 'bloko/blocks/conversion';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';

import translation from 'lux/components/translation';
import useToggleState from 'lux/hooks/useToggleState';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';
import { useSelector } from 'lux/modules/useSelector';

const VACANCIES_TO_DISPLAY_STEP = 10;

const VacanciesWithLocation = ({ ids, title, trls, covered = false }) => {
    const [isCovered, toggleCovered, setCovered] = useToggleState(covered);
    const [shownVacanciesCount, setShownVacanciesCount] = useState(VACANCIES_TO_DISPLAY_STEP);
    const managerVacancies = useSelector((state) => state.managerVacancies);
    const allVacancies = managerVacancies.vacancies?.list || [];
    const vacanciesWithRegions = useMemo(
        () => allVacancies.filter(({ vacancyId }) => ids.includes(vacancyId)),
        [allVacancies, ids]
    );

    const vacanciesToDisplay = vacanciesWithRegions.slice(0, shownVacanciesCount);
    const leftToDisplay = ids.length - shownVacanciesCount;

    const handleShowMore = useCallback(
        () => setShownVacanciesCount(shownVacanciesCount + VACANCIES_TO_DISPLAY_STEP),
        [shownVacanciesCount]
    );

    useEffect(() => {
        setCovered(covered);
        setShownVacanciesCount(VACANCIES_TO_DISPLAY_STEP);
    }, [setCovered, covered, ids]);

    const conversionTitle = (
        <>
            {title}
            {NON_BREAKING_SPACE}
            <ConversionNumber
                value={ids.length}
                one={trls[VacanciesWithLocation.trls.vacancySome]}
                some={trls[VacanciesWithLocation.trls.vacancyMany]}
                many={trls[VacanciesWithLocation.trls.vacancyMany]}
            />
        </>
    );

    if (isCovered) {
        return (
            <BlokoLink appearance={LinkAppearance.Pseudo} onClick={toggleCovered}>
                {conversionTitle}
            </BlokoLink>
        );
    }

    return (
        <div className="vacancy-auto-update-summary">
            <div className="auto-update-inner-title-padded">
                <Text Element="span" strong>
                    {conversionTitle}
                </Text>
            </div>
            {vacanciesToDisplay.map(({ area, name, vacancyId }, idx) => (
                <Link key={vacancyId} target="_blank" to={`/vacancy/${vacancyId}`}>
                    {name}
                    {NON_BREAKING_SPACE}– {area.name}
                    {idx < vacanciesToDisplay.length - 1 && ', '}
                </Link>
            ))}
            {ids.length > shownVacanciesCount && (
                <span className="vacancy-auto-update-addition">
                    <BlokoLink appearance={LinkAppearance.Pseudo} onClick={handleShowMore}>
                        {trls[VacanciesWithLocation.trls.more]}
                        {NON_BREAKING_SPACE}
                        {leftToDisplay > VACANCIES_TO_DISPLAY_STEP ? VACANCIES_TO_DISPLAY_STEP : leftToDisplay}
                    </BlokoLink>
                </span>
            )}
        </div>
    );
};

VacanciesWithLocation.trls = {
    vacancyOne: 'vacancy.one',
    vacancySome: 'vacancy.some',
    vacancyMany: 'vacancy.many',
    more: 'vacancy.autoUpdate.group.more',
};

VacanciesWithLocation.propTypes = {
    covered: PropTypes.bool,
    title: PropTypes.node,
    trls: PropTypes.object,
    ids: PropTypes.arrayOf(PropTypes.number),
};

export default translation(VacanciesWithLocation);
