export const CHECK_SINGLE = 'single';
export const CHECK_ALL = 'all';
export const UNCHECK_ALL = 'uncheckAll';
export const FILTER_CHECKED = 'filterChecked';

export interface CheckAction {
    type: typeof CHECK_SINGLE | typeof CHECK_ALL | typeof UNCHECK_ALL | typeof FILTER_CHECKED;
    payload?: { itemsIds: number | number[] };
}

type CheckReducerType = (state: number[], action: CheckAction) => number[];

const checkReducer: CheckReducerType = (state, { type, payload = {} }) => {
    const { itemsIds = [] } = payload;
    const ids = new Array<number>().concat(itemsIds);

    switch (type) {
        case CHECK_SINGLE: {
            const itemId = ids[0];
            if (state.includes(itemId)) {
                return state.filter((id) => id !== itemId);
            }
            return state.concat(itemId);
        }

        case CHECK_ALL:
            if (ids.every((id) => state.includes(id))) {
                return [];
            }
            return ids;

        case FILTER_CHECKED:
            return state.filter((id) => ids.includes(id));

        case UNCHECK_ALL:
            return [];

        default:
            return state;
    }
};

export default checkReducer;
