import { push } from 'connected-react-router';
import { AnyAction, Dispatch } from 'redux';

import { AddNotification } from 'lux/components/Notifications/Provider/types';
import { VacancyToProlongate } from 'lux/models/employerVacanciesRise';
import { PublicationProductTypeWithTrls } from 'lux/models/prolongateInfo';
import fetcher from 'lux/modules/fetcher';

import VacancyProlongateErrorNotification from 'lux/components/VacancyModals/Prolongation/VacancyProlongateErrorNotification';

const PROLONGATE_AND_BUY_VACANCY_URL = '/shards/employer/vacancies/prolongate/prolongate_and_buy';

declare global {
    interface FetcherPostApi {
        [PROLONGATE_AND_BUY_VACANCY_URL]: {
            queryParams: void;
            body: {
                availableToSpendVacancyIds?: number[];
                freeRenewalVacancyIds?: number[];
                products: PublicationProductTypeWithTrls[];
                purchaseParams: {
                    source: string;
                };
                vacanciesToBuy: VacancyToProlongate[];
            };
            response: { purchaseUrl: string };
        };
    }
}

const prolongateAndBuyVacancy =
    ({
        addNotification,
        availableToSpendVacancyIds,
        freeRenewalVacancyIds,
        products,
        purchaseParams,
        vacanciesToBuy,
    }: {
        addNotification: AddNotification;
        availableToSpendVacancyIds: number[];
        freeRenewalVacancyIds: number[];
        products: PublicationProductTypeWithTrls[];
        purchaseParams: {
            source: string;
        };
        vacanciesToBuy: VacancyToProlongate[];
    }) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        let response;
        try {
            response = await fetcher.post(PROLONGATE_AND_BUY_VACANCY_URL, {
                availableToSpendVacancyIds,
                freeRenewalVacancyIds,
                products,
                purchaseParams,
                vacanciesToBuy,
            });
            dispatch(push(response.data.purchaseUrl));
        } catch (error) {
            addNotification(VacancyProlongateErrorNotification, { props: { isMultiple: true } });
            throw error;
        }
    };

export default prolongateAndBuyVacancy;
