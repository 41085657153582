import { memo, useRef, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Modal from 'bloko/blocks/modal';

import { useNotification } from 'lux/components/Notifications/Provider';
import { useSelector } from 'lux/modules/useSelector';
import {
    getIframeSrc,
    isSkillazOrigin,
    sendSkillazEventAnalytics,
    shouldCloseIframe,
} from 'lux/utils/employer/videoInterview';

import { VIDEO_INTERVIEW_NOTIFICATIONS } from 'lux/components/VacancyModals/VideoInterviewModal/Notifications';

const VideoInterviewModal = ({ handleCloseModal, isVisible, authToken, vacanciesIds }) => {
    const [isModalInit, setModalInit] = useState(true);
    const labelPageId = useSelector((state) => state.request.id);
    const iframeSrc = getIframeSrc(vacanciesIds[0]);
    const iframeRef = useRef(null);
    const iframeSizes = useRef({ width: 1, height: 1 });
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const authorizeIframe = useCallback(
        (event) => {
            event.target.contentWindow.postMessage(authToken, window.globalVars.skillazURL);
        },
        [authToken]
    );

    useEffect(() => {
        const handleIframeEvents = ({ data: { eventId, payload }, origin }) => {
            if (!isSkillazOrigin(origin)) {
                return;
            }

            if (eventId === 'size') {
                if (isModalInit) {
                    iframeSizes.current = {
                        width: payload.width,
                        height: payload.height,
                    };
                    setModalInit(false);
                    return;
                }

                if (iframeRef.current) {
                    iframeRef.current.width = payload.width;
                    iframeRef.current.height = payload.height;
                }

                return;
            }

            sendSkillazEventAnalytics(eventId, payload, labelPageId);

            if (eventId === 'success' && payload.action in VIDEO_INTERVIEW_NOTIFICATIONS) {
                addNotification(VIDEO_INTERVIEW_NOTIFICATIONS[payload.action]);
            }

            if (shouldCloseIframe(eventId, payload)) {
                handleCloseModal();
            }
        };

        window.addEventListener('message', handleIframeEvents);

        return () => {
            window.removeEventListener('message', handleIframeEvents);
        };
    }, [
        addNotification,
        authToken,
        dispatch,
        handleCloseModal,
        iframeSrc,
        isModalInit,
        isVisible,
        labelPageId,
        vacanciesIds,
    ]);

    if (isModalInit) {
        return <iframe onLoad={authorizeIframe} className="g-invisible" src={iframeSrc} {...iframeSizes.current} />;
    }

    return (
        <Modal visible={isVisible} onClose={handleCloseModal}>
            <div>
                <iframe src={iframeSrc} ref={iframeRef} onLoad={authorizeIframe} {...iframeSizes.current} />
            </div>
        </Modal>
    );
};

VideoInterviewModal.propTypes = {
    isVisible: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    authToken: PropTypes.string,
    vacanciesIds: PropTypes.array,
};

export default memo(VideoInterviewModal);
