import { useCallback, useMemo, memo, useState } from 'react';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import ConversionNumber from 'bloko/blocks/conversion';
import { FormSpacer } from 'bloko/blocks/form';
import Information from 'bloko/blocks/information';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Modal, { ModalHeader, ModalTitle, ModalFooter, ModalContent } from 'bloko/blocks/modal';
import Text from 'bloko/blocks/text';
import { format } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import useShowRisePage, {
    RISE_PAGE_DIRECT_EXPERIMENT,
    RISE_PAGE_MODAL_EXPERIMENT,
} from 'lux/components/Rise/useShowRisePage';
import { useAutoRenewalModal } from 'lux/components/VacancyActions/AutoUpdateAction';
import { openBlankRisePage } from 'lux/components/VacancyActions/risePageExperimentUtils';
import { ADD_MODAL, MODAL_REMOVE_AUTO_UPDATE } from 'lux/components/VacancyModals/constants';
import translation from 'lux/components/translation';
import { useExperimentGroupCheckFunction } from 'lux/hooks/useExperimentCheckFunction';
import { NON_BREAKING_SPACE } from 'lux/modules/symbols';

const ERRORS_TO_SHOW = ['NOT_ENOUGH_QUOTAS', 'NOT_ENOUGH_PUBLICATIONS', 'NOT_ENOUGH_PERMISSIONS'];
const formatDateSchedule = (date) => formatDate(date, 'dd.MM.y');

const UpdatesSchedule = ({
    schedule: {
        previousSequentialFailuresCount,
        scheduledUpdateTimes,
        plannedExpireTime,
        nextUpdateWithFailure,
        failureReason,
        nextFailureIsTheLast,
        scheduleParams,
    },
    isVisible,
    handleCloseModal,
    vacancyName,
    expireTime,
    dispatchModal,
    vacancyId,
    trls,
}) => {
    // tempexp_30986_start
    const { isAnyRisePageExperimentEnabled, sendCheck } = useShowRisePage();
    const getExperimentGroup = useExperimentGroupCheckFunction(sendCheck);
    // tempexp_30986_end

    const shouldShowError = ERRORS_TO_SHOW.includes(failureReason);
    const handleRemoveSchedule = useCallback(
        (e) => {
            e.preventDefault();
            dispatchModal({
                type: ADD_MODAL,
                payload: {
                    modalType: MODAL_REMOVE_AUTO_UPDATE,
                    data: { expireTime, vacancyId, vacancyName },
                },
            });
        },
        [dispatchModal, expireTime, vacancyId, vacancyName]
    );
    const [isLoading, setLoading] = useState(false);
    const handleOpenEdit = useAutoRenewalModal(vacancyId, vacancyName, dispatchModal, true, isLoading, setLoading);
    const formatWithValue = useCallback(
        (trl) => format(trl, { '{0}': previousSequentialFailuresCount }),
        [previousSequentialFailuresCount]
    );
    const renderPlannedSchedule = (nextUpdate, index) => {
        if (index === 0 && shouldShowError && nextUpdateWithFailure) {
            return (
                <div key={nextUpdate} className="auto-update-schedule auto-update-schedule_failed">
                    <div className="auto-update-schedule__date" suppressHydrationWarning>
                        {formatDateSchedule(nextUpdate)}
                    </div>
                    <div>
                        {trls[UpdatesSchedule.trls.mayCanceled]}
                        {NON_BREAKING_SPACE}— {trls[UpdatesSchedule.trls.failureReasons.short[failureReason]]}
                    </div>
                </div>
            );
        }

        return (
            <div key={nextUpdate} className="auto-update-schedule">
                <div className="auto-update-schedule__date">{formatDateSchedule(nextUpdate)}</div>
                <div>{trls[UpdatesSchedule.trls.planed]}</div>
            </div>
        );
    };
    const renderErrorBlock = () => {
        if (nextFailureIsTheLast) {
            return (
                <div className="vacancy-auto-update-failure">
                    <Information>
                        <Text Element="span" strong>
                            <ConversionNumber
                                hasValue={false}
                                format={formatWithValue}
                                value={previousSequentialFailuresCount}
                                one={trls[UpdatesSchedule.trls.failureMany]}
                                some={trls[UpdatesSchedule.trls.failureSome]}
                                many={trls[UpdatesSchedule.trls.failureMany]}
                            />
                        </Text>{' '}
                        {trls[UpdatesSchedule.trls.lastFailure]}
                    </Information>
                </div>
            );
        }

        if (shouldShowError) {
            return (
                <div className="vacancy-auto-update-failure">
                    <Information>
                        {trls[UpdatesSchedule.trls.beforeFailure]}{' '}
                        <Text Element="span" strong>
                            {trls[UpdatesSchedule.trls.failureReasons.full[failureReason]]}
                        </Text>
                    </Information>
                </div>
            );
        }

        return null;
    };
    const scheduledInfo = useMemo(() => {
        const startDate = new Date(scheduleParams.start);
        const endDate = new Date(scheduleParams.end);
        const updateHours = startDate.getHours();
        const onSameMonth = startDate.getMonth() === endDate.getMonth();

        return format(trls[UpdatesSchedule.trls.scheduled], {
            '{0}': trls[UpdatesSchedule.trls[scheduleParams.type]].toLowerCase(),
            '{1}': `${updateHours}:00—${updateHours + 1}:00`,
            '{2}': `${startDate.getDate()}${
                onSameMonth ? '' : NON_BREAKING_SPACE + trls[UpdatesSchedule.trls[`month${startDate.getMonth()}`]]
            }`,
            '{3}': `${endDate.getDate()}${NON_BREAKING_SPACE}${
                trls[UpdatesSchedule.trls[`month${endDate.getMonth()}`]]
            }`,
        });
    }, [scheduleParams.end, scheduleParams.start, scheduleParams.type, trls]);

    return (
        <Modal visible={isVisible} onClose={handleCloseModal}>
            <ModalHeader>
                <ModalTitle>{format(trls[UpdatesSchedule.trls.title], { '{0}': vacancyName })}</ModalTitle>
                <div className="auto-update-inner-schedule-type">
                    {scheduledInfo}{' '}
                    <BlokoLink
                        appearance={LinkAppearance.Pseudo}
                        onClick={() => {
                            // tempexp_30986_start
                            getExperimentGroup(RISE_PAGE_DIRECT_EXPERIMENT);
                            getExperimentGroup(RISE_PAGE_MODAL_EXPERIMENT);
                            // tempexp_30986_end
                            // tempexp_30986_next_line
                            if (isAnyRisePageExperimentEnabled) {
                                openBlankRisePage([vacancyId], true);
                            } else {
                                handleCloseModal();
                                handleOpenEdit();
                            }
                        }}
                        data-qa="vacancy-auto-update-edit"
                    >
                        {trls[UpdatesSchedule.trls.edit]}
                    </BlokoLink>
                </div>
            </ModalHeader>
            <ModalContent>
                <Text strong>{trls[UpdatesSchedule.trls.futureUpdates]}</Text>
                <div className="auto-update-schedule-container">
                    {scheduledUpdateTimes.map(renderPlannedSchedule)}
                    <div className="auto-update-schedule auto-update-schedule_expired">
                        <div className="auto-update-schedule__date" suppressHydrationWarning>
                            {formatDateSchedule(plannedExpireTime)}
                        </div>
                        <div>{trls[UpdatesSchedule.trls.expired]}</div>
                    </div>
                </div>
            </ModalContent>
            {renderErrorBlock()}
            <ModalFooter>
                <div className="vacancy-auto-update-link-trigger">
                    <BlokoLink
                        appearance={LinkAppearance.Pseudo}
                        onClick={handleRemoveSchedule}
                        data-qa="vacancy-auto-update-remove"
                    >
                        {trls[UpdatesSchedule.trls.removePlaned]}
                    </BlokoLink>
                </div>
                <FormSpacer>
                    <Button
                        Element={Link}
                        kind={ButtonKind.Primary}
                        to="/price/publications"
                        data-qa="vacancy-auto-update-price-list"
                    >
                        {trls[UpdatesSchedule.trls.priceList]}
                    </Button>
                </FormSpacer>
            </ModalFooter>
        </Modal>
    );
};

UpdatesSchedule.propTypes = {
    isVisible: PropTypes.bool,
    expireTime: PropTypes.number,
    dispatchModal: PropTypes.func,
    vacancyName: PropTypes.string,
    handleCloseModal: PropTypes.func,
    vacancyId: PropTypes.number,
    schedule: PropTypes.object,
    trls: PropTypes.object,
};

UpdatesSchedule.trls = {
    title: 'vacancy.autoUpdate.scheduleList',
    failureSome: 'vacancy.autoUpdate.failureSome',
    failureMany: 'vacancy.autoUpdate.failureMany',
    edit: 'vacancy.autoUpdate.edit',
    priceList: 'vacancy.autoUpdate.priceList',
    futureUpdates: 'vacancy.autoUpdate.futureUpdates',
    planed: 'vacancy.autoUpdate.planed',
    scheduled: 'vacancy.autoUpdate.scheduled',
    removePlaned: 'vacancy.autoUpdate.removePlaned',
    expired: 'vacancy.autoUpdate.expired',
    mayCanceled: 'vacancy.autoUpdate.failure.mayCanceled',
    lastFailure: 'vacancy.autoUpdate.lastFailure',
    beforeFailure: 'vacancy.autoUpdate.beforeFailure',
    month0: 'calendar.genitive.month.0',
    month1: 'calendar.genitive.month.1',
    month2: 'calendar.genitive.month.2',
    month3: 'calendar.genitive.month.3',
    month4: 'calendar.genitive.month.4',
    month5: 'calendar.genitive.month.5',
    month6: 'calendar.genitive.month.6',
    month7: 'calendar.genitive.month.7',
    month8: 'calendar.genitive.month.8',
    month9: 'calendar.genitive.month.9',
    month10: 'calendar.genitive.month.10',
    month11: 'calendar.genitive.month.11',
    EVERY_DAY: 'vacancy.autoUpdate.everyDay',
    EVERY_WORKING_DAY: 'vacancy.autoUpdate.everyWorkDay',
    EVERY_WEEK: 'vacancy.autoUpdate.oncePerWeek',
    EVERY_TWO_WEEKS: 'vacancy.autoUpdate.oncePerTwoWeeks',
    EVERY_SIXTH_DAY: 'vacancy.autoUpdate.sixthDay',
    EVERY_FOURTH_DAY: 'vacancy.autoUpdate.fourthDay',
    failureReasons: {
        short: {
            NOT_ENOUGH_PUBLICATIONS: 'vacancy.autoUpdate.failure.publications',
            NOT_ENOUGH_PERMISSIONS: 'vacancy.autoUpdate.failure.permissions',
            NOT_ENOUGH_QUOTAS: 'vacancy.autoUpdate.failure.quotas',
        },
        full: {
            NOT_ENOUGH_PUBLICATIONS: 'vacancy.autoUpdate.failure.publications.full',
            NOT_ENOUGH_PERMISSIONS: 'vacancy.autoUpdate.failure.permissions.full',
            NOT_ENOUGH_QUOTAS: 'vacancy.autoUpdate.failure.quotas.full',
        },
    },
};

export default memo(translation(UpdatesSchedule));
