import { push } from 'connected-react-router';
import { AnyAction, Dispatch } from 'redux';

import { AddNotification } from 'lux/components/Notifications/Provider/types';
import { PublicationProductTypeWithTrls } from 'lux/models/prolongateInfo';
import fetcher from 'lux/modules/fetcher';

import VacancyAutoUpdateErrorNotification from 'lux/components/VacancyModals/AutoUpdateModalExp/VacancyAutoUpdateErrorNotification';

const POST_AUTO_UPDATE_AND_BUY_URL = '/shards/employer/vacancies/auto_update/schedule_and_buy';

interface BodyParams {
    products: PublicationProductTypeWithTrls[];
    purchaseParams: {
        source: string;
    };
    vacancyId: number[];
    scheduleType: string;
    start: string;
    end: string;
}

declare global {
    interface FetcherPostApi {
        [POST_AUTO_UPDATE_AND_BUY_URL]: {
            queryParams: void;
            body: BodyParams;
            response: { purchaseUrl: string };
        };
    }
}

const postUpdateAndBuy = (body: BodyParams) => fetcher.post(POST_AUTO_UPDATE_AND_BUY_URL, body);

const scheduleAndBuy =
    ({ products, purchaseParams, vacancyId, scheduleType, start, end }: BodyParams, addNotification: AddNotification) =>
    async (dispatch: Dispatch<AnyAction>): Promise<void> => {
        try {
            const response = await postUpdateAndBuy({
                products,
                purchaseParams,
                vacancyId,
                scheduleType,
                start,
                end,
            });

            dispatch(push(response.data.purchaseUrl));
        } catch (error) {
            addNotification(VacancyAutoUpdateErrorNotification, { props: { isMultiple: true } });
            throw error;
        }
    };

export default scheduleAndBuy;
