import { Form as FinalForm } from 'react-final-form';
import { useDispatch } from 'react-redux';

import {
    BottomSheet,
    BottomSheetFooter,
    Button,
    Modal,
    NavigationBar,
    useBreakpoint,
    VSpacing,
    Text,
} from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'lux/components/Form';
import { useNotification } from 'lux/components/Notifications/Provider';
import HiringPlanFields from 'lux/components/VacancyCreate/sections/MainInfo/HiringPlanFields';
import translation from 'lux/components/translation';
import { updateHasHiringPlan } from 'lux/models/employerVacancies/managerVacancies';
import { HiringPlanType } from 'lux/models/employerVacancies/vacancyHiringPlans';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

import hiringPlanUpdateSuccess from 'lux/components/VacancyModals/HiringPlan/HiringPlanUpdateSuccess';

import styles from './styles.less';

const TrlKeys = {
    title: 'hiringPlan.modal.header',
    description: 'hiringPlan.description',
    close: 'hiringPlan.modal.close',
    submit: 'hiringPlan.modal.submit',
};

type HiringPlanProps = {
    isVisible: boolean;
    handleCloseModal: () => void;
    vacancyId: number;
};

declare global {
    interface FetcherPostApi {
        '/shards/employer/vacancies/hiring_plan/save_hiring_plan': {
            body: HiringPlanType;
            queryParams: {
                vacancyId: number;
            };
            response: void;
        };
    }
}

const HiringPlan: TranslatedComponent<HiringPlanProps> = ({ trls, isVisible, handleCloseModal, vacancyId }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const rules = useSelector(({ vacancyHiringPlanValidationRules }) => vacancyHiringPlanValidationRules);
    const { isMobile } = useBreakpoint();

    const submitForm = async (value: { hiringPlan: HiringPlanType }) => {
        const data = value.hiringPlan;

        try {
            await fetcher.post('/shards/employer/vacancies/hiring_plan/save_hiring_plan', data, {
                params: { vacancyId },
            });
        } catch (error) {
            defaultRequestErrorHandler(error, addNotification);
            return;
        }

        dispatch(updateHasHiringPlan({ vacancyId, hasHiringPlan: true }));
        addNotification(hiringPlanUpdateSuccess);
        handleCloseModal();
    };

    return (
        <FinalForm
            initialValues={{
                hiringPlan: null,
            }}
            onSubmit={submitForm}
            render={({ handleSubmit, submitting, values }) => {
                const form = (
                    <Form onSubmit={handleSubmit} className={styles.formContainer}>
                        <HiringPlanFields rules={{ hiringPlan: { fields: rules } }} />
                    </Form>
                );

                const actions = (
                    <>
                        <Button data-qa="hiring-plan-modal-close" mode="secondary" onClick={handleCloseModal}>
                            {trls[TrlKeys.close]}
                        </Button>
                        <Button
                            data-qa="hiring-plan-modal-submit"
                            type="submit"
                            mode="primary"
                            onClick={handleSubmit}
                            loading={submitting}
                            disabled={!values.hiringPlan?.plannedCount}
                        >
                            {trls[TrlKeys.submit]}
                        </Button>
                    </>
                );

                if (isMobile) {
                    return (
                        <BottomSheet
                            visible={isVisible}
                            header={<NavigationBar title={trls[TrlKeys.title]} showDivider="always" />}
                            onClose={handleCloseModal}
                            footer={<BottomSheetFooter>{actions}</BottomSheetFooter>}
                        >
                            {form}
                        </BottomSheet>
                    );
                }

                return (
                    <Modal
                        visible={isVisible}
                        onClose={handleCloseModal}
                        title={trls[TrlKeys.title]}
                        rightButtons={actions}
                    >
                        <Text style="secondary">{trls[TrlKeys.description]}</Text>
                        <VSpacing default={16} />
                        {form}
                    </Modal>
                );
            }}
        />
    );
};

export default translation(HiringPlan);
