import { ChangeEvent, useState } from 'react';

import { CheckableChip, ChipsContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

import { Reason } from 'lux/components/VacancyModals/VacancyAdviceModal/constants';

const TrlKeys = {
    [Reason.Difficult]: 'employer.myVacancies.advice.feedback.modal.reason.difficult',
    [Reason.Paid]: 'employer.myVacancies.advice.feedback.modal.reason.paid',
    [Reason.DontBelieve]: 'employer.myVacancies.advice.feedback.modal.reason.dontBelieve',
    [Reason.Disagree]: 'employer.myVacancies.advice.feedback.modal.reason.disagree',
    [Reason.NotRelevant]: 'employer.myVacancies.advice.feedback.modal.reason.notRelevant',
    [Reason.CantApply]: 'employer.myVacancies.advice.feedback.modal.reason.cantApply',
    [Reason.Errors]: 'employer.myVacancies.advice.feedback.modal.reason.errors',
};

interface RatingChooserProps {
    onSelect: (reasons: Reason[]) => void;
}

const ReasonChipsChooser: TranslatedComponent<RatingChooserProps> = ({ trls, onSelect }) => {
    const [selectedReasons, setSelectedReasons] = useState<Reason[]>([]);

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const newReason = event.target.value as Reason;

        setSelectedReasons((prev) => {
            const newState = prev.includes(newReason)
                ? prev.filter((reason) => reason !== newReason)
                : [...prev, newReason];
            onSelect(newState);
            return newState;
        });
    };

    return (
        <ChipsContainer>
            {Object.values(Reason).map((reason) => (
                <CheckableChip
                    key={reason}
                    type="checkbox"
                    name="vacancy-advice-feedback-modal-reason"
                    value={reason}
                    onChange={onChange}
                    checked={selectedReasons.includes(reason)}
                >
                    {trls[TrlKeys[reason]]}
                </CheckableChip>
            ))}
        </ChipsContainer>
    );
};

export default translation(ReasonChipsChooser);
