import { useState } from 'react';
import { Field as FinalField, FieldMetaState } from 'react-final-form';

import { VSpacing, Input, Text, VSpacingContainer, Button, useBreakpoint } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

export interface ChangeContactFormValues {
    fio: string;
    email: string;
    phone: string;
    comment: string;
    additionalPhone: string;
    additionalComment: string;
}

enum ChangeContactFieldName {
    Fio = 'fio',
    Email = 'email',
    Phone = 'phone',
    Comment = 'comment',
    AdditionalPhone = 'additionalPhone',
    AdditionalComment = 'additionalComment',
}

const TrlKeys = {
    callTrackingHint: 'vacancy.contacts.change.modal.call-tracking.hint',
    addPhone: 'vacancy.contacts.change.modal.button.add.phone',
    placeholder: {
        [ChangeContactFieldName.Fio]: 'vacancy.contacts.change.modal.input.fio',
        [ChangeContactFieldName.Email]: 'vacancy.contacts.change.modal.input.email',
        [ChangeContactFieldName.Phone]: 'vacancy.contacts.change.modal.input.phone',
        [ChangeContactFieldName.Comment]: 'vacancy.contacts.change.modal.input.comment',
        [ChangeContactFieldName.AdditionalPhone]: 'vacancy.contacts.change.modal.input.phone',
        [ChangeContactFieldName.AdditionalComment]: 'vacancy.contacts.change.modal.input.comment',
    },
    errors: {
        empty: 'vacancy.contacts.change.modal.error.empty',
        default: 'vacancy.contacts.change.modal.error.default',

        fio: 'vacancy.contacts.change.modal.error.fio',
        email: 'vacancy.contacts.change.modal.error.email.longEmail',
        emailPatternRule: 'vacancy.contacts.change.modal.error.emailPatternRule',
        fullPhoneNumberValidation: 'vacancy.contacts.change.modal.error.fullPhoneNumberValidation',
        phoneOrEmailRule: 'vacancy.contacts.change.modal.error.phoneOrEmailRule',
        phoneComment: 'vacancy.contacts.change.modal.error.phoneComment',
        minLength: 'vacancy.contacts.change.modal.error.minLength',
        fullPhoneNumber: 'vacancy.contacts.change.modal.error.fullPhoneNumber',
        required: 'vacancy.contacts.change.modal.error.required',
    },
};

const InputField: TranslatedComponent<{
    name: ChangeContactFieldName;
}> = ({ name, trls }) => {
    const renderError = (meta: FieldMetaState<string>) => {
        const error =
            meta &&
            meta.submitFailed &&
            meta.touched &&
            !meta.dirtySinceLastSubmit &&
            ((meta.error as string) || (meta.submitError as string));
        if (!error) {
            return null;
        }

        return trls[
            error in TrlKeys.errors ? TrlKeys.errors[error as keyof typeof TrlKeys.errors] : TrlKeys.errors.default
        ];
    };

    return (
        <FinalField
            name={name}
            render={({ input, meta }) => {
                const currentError = renderError(meta);
                return (
                    <Input
                        {...input}
                        maxLength={255}
                        size="large"
                        elevatePlaceholder
                        autoCorrect="off"
                        autoCapitalize="off"
                        errorMessage={currentError}
                        spellCheck="false"
                        placeholder={trls[TrlKeys.placeholder[name]]}
                        invalid={!!currentError}
                    />
                );
            }}
        />
    );
};

const ChangeContactsForm: TranslatedComponent<{
    initialValues: ChangeContactFormValues;
}> = ({ trls, initialValues }) => {
    const [isAdditionalPhone, setIsAdditionalPhone] = useState(false);

    const { isMobile } = useBreakpoint();

    return (
        <>
            <VSpacingContainer default={12}>
                {[ChangeContactFieldName.Fio, ChangeContactFieldName.Email, ChangeContactFieldName.Phone].map(
                    (name) => {
                        return <InputField key={name} name={name} trls={trls} />;
                    }
                )}
            </VSpacingContainer>
            <VSpacing default={8} />
            <Text typography="label-3-regular" style="secondary">
                {trls[TrlKeys.callTrackingHint]}
            </Text>
            <VSpacing default={12} />
            <InputField name={ChangeContactFieldName.Comment} trls={trls} />
            <VSpacing default={12} />
            <Button
                mode="secondary"
                style="neutral"
                onClick={() => setIsAdditionalPhone(true)}
                icon={<PlusOutlinedSize24 />}
                stretched={isMobile}
            >
                {trls[TrlKeys.addPhone]}
            </Button>
            <VSpacing default={12} />
            {(isAdditionalPhone || initialValues.additionalPhone) && (
                <>
                    <InputField name={ChangeContactFieldName.AdditionalPhone} trls={trls} />
                    <VSpacing default={12} />
                    <InputField name={ChangeContactFieldName.AdditionalComment} trls={trls} />
                    <VSpacing default={12} />
                </>
            )}
        </>
    );
};

export default translation(ChangeContactsForm);
