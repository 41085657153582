import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import Button, { ButtonKind } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import Modal, { ModalHeader, ModalTitle, ModalFooter } from 'bloko/blocks/modal';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import translation from 'lux/components/translation';
import paths from 'lux/modules/routePaths';

import styles from './buy-option-modal.less';

const BuyOption = ({ handleCloseModal, isVisible, trls }) => {
    const dispatch = useDispatch();
    return (
        <Modal visible={isVisible} onClose={handleCloseModal}>
            <ModalHeader outlined>
                <ModalTitle>{trls[BuyOption.trls.title]}</ModalTitle>
            </ModalHeader>
            <Text size={TextSize.Large}>{trls[BuyOption.trls.description]}</Text>
            <VSpacing base={5} />
            <Text size={TextSize.Large} strong>
                {trls[BuyOption.trls.listTitle]}
            </Text>
            <VSpacing base={2} />
            <ol className={styles.benefitsList}>
                <li>{trls[BuyOption.trls.search]}</li>
                <li>{trls[BuyOption.trls.profArea]}</li>
                <li>{trls[BuyOption.trls.vacancyOfDay]}</li>
                <li>{trls[BuyOption.trls.labelPremium]}</li>
                <li>{trls[BuyOption.trls.maxResponses]}</li>
            </ol>
            <VSpacing base={14} />
            <ModalFooter>
                <Button onClick={handleCloseModal}>{trls[BuyOption.trls.cancel]}</Button>
                <FormSpacer>
                    <Button kind={ButtonKind.Primary} onClick={() => dispatch(push(paths.priceZpPromotion))}>
                        {trls[BuyOption.trls.submit]}
                    </Button>
                </FormSpacer>
            </ModalFooter>
        </Modal>
    );
};

BuyOption.propTypes = {
    isVisible: PropTypes.bool,
    handleCloseModal: PropTypes.func,
    trls: PropTypes.object,
};

BuyOption.trls = {
    title: 'employer.myVacancies.buyOption.title',
    description: 'employer.myVacancies.buyOption.description',
    listTitle: 'employer.myVacancies.buyOption.listTitle',
    search: 'employer.myVacancies.buyOption.search',
    profArea: 'employer.myVacancies.buyOption.profArea',
    vacancyOfDay: 'employer.myVacancies.buyOption.vacancyOfDay',
    labelPremium: 'employer.myVacancies.buyOption.labelPremium',
    maxResponses: 'employer.myVacancies.buyOption.maxResponses',
    submit: 'employer.myVacancies.buyOption.submit',
    cancel: 'Cancel',
};

export default translation(BuyOption);
