import { Fragment, useCallback } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Button, { ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import ConversionNumber from 'bloko/blocks/conversion';
import { FormSpacer } from 'bloko/blocks/form';
import Modal, { ModalContent, ModalFooter, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import ElementShownAnchor from 'lux/components/ElementShownAnchor';
import useShowRisePage from 'lux/components/Rise/useShowRisePage';
import { openBlankRisePage } from 'lux/components/VacancyActions/risePageExperimentUtils';
import translation from 'lux/components/translation';
import useOnOffState from 'lux/hooks/useOnOffState';
import { VacancyToProlongate } from 'lux/models/employerVacanciesRise';

import Content from 'lux/components/VacancyModals/Prolongation/Content';
import SubmitButtons from 'lux/components/VacancyModals/Prolongation/SubmitButtons';
import useSubmitButtons, { SubmitButtonType } from 'lux/components/VacancyModals/Prolongation/useSubmitButtons';

import styles from './styles.less';

interface ProlongationProps {
    afterSuccessAction: ({
        handleCloseModal,
        queryParams,
        vacancyIds,
    }: {
        handleCloseModal: () => void;
        queryParams: Record<string, number[]>;
        vacancyIds: number[];
    }) => void;
    handleCloseModal: () => void;
    freeRenewalParameters: {
        intervalMinutes: string | null;
        lastActivationTimeIso: string | null;
    };
    isVisible: boolean;
    // tempexp_30986_next_line
    showRegularButton: boolean;
    // tempexp_30986_next_line
    vacanciesIds: number[];
    vacanciesInfoToProlongate: VacancyToProlongate[];
}

const TrlKeys = {
    cancel: 'Cancel',
    title: 'employer.myVacancies.prolongate',
    vacancy: {
        one: 'vacancy.genitive.one',
        some: 'vacancy.genitive.some',
        many: 'vacancy.genitive.many',
    },
};

const sendEventElementShown = (
    element: HTMLElement,
    bodyData: {
        availableToSpendIds: number[];
        availableToFreeProlongateIds: number[];
        availableToPurchaseIds: number[];
        unavailableIds: number[];
        submitButtonType: SubmitButtonType;
    }
) =>
    Analytics.sendHHEventElementShown(
        element,
        {
            elementName: 'prolongation_modal',
        },
        // Из-за большого кол-ва вакансий используем body, а не query параметры
        {
            ...bodyData,
        }
    );

const Prolongation: TranslatedComponent<ProlongationProps> = ({
    afterSuccessAction,
    handleCloseModal,
    freeRenewalParameters,
    isVisible,
    showRegularButton,
    vacanciesIds,
    vacanciesInfoToProlongate,
    trls,
}) => {
    // tempexp_30986_next_line
    const { isRisePageModalExperimentEnabled } = useShowRisePage();

    const availableToSpendIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToSpend.vacancyIds
    );
    const availableToFreeProlongateIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds
    );
    const availableToPurchaseIds = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToPurchase.vacancyIds
    );
    const unavailableIds = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.unavailable.vacancyIds);
    const submitButtonType = useSubmitButtons();

    const total = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.total);

    const [loading, startLoading, finishLoading] = useOnOffState(false);

    const isXs = useBreakpoint() === Breakpoint.XS;

    const handleOpenBlankRisePage = useCallback(() => {
        Analytics.sendHHEventButtonClick(
            'prolongation_modal_regular_rise_button',
            {},
            {
                vacanciesIds,
            }
        );
        openBlankRisePage(vacanciesIds, true);
    }, [vacanciesIds]);

    // tempexp_30986_next_line
    const Wrapper = isXs ? Fragment : FormSpacer;

    return (
        <Modal visible={isVisible} onClose={handleCloseModal}>
            <ModalHeader outlined>
                <ModalTitle>
                    {formatToReactComponent(trls[TrlKeys.title], {
                        '{0}': (
                            <ConversionNumber
                                one={trls[TrlKeys.vacancy.one]}
                                some={trls[TrlKeys.vacancy.some]}
                                many={trls[TrlKeys.vacancy.many]}
                                value={total}
                            />
                        ),
                    })}
                </ModalTitle>
            </ModalHeader>
            <ModalContent>
                <ElementShownAnchor
                    className={styles.wrapper}
                    fn={sendEventElementShown}
                    availableToSpendIds={availableToSpendIds}
                    availableToFreeProlongateIds={availableToFreeProlongateIds}
                    availableToPurchaseIds={availableToPurchaseIds}
                    unavailableIds={unavailableIds}
                    submitButtonType={submitButtonType}
                >
                    <Content
                        afterSuccessAction={afterSuccessAction}
                        handleCloseModal={handleCloseModal}
                        freeRenewalParameters={freeRenewalParameters}
                        loading={loading}
                        startLoading={startLoading}
                        finishLoading={finishLoading}
                    />
                </ElementShownAnchor>
            </ModalContent>
            <ModalFooter>
                <div className={styles.footerWrapper}>
                    <Button data-qa="prolongate-modal-close" onClick={handleCloseModal} stretched={isXs}>
                        {trls[TrlKeys.cancel]}
                    </Button>
                    {/* tempexp_30986_next_line */}
                    <div className={styles.buttonWrapperExp}>
                        {/* tempexp_30986_start */}
                        {isRisePageModalExperimentEnabled && showRegularButton && (
                            <Wrapper>
                                <Button
                                    appearance={ButtonAppearance.Outlined}
                                    kind={ButtonKind.Primary}
                                    onClick={handleOpenBlankRisePage}
                                    stretched={isXs}
                                >
                                    Поднимать регулярно
                                </Button>
                            </Wrapper>
                        )}
                        {/* tempexp_30986_end */}
                        <SubmitButtons
                            afterSuccessAction={afterSuccessAction}
                            handleCloseModal={handleCloseModal}
                            loading={loading}
                            startLoading={startLoading}
                            finishLoading={finishLoading}
                            vacanciesInfoToProlongate={vacanciesInfoToProlongate}
                        />
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default translation(Prolongation);
