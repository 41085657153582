import { MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notification from 'lux/components/Notifications/Notification';
import { NotificationProps } from 'lux/components/Notifications/Provider/types';
import translation from 'lux/components/translation';

const TrlKeys = {
    error: 'employer.myVacancyes.move.error',
};

const TransferError: TranslatedComponent<NotificationProps> = ({ trls, ...props }) => {
    return (
        <Notification
            addon={<MinusCircleFilledSize24 initial="negative" />}
            aria-label={trls[TrlKeys.error]}
            {...props}
        >
            {trls[TrlKeys.error]}
        </Notification>
    );
};

export default translation(TransferError);
